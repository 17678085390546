import Fuse from "fuse.js"
import {nameofFactory} from "../helper/nameOf";
import PlaylistData from "../playlist/data/PlaylistData";


export class PlaylistIndexSearchEngine {

    private nameOf = nameofFactory<PlaylistData>();

    private readonly fuseOptions: Fuse.IFuseOptions<PlaylistData> = {
        shouldSort: true,
        findAllMatches: true,
        useExtendedSearch: true,
        keys: [
            this.nameOf("title")
        ]
    };

    private readonly fuseSearch: Fuse<PlaylistData>;
    readonly allEntries: readonly PlaylistData[];

    constructor(allEntries: readonly PlaylistData[]) {
        this.fuseSearch = new Fuse(allEntries, this.fuseOptions);
        this.allEntries = allEntries;
    }

    /**
     * Searches through all toc entries. Each param acts as a restriction. An empty array or string means no restriction.
     */
    public search(searchTerm: string): readonly PlaylistData[] {
        if (!searchTerm) {
            return this.allEntries;
        }

        const results: Fuse.FuseResult<PlaylistData>[] = this.fuseSearch.search<PlaylistData>(searchTerm);
        return results.map(v => v.item);
    }
}