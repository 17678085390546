import {Dispatch, SetStateAction, useEffect, useState} from "react";
import {DtoLocalStoragePersistence} from "../../localStoragePersistence/common/DtoLocalStoragePersistence";
import {DtoIdentifier, EntityDTO} from "../../dto/EntityDTO";

export default function useStateWithLocalStoragePersistenceAndDefault<S, DTO extends EntityDTO>(
    identifier: DtoIdentifier,
    fromStateToPersistedMapping: (s: S) => DTO,
    fromPersistedToStateMapping: (p: DTO) => S,
    defaultValue: S | ((identifier: DtoIdentifier) => S),
    dtoLocalStoragePersistence: DtoLocalStoragePersistence<DTO>
): [S, Dispatch<SetStateAction<S>>] {
    const [value, setValue] = useState<S>(() => readPersistence());

    function readPersistence(): S {
        const dto = dtoLocalStoragePersistence.load(identifier);
        if (dto) {
            const mappedValue = fromPersistedToStateMapping(dto);
            console.debug("read persisted state from local storage " + identifier, {state: mappedValue, dto: dto});
            return mappedValue;
        } else {
            return defaultValue instanceof Function ? defaultValue(identifier) : defaultValue;
        }
    }

    useEffect(() => {
        const persisted = fromStateToPersistedMapping(value);
        dtoLocalStoragePersistence.persist(persisted);
    }, [dtoLocalStoragePersistence, fromStateToPersistedMapping, value]);


    return [value, setValue];
}