import * as React from 'react';
import {faLayerGroup} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Header extends React.Component<{}, {}> {

    public render() {
        return (
            <div className="bg-light display-4 py-2" style={{paddingLeft: 16}}><FontAwesomeIcon icon={faLayerGroup}/> Songship</div>
        );
    }
}

export default Header;
