import * as React from 'react';
import {CardHeader, CloseButton, Col, Row} from "reactstrap";

interface Props {
    onCloseFunction: () => void
    title: String
}

export function CardHeaderWithCloseButton(props: Props) {
    return (
        <CardHeader>
            <Row>
                <Col>
                    {props.title}
                </Col>
                <Col className="col-auto">
                    <CloseButton onClick={() => props.onCloseFunction()}/>
                </Col>
            </Row>
        </CardHeader>
    );
}

