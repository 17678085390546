import * as React from 'react';
import {Input, ListGroup, ListGroupItem} from 'reactstrap';
import PlaylistData from "../playlist/data/PlaylistData";
import PlaylistSummaryItemList from "./PlaylistSummaryItemList";
import {CyPlaylistIndex} from "./CyPlaylistIndex";
import {Link} from "react-router-dom";
import {CyPlaylistIndexTable} from "./CyPlaylistIndexTable";


interface Props {
    playlistData: PlaylistData
    showDetailsInPlaylistSummary: boolean,
    toggleSelection: (identifier: string) => void
    isSelected: boolean
}

export function PlaylistSummary(props: Props) {

    function formatDateTime(date: Date): string {
        return date.toLocaleString();
    }

    return (
        <ListGroup horizontal style={{flex: "1 1 auto", userSelect: "none"}} className="border-bottom"
                   data-cy={CyPlaylistIndexTable.PlaylistSummary} data-cy-key={props.playlistData.getIdentifier()}>
            <ListGroupItem
                active={props.isSelected}
                className={"d-flex align-items-center border-0 " + (props.isSelected && "bg-primary text-dark bg-opacity-25")}
                style={{fontSize: "24px"}}
                onClick={() => props.toggleSelection(props.playlistData.getIdentifier())}
            >
                <Input
                    type="checkbox"
                    className="me-2"
                    color="primary"
                    checked={props.isSelected}
                    readOnly
                />
            </ListGroupItem>
            <ListGroupItem
                action
                className={"border-0 " + (props.isSelected && "bg-primary text-dark bg-opacity-25")}
                tag={Link}
                to={"/playlist/" + props.playlistData.getIdentifier()}
            >
                <div className="col">
                    <div className="row">
                        <div className="col-lg-8 d-flex">
                            <span className="lead"
                                  data-cy={CyPlaylistIndex.PlaylistTitle}>{props.playlistData.title}</span>
                        </div>
                        <div className="col-lg-2">
                            <span className="d-lg-none">Erstellt: </span>
                            {formatDateTime(props.playlistData.createdAt)}
                        </div>
                        <div className="col-lg-2">
                            <span className="d-lg-none">Aktualisiert: </span>
                            {formatDateTime(props.playlistData.updatedAt)}
                        </div>
                    </div>
                    <div className="row">
                        {props.showDetailsInPlaylistSummary &&
                            <PlaylistSummaryItemList playlistData={props.playlistData.getItems()}/>}
                    </div>
                </div>
            </ListGroupItem>
        </ListGroup>
    );
}