const cacheMajorVersion = "8";
export const OfflineConstants = {
    cacheMajorVersion: cacheMajorVersion,
    offlineAvailableContentPathSegment: "offlineAvailableContent",
    tableOfContentsPathSegment: "tableOfContents",
    tableOfContentsCacheName: "tocCache",
    songsheetfilesPathSegment: "songsheetfiles",
    songsheetfilesCacheName: "songsheetCache",
    skipCachePath: "skipCache",
    prefix: "songship",
    suffix: `v${cacheMajorVersion}`
} as const;