import * as React from 'react';
import {Button, NavItem} from 'reactstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faToggleOff, faToggleOn} from '@fortawesome/free-solid-svg-icons';

interface Props {
    value: string
    isActive: boolean
    setIsActive: (isActive: boolean) => void
}

class NavItemTogglePersistent extends React.Component<Props, {}> {
    constructor(props: Readonly<Props>) {
        super(props);

        this.toggle = this.toggle.bind(this);
    }

    private toggle() {
        this.props.setIsActive(!this.props.isActive);
    }

    public render() {
        return (
            <NavItem>
                <Button className="nav-link" onClick={() => this.toggle()} color="link">
                    <FontAwesomeIcon icon={this.props.isActive ? faToggleOn : faToggleOff}/> {this.props.value}
                </Button>
            </NavItem>
        );
    }
}

export default NavItemTogglePersistent;
