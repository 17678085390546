import * as React from 'react';
import {useState} from 'react';
import {Collapse, Nav, Navbar, NavbarToggler, NavItem} from 'reactstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowCircleLeft} from '@fortawesome/free-solid-svg-icons';
import {Link} from "react-router-dom";
import NavItemTogglePersistent from "../../common/NavItemTogglePersistent";
import DefaultSongbookDropdown from "./DefaultSongbookDropdown";

interface Props {
    playlistIdentifier: string
    showTonality: boolean
    setShowTonality: (newValue: boolean) => void
    currentSongbookId: number | undefined
    setCurrentSongbookId: (newValue?: number) => void
    songbookMap: Map<number, string>
}

function SharePlaylistMenu(props: Props) {
    const [isOpen, setIsOpen] = useState(false);
    const toggleIsOpen = () => setIsOpen(!isOpen);

    return (
        <>
            <Navbar color="light" light expand="lg" sticky={"top"} fixed={"top"}>
                <NavbarToggler onClick={toggleIsOpen}/>
                <Collapse isOpen={isOpen} navbar>
                    <Nav className="me-auto" navbar>
                        <NavItem>
                            <Link className="nav-link" to={"/playlist/" + props.playlistIdentifier}><FontAwesomeIcon icon={faArrowCircleLeft}/> Zurück</Link>
                        </NavItem>
                        <NavItemTogglePersistent value="Tonart anzeigen" setIsActive={props.setShowTonality} isActive={props.showTonality}/>
                        <DefaultSongbookDropdown
                            songbookMap={props.songbookMap}
                            currentSongbookId={props.currentSongbookId}
                            setCurrentSongbookId={props.setCurrentSongbookId}
                        />
                    </Nav>
                </Collapse>
            </Navbar>
        </>
    );
}

export default SharePlaylistMenu;
