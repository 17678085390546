import {PlaylistDTO, PlaylistDTOCompanion} from "../../../dto/v3/playlist/PlaylistDTO";
import {PLAYLIST_LOCAL_STORAGE_ENTITY_NAME} from "../../EntityNames";
import LocalStoragePersistence from "../../common/LocalStoragePersistence";
import {DtoLocalStoragePersistence} from "../../common/DtoLocalStoragePersistence";
import {DtoIdentifier} from "../../../dto/EntityDTO";
import _ from "lodash";
import EntityLocalStorageKey from "../../common/EntityLocalStorageKey";


export class PlaylistLocalStoragePersistence extends DtoLocalStoragePersistence<PlaylistDTO> {
    protected schemaVersion(): number {
        return PlaylistDTOCompanion.SCHEMA_VERISON;
    }

    protected buildFromParsedJson(parsedJson: PlaylistDTO): PlaylistDTO {
        return PlaylistDTOCompanion.fromParsedJson(parsedJson);
    }

    /**
     * Playlists which are marked as removed, are filtered out.
     */
    public loadAll(): ReadonlyArray<PlaylistDTO> {
        return _.compact(PlaylistLocalStoragePersistence.loadAllIdentifiers().map((i: string) => {
            const data: PlaylistDTO | undefined = this.load(i);
            if (data !== undefined) {
                return data.isRemoved ? undefined : data;
            } else {
                console.error("Identifier=" + i + " could not be loaded.");
                return undefined;
            }
        }));
    }

    public persist(playlist: PlaylistDTO) {
        // console.debug(`Persisting identifier=${playlist.identifier} schemaVersion=${playlist.schemaVersion} title=${playlist.title}`, playlist)
        LocalStoragePersistence.persist(this.localStorageKey(playlist.identifier), playlist);
    }

    protected localStorageKey(identifier: DtoIdentifier): EntityLocalStorageKey {
        return new EntityLocalStorageKey(PLAYLIST_LOCAL_STORAGE_ENTITY_NAME, identifier);
    }

    /**
     * Be aware: Also identifiers of removed playlists are returned here!
     */
    public static loadAllIdentifiers(): ReadonlyArray<string> {
        return LocalStoragePersistence.loadAllIdentifiersForEntity(PLAYLIST_LOCAL_STORAGE_ENTITY_NAME)
    }
}