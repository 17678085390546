import history from "../history";
import _ from "lodash";
import {RoutePaths} from "../RoutePaths";

export default class LocationRestorerAfterLogin {

    private static readonly LOCATION_B4_LOGIN_LOCAL_STORAGE_KEY: string = "locationBeforeLogin";
    private NOT_ALLOWED_LIST_FOR_RESTORE: readonly string[] = [RoutePaths.CALLBACK, RoutePaths.LOGOUT, RoutePaths.STUB_LOGIN];

    public writeCurrentLocationToLocalStorage() {
        const urlWithoutOriginB4Redirect = window.location.href.replace(window.location.origin, "");
        console.debug("LocationRestorerAfterLogin: Putting current location to local storage to redirect the user after a successful login.", urlWithoutOriginB4Redirect);
        localStorage.setItem(LocationRestorerAfterLogin.LOCATION_B4_LOGIN_LOCAL_STORAGE_KEY, urlWithoutOriginB4Redirect);
    }

    public navigateToLocationBeforeLogin(fallbackRoute?: string) {
        const localStorageValue = localStorage.getItem(LocationRestorerAfterLogin.LOCATION_B4_LOGIN_LOCAL_STORAGE_KEY);
        if (localStorageValue && this.isUrlAllowed(localStorageValue)) {
            console.debug("LocationRestorerAfterLogin: Restoring the URL which was set before user was redirected to login.", localStorageValue);
            localStorage.removeItem(LocationRestorerAfterLogin.LOCATION_B4_LOGIN_LOCAL_STORAGE_KEY);
            history.push(localStorageValue);

        } else if (fallbackRoute) {
            console.debug("Restoring to provided fallback route.", fallbackRoute)
            history.push(fallbackRoute);
        } else {
            console.debug("Restoring to root because no fallback route was given.")
            history.push("/");
        }
    }

    private isUrlAllowed(urlToRestore: string) {
        const result = _.every(this.NOT_ALLOWED_LIST_FOR_RESTORE, e => !urlToRestore.includes(e))
        if (!result) console.warn("urlToRestore is not allowed to be restored", urlToRestore);
        return result;
    }
}
