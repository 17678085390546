import {
    PlaylistLocalStoragePersistence
} from "../../localStoragePersistence/v3/playlist/PlaylistLocalStoragePersistence";
import {SyncPlaylistConfig} from "../../Config";
import Auth from "../../auth/Auth";
import {JsonParsing} from "../../dto/parsing/JsonParsing";
import {PlaylistDTO, PlaylistDTOCompanion} from "../../dto/v3/playlist/PlaylistDTO";
import {PlaylistItemDTOCompanion} from "../../dto/v3/playlist/PlaylistItemDTO";

//this status is returned by the server in case the playlist has already been modified by someone else (lastServerVersion does not match)
const HTTP_CONFLICT = 409;

export interface PushResult {
    noPushNecessaryBecauseNoNeedToPush: ReadonlyArray<string>;
    noPushNecessaryBecauseQrCodeScannedPlaylist: ReadonlyArray<string>;
    successfullyPushed: ReadonlyArray<string>;
    pushedWithConflict: ReadonlyArray<{ identifier: string, message: string }>;
    pushError: ReadonlyArray<{ identifier: string, message: string }>;
}

export class PlaylistPusher {
    private readonly auth: Auth;

    constructor(auth: Auth) {
        this.auth = auth;

        this.run = this.run.bind(this);
        this.pushAllPlaylists = this.pushAllPlaylists.bind(this);
        this.analyzeAndPushPlaylistIfNecessary = this.analyzeAndPushPlaylistIfNecessary.bind(this);
    }

    run(): Promise<PushResult> {
        return Promise.resolve(this.loadAllPlaylistIdentifiers())
            .then((identifiers: ReadonlyArray<string>) => this.pushAllPlaylists(identifiers))
    }

    private loadAllPlaylistIdentifiers(): ReadonlyArray<string> {
        return PlaylistLocalStoragePersistence.loadAllIdentifiers();
    }

    private pushAllPlaylists(identifierList: ReadonlyArray<string>): Promise<PushResult> {
        let promises = identifierList.map((identifier) => this.analyzeAndPushPlaylistIfNecessary(identifier));
        return Promise.all(promises)
            .then((pushedPlaylistPromiseResulsts) => {
                const combinedResult = combinePushedPlaylistResults(pushedPlaylistPromiseResulsts);
                return Promise.resolve(combinedResult);
            });
    }

    private analyzeAndPushPlaylistIfNecessary(identifier: string): Promise<PushResult> {
        const playlistDto: PlaylistDTO | undefined = new PlaylistLocalStoragePersistence().load(identifier);
        if (playlistDto && playlistDto.isQrScannedPlaylist) {
            return Promise.resolve(noPushNecessaryBecauseQrCodeScannedPlaylist(playlistDto.identifier));
        } else if (playlistDto && playlistDto.needsPush) {
            return this.pushPlaylist(playlistDto, identifier);
        } else if (playlistDto && !playlistDto.needsPush) {
            return Promise.resolve(noPushNecessaryBecauseNoNeedToPush(playlistDto.identifier));
        } else {
            const message = "Playlist with id=" + identifier + " not found for push";
            return Promise.resolve(pushError(identifier, message));
        }
    }

    private pushPlaylist(playlistDto: PlaylistDTO, identifier: string): Promise<PushResult> {
        // console.debug("Pushing playlist with id=" + identifier);
        const pushUrl = SyncPlaylistConfig.pushUrl;
        return fetch(pushUrl, {
            method: 'post',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.auth.getAccessToken()}`
            },
            body: JSON.stringify(playlistDto)
        })
            .then(res => Promise.all([Promise.resolve(res.ok), res.status, res.text()]))
            .then(([isOk, httpStatus, responseText]) => {
                if (isOk) {
                    return this.updatePushedPlaylist(responseText);
                } else if (httpStatus === HTTP_CONFLICT) {
                    return Promise.resolve(pushedWithConflict(identifier, responseText));
                } else {
                    return Promise.resolve(pushError(identifier, responseText));
                }
            })
            .catch(function (error) {
                const message = "Error while pushing playlist with id=" + identifier + " to " + pushUrl;
                console.warn(message, error);
                return Promise.resolve(pushError(identifier, message))
            });
    }

    private updatePushedPlaylist(content: string): Promise<PushResult> {
        //TODO: Introduce proper parsing.
        return new JsonParsing().parse<PlaylistDTOFromPushV3Endpoint>(content)
            .map((dto: PlaylistDTOFromPushV3Endpoint) => {
                return PlaylistDTOCompanion.create(
                    dto.identifier,
                    dto.title,
                    dto.tags,
                    dto.belongsToCrew,
                    dto.items.map(i => PlaylistItemDTOCompanion.create(i.tocId, i.title, i.note)),
                    dto.createdAt,
                    dto.updatedAt,
                    dto.lastServerVersion,
                    false,
                    false,
                    dto.isRemoved
                );
            })
            .map((dto: PlaylistDTO) => {
                new PlaylistLocalStoragePersistence().persist(dto);
                return dto.identifier;
            })
            .match((identifier: string) => Promise.resolve(successFullyPushed(identifier)), e => {
                throw e;
            });
    }
}

/**
 * We use these interfaces, because they represent, what we really get from the server. We don't get the schemaVersion for example.
 * Maybe this gets better, when we use GraphQL here as well but for now we don't lie.
 */
interface PlaylistDTOFromPushV3Endpoint {
    identifier: string;
    title: string;
    belongsToCrew: string | undefined;
    tags: ReadonlyArray<string>;
    items: ReadonlyArray<PlaylistItemDTOFromPushV3Endpoint>;
    createdAt: Date;
    updatedAt: Date;
    lastServerVersion: number;
    isRemoved: boolean
}

interface PlaylistItemDTOFromPushV3Endpoint {
    tocId?: TocIdDTOFromPushV3Endpoint,
    title?: string;
    note?: string;
}

export interface TocIdDTOFromPushV3Endpoint {
    sourceSystem: string;
    songId: number;
}

const emptyPushResult: PushResult = {
    noPushNecessaryBecauseNoNeedToPush: [],
    noPushNecessaryBecauseQrCodeScannedPlaylist: [],
    successfullyPushed: [],
    pushedWithConflict: [],
    pushError: []
}

function noPushNecessaryBecauseNoNeedToPush(identifier: string): PushResult {
    return {
        noPushNecessaryBecauseNoNeedToPush: [identifier],
        noPushNecessaryBecauseQrCodeScannedPlaylist: [],
        successfullyPushed: [],
        pushedWithConflict: [],
        pushError: []
    }
}

function noPushNecessaryBecauseQrCodeScannedPlaylist(identifier: string): PushResult {
    return {
        noPushNecessaryBecauseNoNeedToPush: [],
        noPushNecessaryBecauseQrCodeScannedPlaylist: [identifier],
        successfullyPushed: [],
        pushedWithConflict: [],
        pushError: []
    }
}

function successFullyPushed(identifier: string): PushResult {
    return {
        noPushNecessaryBecauseNoNeedToPush: [],
        noPushNecessaryBecauseQrCodeScannedPlaylist: [],
        successfullyPushed: [identifier],
        pushedWithConflict: [],
        pushError: []
    }
}

function pushedWithConflict(identifier: string, message: string): PushResult {
    return {
        noPushNecessaryBecauseNoNeedToPush: [],
        noPushNecessaryBecauseQrCodeScannedPlaylist: [],
        successfullyPushed: [],
        pushedWithConflict: [{identifier: identifier, message: message}],
        pushError: []
    }
}

function pushError(identifier: string, message: string): PushResult {
    return {
        noPushNecessaryBecauseNoNeedToPush: [],
        noPushNecessaryBecauseQrCodeScannedPlaylist: [],
        successfullyPushed: [],
        pushedWithConflict: [],
        pushError: [{identifier: identifier, message: message}]
    }
}

function combinePushedPlaylistResults(pushedPlaylistPromiseResulsts: PushResult[]): PushResult {
    return pushedPlaylistPromiseResulsts.reduce((acc, currentValue) => {
        return {
            noPushNecessaryBecauseNoNeedToPush: acc.noPushNecessaryBecauseNoNeedToPush.concat(currentValue.noPushNecessaryBecauseNoNeedToPush),
            noPushNecessaryBecauseQrCodeScannedPlaylist: acc.noPushNecessaryBecauseQrCodeScannedPlaylist.concat(currentValue.noPushNecessaryBecauseQrCodeScannedPlaylist),
            successfullyPushed: acc.successfullyPushed.concat(currentValue.successfullyPushed),
            pushedWithConflict: acc.pushedWithConflict.concat(currentValue.pushedWithConflict),
            pushError: acc.pushError.concat(currentValue.pushError)
        }
    }, emptyPushResult);
}

