import * as React from 'react';
import {Button, Card, CardBody, CardHeader} from 'reactstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBell, faDownload} from '@fortawesome/free-solid-svg-icons';

interface Props {
    startUpdate: () => void
}

export function DownloadNecessaryNotification(props: Props) {
    return (
        <Card className="mt-3">
            <CardHeader>
                <h2 className={"light-header"}><FontAwesomeIcon icon={faBell}/> Neue Daten</h2>
            </CardHeader>
            <CardBody>
                Es wurden neue bzw. modifizierte Lieder gefunden, die noch nicht auf deinem Gerät gespeichert sind. Möchtest du sie jetzt herunterladen?<br/><br/>
                <Button color="primary" onClick={() => props.startUpdate()}><FontAwesomeIcon icon={faDownload}/> Download starten</Button><br/> <br/>
            </CardBody>
        </Card>
    );
}