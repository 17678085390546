import {Client, gql, OperationResult} from "urql";

export class CrewJoiner {
    private readonly graphQlClient: Client

    private readonly addUserToCrewMutation = gql`
        mutation addUserToCrew($joiningIdentifier: String!, $emailUsedDuringJoin: String!){
            addUserToCrew(joiningIdentifier : $joiningIdentifier, emailUsedDuringJoin : $emailUsedDuringJoin){
                title
            }
        }
    `;

    constructor(graphQlClient: Client) {
        this.graphQlClient = graphQlClient;

        this.joinCrew = this.joinCrew.bind(this);
    }

    joinCrew(joiningIdentifier: string, emailUsedDuringJoin : string): Promise<string> {
        return this.graphQlClient
            .mutation(this.addUserToCrewMutation, {joiningIdentifier: joiningIdentifier, emailUsedDuringJoin: emailUsedDuringJoin})
            .toPromise()
            .then((result: OperationResult<any, object>) => {
                if (result.error === undefined) {
                    const title = result.data.addUserToCrew.title
                    return Promise.resolve(title);
                } else {
                    return Promise.reject(result);
                }
            });
    }
}

