import * as React from 'react';
import {ReactNode} from 'react';
import {Alert} from 'reactstrap';
import {PlaylistLocalStoragePersistence} from "../localStoragePersistence/v3/playlist/PlaylistLocalStoragePersistence";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle, faHome} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import Header from "../Header";

interface Props {
    identifier: string
    nodeToRender: ReactNode
}

interface State {
    doesKeyExistInLocalStorage: boolean
}

class PlaylistPreparer extends React.Component<Props, State> {
    private readonly playlistLocalStoragePersistence: PlaylistLocalStoragePersistence;

    constructor(props: Readonly<Props>) {
        super(props);

        this.playlistLocalStoragePersistence = new PlaylistLocalStoragePersistence();

        this.state = {
            doesKeyExistInLocalStorage: this.playlistLocalStoragePersistence.doesDtoExist(props.identifier),
        };
    }

    public render() {
        return (
            <>
                {this.state.doesKeyExistInLocalStorage ?
                    (
                        this.props.nodeToRender
                    ) :
                    (<>
                        <Header/>
                        <br/>
                        <Alert color="warning">
                            <h2 className={"light-header"}><FontAwesomeIcon icon={faExclamationTriangle}/> Playlist nicht gefunden</h2>
                            Die gesuchte Playlist konnte nicht gefunden werden.<br/>Playlists sind nur auf dem Gerät vorhanden, auf dem du sie erstellst.<br/> <br/>
                            <Link className="nav-link" to="/"><FontAwesomeIcon icon={faHome}/> Übersicht</Link>
                        </Alert>
                    </>)}
            </>
        );
    }
}

export default PlaylistPreparer;
