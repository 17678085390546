import {TableOfContentsDTO} from "../dto/v1/toc/TableOfContentsDTO";
import {CurrentState, OfflineUpdateSummary} from "./OfflineUpdateSummary";
import {TocTransformer} from "./TocTransformer";

export class Blackboard {

    private notifyProgress: (summary: OfflineUpdateSummary) => void;

    constructor(notifyProgress: (summary: OfflineUpdateSummary) => void, toc: TableOfContentsDTO) {
        this.notifyProgress = notifyProgress;
        this._toc = toc;
        const tocTransformer = new TocTransformer();
        this.setUrlsFromToC(tocTransformer.buildUrlListForAllPagesOfAllSongs(toc));
    }

    private lastNotifiedDownloadTimestamp = new Date();

    private readonly _toc: TableOfContentsDTO = new TableOfContentsDTO();
    failures: Array<string> = [];
    private _input = {
        urlsFromToc: new Set<string>(),
        urlsFromCache: new Set<string>()
    };
    private _work = {
        urlsToBeDownloaded: new Array<string>(),
        urlsToBeDeleted: new Array<string>()
    };
    private summary: OfflineUpdateSummary = new OfflineUpdateSummary();

    get input(): { urlsFromToc: Set<string>; urlsFromCache: Set<string> } {
        return this._input;
    }

    private setUrlsFromToC(value: Set<string>) {
        this._input.urlsFromToc = value;
        this.summary.amountInToc = value.size;
        this.notifyProgress(this.summary);
    }

    public setUrlsFromCache(value: Set<string>) {
        this._input.urlsFromCache = value;
        this.summary.amountInCache = value.size;
        this.notifyProgress(this.summary);
    }

    get work(): { urlsToBeDownloaded: string[]; urlsToBeDeleted: string[] } {
        return this._work;
    }

    set work(work: { urlsToBeDownloaded: string[]; urlsToBeDeleted: string[] }) {
        this._work = work;
        this.summary.initialAmountToBeDownloaded = work.urlsToBeDownloaded.length;
        this.summary.leftAmountToBeDownloaded = work.urlsToBeDownloaded.length;
        this.summary.initialAmountToBeDeleted = work.urlsToBeDeleted.length;
    }

    set toBeDownloaded(value: string[]) {
        this._work.urlsToBeDownloaded = value;
        this.summary.initialAmountToBeDownloaded = value.length;
        this.summary.leftAmountToBeDownloaded = value.length;
        this.notifyProgress(this.summary);
    }

    set toBeDeleted(value: string[]) {
        this._work.urlsToBeDeleted = value;
        this.summary.initialAmountToBeDeleted = value.length;
        this.notifyProgress(this.summary);
    }

    get toc(): TableOfContentsDTO {
        return this._toc;
    }

    public updateAndNotifyCurrentState(state: CurrentState) {
        this.summary.currentState = state;
        this.notifyProgress(this.summary);
    }

    public updateDownloadsLeft(leftAmountToBeDownloaded: number) {
        this.summary.currentState = CurrentState.DOWNLOAD;
        this.summary.leftAmountToBeDownloaded = leftAmountToBeDownloaded;
        const diff = new Date().getTime() - this.lastNotifiedDownloadTimestamp.getTime();
        if (diff > 1000) {
            this.lastNotifiedDownloadTimestamp = new Date();
            this.notifyProgress(this.summary);
        }

    }

}