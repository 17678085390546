import {OfflineConstants} from "./OfflineConstants";

// @ts-ignore
const extConf = ENVIRONMENT_SPECIFIC_CONFIG

// @ts-ignore
const extBuildInfo = BUILD_INFORMATION

const envSpecificConfig = {
    uiUrl: extConf.uiUrl,
    backendUrl: extConf.backendUrl,
    scottyUrl: extConf.scottyUrl,
    useProdSecurityInDevMode: !!(extConf.useProdSecurityInDevMode || false)
} as const;

const buildInformation = {
    dockerTag: extBuildInfo.dockerTag,
    gitRevision: extBuildInfo.gitRevision,
} as const;

const tocApiVersion = "v2";

function buildCacheIdentifier(identifier: string) {
    return `${OfflineConstants.prefix}-${identifier}-${OfflineConstants.suffix}`
}

export const UiConfig = {
    baseUrl: envSpecificConfig.uiUrl,
    appVersion: `${buildInformation.dockerTag}@${buildInformation.gitRevision}`,
} as const;

export const BackendConfig = {
    baseUrl: envSpecificConfig.backendUrl,
    scottyUrl: envSpecificConfig.scottyUrl
} as const;

export const AuthConfig = {
    domain: "rebstokk.eu.auth0.com",
    clientId: "EUoLpk3gxPLBy8CCXP6tE9loL8VSVg2S", //Songship Single Page Application
    callbackUrl: envSpecificConfig.uiUrl + "/callback",
    audience: "https://backend.songship.app", //this does not change for beta because it identifies the API at Auth0
    useProdSecurityInDevMode: envSpecificConfig.useProdSecurityInDevMode
} as const;

export const TocConfig = {
    version: tocApiVersion,
    url: envSpecificConfig.backendUrl + "/" + OfflineConstants.offlineAvailableContentPathSegment + "/" + OfflineConstants.tableOfContentsPathSegment + "/" + tocApiVersion + "/list",
    urlSkipCache: envSpecificConfig.backendUrl + "/" + OfflineConstants.skipCachePath + "/" + OfflineConstants.tableOfContentsPathSegment + "/" + tocApiVersion + "/list",
    cacheIdentifier: buildCacheIdentifier(OfflineConstants.tableOfContentsCacheName)
} as const;

export const SongsheetsConfig = {
    url: envSpecificConfig.backendUrl + "/" + OfflineConstants.offlineAvailableContentPathSegment + "/" + OfflineConstants.songsheetfilesPathSegment,
    cacheIdentifier: buildCacheIdentifier(OfflineConstants.songsheetfilesCacheName)
} as const;

export const SyncPlaylistConfig = {
    pushUrl: envSpecificConfig.backendUrl + "/sync/playlist/push/v3"
} as const;