import Auth from "../auth/Auth";

export class FetchWithAuth {

    private auth: Auth;

    constructor(auth: Auth) {
        this.auth = auth;
    }

    public fetch(url: string): Promise<Response> {
        return fetch(url, {
            headers: new Headers({
                'Authorization': `Bearer ${this.auth.getAccessToken()}`
            })
        })
    }


}
