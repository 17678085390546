import * as React from 'react';
import './TocAvailabilityNotice.css';
import {Alert, Button, Container} from "reactstrap";
import {faKey, faLock} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Header from "../Header";

interface Props {
    logout: () => void
}

export function AuthFailedNotice(props: Props) {

    return (
        <Container fluid>
            <Header/>
            <br/>
            <Alert color="danger">
                <h2 className={"light-header"}><FontAwesomeIcon icon={faKey}/> Fehler bei der Anmeldung</h2>
                <br/>
                Fehler bei der Anmeldung. Du scheinst keine Berechtigung zu haben.<br /><br />

                <Button color="primary" onClick={() => props.logout()} className="me-2"><FontAwesomeIcon icon={faLock}/> Abmelden</Button>

            </Alert>
        </Container>
    );
}