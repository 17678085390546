import {TocEntryDTO} from "../dto/v1/toc/TocEntryDTO";
import {SongsheetsConfig} from "../Config";

export class ImageUrlResolver {

    public resolveAllUrlsForSong(song: TocEntryDTO): string[] {
        return song.pictureReferences.map(r => this.resolveFor(r))
    }

    public resolveFor(pictureId: string): string {
        return `${SongsheetsConfig.url}/${pictureId}`
    }

}