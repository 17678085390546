import * as React from 'react';
import './TocAvailabilityNotice.css';
import {Alert, Button} from "reactstrap";
import {faArrowAltCircleRight, faKey, faLock} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Header from "../Header";
import {LocalDataRemover} from "./LocalDataRemover";
import {LoginByDifferentUserHandler} from "../auth/LoginByDifferentUserHandler";
import Auth from "../auth/Auth";
import {CyApp} from "../CyApp";

interface Props {
    auth: Auth
}

export function LocalStorageDeletionNecessaryNotice(props: Props) {

    function deleteCachesAndReload() {
        new LocalDataRemover().removeAllLocalData().then(() => {
            //persist the new subject, *after* the user has accepted to delete the old one.
            LoginByDifferentUserHandler.persistCurrentAuth0Subject(props.auth.getAuth0Subject());

            window.location.reload();
        });

    }

    return (
        <>
            <Header/>
            <br/>
            <Alert color="warning">
                <h2 className={"light-header"} data-cy={CyApp.UserChangedTitle}><FontAwesomeIcon icon={faKey}/> Benutzerwechsel festgestellt</h2>
                <br/>
                Wir haben festgestellt, dass du dich soeben mit einem anderen Benutzer angemeldet hast, als das letzte Mal.<br/> <br/>

                Wenn du fortfährst, werden die lokal auf diesem Gerät gespeicherten Daten (Liedernoten, Inhaltsverzeichnis, Playlists etc.) des letzten Benutzers gelöscht.<br/> <br/>

                Möchtest du fortfahren oder dich abmelden und mit dem vorherigen Benutzer anmelden?<br/> <br/>

                <Button color="primary" onClick={() => props.auth.logout()} className="me-2"><FontAwesomeIcon icon={faLock}/> Abmelden</Button>

                <Button color="primary" onClick={() => deleteCachesAndReload()} data-cy={CyApp.UserChangedContinueButton}><FontAwesomeIcon icon={faArrowAltCircleRight}/> Fortfahren</Button>

            </Alert>
        </>
    );
}