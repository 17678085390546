import {useEffect, useState} from "react";
import {BackendConfig} from "../Config";


// crewId can be undefined for the private queue
export function useCrewLiveEventSource(crewId: string | undefined): { eventSource: EventSource | undefined } {


    const [eventSource, setEventSource] = useState<EventSource | undefined>(undefined);

    //{"eventName":"playlistLivePosition","eventID":"b0df0d17-a888-4c1f-8bc4-803e67532a90","crewId":"556209d1-ef72-4b65-968e-b2d1c714bab1","deviceIdentifier":"c1e3068d-d48b-4eb1-b756-e272903165c8","timestamp":"2024-07-10T13:58:49.445Z","deviceReadableName":"Philipp's Smartphone","payload":"{\"selectedPlaylistItemIndex\":0,\"currentLyricsPosition\":4,\"currentSlideIndex\":0}"}
    useEffect(() => {
        if (!crewId) {
            console.debug("No crewId, not opening event source.")
            return
        }

        // opening a connection to the server to begin receiving events from it
        const source = new EventSource(`${BackendConfig.baseUrl}/live/getLiveUpdates/${crewId}`);

        //we don't attach the onmessage event handler, this can be done by the concrete listeners, that use this stream

        source.onerror = (event: Event): any => {
            console.error("EventSource failed:", event);
        }

        window.addEventListener('beforeunload', () => {
            console.debug("Closing event source")
            source.close();
        });

        setEventSource(source);

        // terminating the connection on component unmount
        return () => source.close();

    }, [crewId]);

    return {eventSource};
}