import * as React from 'react';
import {Card, CardBody, CardHeader, CardText, CardTitle, Col, Row} from 'reactstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUsers, faUsersSlash} from '@fortawesome/free-solid-svg-icons';
import {CrewTexts} from "./CrewTexts";
import {RoutePaths} from "../RoutePaths";
import {CopyToClipboardButton} from "./CopyToClipboardButton";
import {renderLoadingUntilDefined} from "../common/LoadingUntilDefined";
import {useCrewQuery} from "../crew/useCrewQuery";
import {CrewInformationUb} from "../gql/graphql";
import {CySettings} from "./CySettings";
import {UiConfig} from "../Config";

export function CrewSettings() {

    const crewInformationMaybe: ReadonlyArray<CrewInformationUb> | undefined = useCrewQuery()

    function generateJoiningLink(joiningIdentifier: string) {
        return `${UiConfig.baseUrl}/${RoutePaths.JOIN_CREW}/${joiningIdentifier}`
    }

    function generateJoiningMessage(p: CrewInformationUb) {
        return `Ich möchte dich in Songship zur Crew ${p.title} einladen. Mit dem folgenden Einladungslink kannst du der Crew direkt beitreten:\r\n${generateJoiningLink(p.joiningIdentifier)}`;
    }

    return (
        <Card className="mt-3">
            <CardHeader><h2 className={"light-header"}>
                <FontAwesomeIcon icon={faUsers}/> Crew</h2>
            </CardHeader>
            <CardBody data-cy={CySettings.CrewList}>
                <p>
                    Wenn du Teil einer Crew (Band, Team, Gruppe etc) bist, kannst du auf alle ihre Lieder, Texte und Playlisten zugreifen.
                </p>

                {renderLoadingUntilDefined(crewInformationMaybe, (crewInformation) => {
                    if (crewInformation.length === 0) {
                        return <p>
                            <div style={{width: "30px", display: "inline-block"}}><FontAwesomeIcon icon={faUsersSlash}/></div>
                            {CrewTexts.NoCrewFound}
                        </p>
                    } else {
                        return <Row className="g-3">

                            {crewInformation.map((p, i) => (<Col key={p.identifier} md={6} lg={4} xl={3}>
                                    <Card>
                                        <CardBody>
                                            <CardTitle tag="h5">
                                                <div style={{width: "30px", display: "inline-block"}}><FontAwesomeIcon icon={faUsers}/></div>
                                                {p.title}</CardTitle>
                                            <CardText>Um jemanden zu {p.title} einzuladen, kopiere und teile den Einladungstext. Teile den Link nur mit Personen, denen zu vertraust.
                                            </CardText>
                                            <CardText><textarea rows={5} style={{width: "100%"}}
                                                                value={generateJoiningMessage(p)} readOnly={true}/>
                                            </CardText>
                                            <CopyToClipboardButton buttonValue={CrewTexts.CopyInvitation} content={generateJoiningMessage(p)}/>
                                        </CardBody>
                                    </Card></Col>
                            ))
                            }
                        </Row>
                    }
                })}
            </CardBody>
        </Card>
    );
}