import * as React from 'react';
import {useEffect, useState} from 'react';
import {Alert, Button, Container} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle, faHome, faHourglassHalf, faInfoCircle, faPlane} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import Header from "../Header";
import {CrewJoiner} from "./CrewJoiner";
import {InProgress, JoinCrewState, JoiningError, SuccessfullyJoined} from "./JoinCrewState";
import SwitchUtils from "../common/SwitchUtils";
import {useOnlineOffline} from "../common/hooks/useOnlineOffline";
import {CrewJoinerTexts} from "./CrewJoinerTexts";
import {CyCrewJoiner} from "./CyCrewJoiner";
import {useClient} from "urql";
import {CrewInformationUb} from "../gql/graphql";
import {useCrewQuery} from "./useCrewQuery";
import {renderLoadingUntilDefined} from "../common/LoadingUntilDefined";

interface Props {
    joinIdentifier: string,
    emailResolver: () => string | undefined,
}

const FAILED_TO_LOAD_E_MAIL_DURING_JOIN_CONST = "FAILED_TO_LOAD_E_MAIL_DURING_JOIN";

export function JoinCrewDialog(props: Props) {
    const isOnline = useOnlineOffline()

    const crewInformationMaybe: ReadonlyArray<CrewInformationUb> | undefined = useCrewQuery()

    const [joinCrewState, setJoinCrewState] = useState<JoinCrewState>(new InProgress());

    const client = useClient();

    useEffect(() => {
        if (crewInformationMaybe === undefined) {
            return
        }

        const maybeCrew = crewInformationMaybe.find(i => i.joiningIdentifier === props.joinIdentifier)
        if (maybeCrew) {
            console.info("User already belongs to crew " + maybeCrew.title + ". Redirecting to /.")
            window.location.href = "/"
            return
        }

        const crewJoiner = new CrewJoiner(client)
        crewJoiner.joinCrew(props.joinIdentifier, props.emailResolver() ?? FAILED_TO_LOAD_E_MAIL_DURING_JOIN_CONST)
            .then(title => {
                setJoinCrewState(new SuccessfullyJoined(title))
                console.info("User joined crew=" + title)
            })
            .catch((err: any) => {
                const message = "Error while joining crew with joiningIdentifier=" + props.joinIdentifier;
                console.error(message, err);
                setJoinCrewState(new JoiningError(message))
                return Promise.resolve();
            })
    }, [props, isOnline, client, crewInformationMaybe]);

    return (
        <Container fluid>
            <Header/>
            <br/>
            {renderLoadingUntilDefined(crewInformationMaybe, () => {
                return renderState()
            })}
        </Container>
    );

    function renderState() {
        if (!isOnline) {
            return renderNoInternet();
        }

        switch (joinCrewState.state) {
            case "InProgress":
                return renderInProgress();
            case "SuccessfullyJoined":
                return renderJoiningSuccessful(joinCrewState.title);
            case "JoiningError":
                return renderJoiningError(joinCrewState.errorMessage);
            default:
                return SwitchUtils.throwUnsupportedValue(joinCrewState)
        }
    }

    function renderNoInternet() {
        return (
            <Alert color="danger">
                <h2 className={"light-header"}><FontAwesomeIcon icon={faPlane}/> Keine Internetverbindung</h2>
                <span>
                    Du kannst nur einer Crew beitreten, wenn du über eine Internetverbindung verfügst.
                </span>
                <br/><br/>
                <Link to="/"><FontAwesomeIcon icon={faHome}/> Zurück zur Startseite</Link>
            </Alert>
        );
    }

    function renderInProgress() {
        return (
            <Alert color="primary">
                <h2 className={"light-header"}><FontAwesomeIcon icon={faHourglassHalf}/> Beitrittsanfrage</h2>
                Wir prüfen deine Anfrage. Einen Moment...
            </Alert>
        );
    }

    function renderJoiningSuccessful(title: String) {
        return (
            <Alert color="success" data-cy={CyCrewJoiner.AlertSuccess}>
                <h2 className={"light-header"}><FontAwesomeIcon icon={faInfoCircle}/> {CrewJoinerTexts.JoiningSuccessful}</h2>
                <span>
                    Du bist erfolgreich der Gruppe <b>{title}</b> beigetreten.
                </span>
                <br/><br/>

                <Button color="success" data-cy={CyCrewJoiner.ButtonOk} onClick={() => {
                    //Not only using Reacts router but really reload the page to refetch the crews!
                    window.location.href = window.location.origin
                }}>OK</Button>
            </Alert>
        );
    }

    function renderJoiningError(errorMessage: string) {
        return (
            <Alert color="danger">
                <h2 className={"light-header"}><FontAwesomeIcon icon={faExclamationTriangle}/> Fehler beim Joinen</h2>
                <span>
                    Du konntest der Gruppe nicht beitreten. Prüfe deinen Identifier. Melde dich bei uns, um Unterstützung zu erhalten!<br/>
                    Technische Fehlermeldung: {errorMessage}
                </span>
                <br/><br/>
                <Link to="/"><FontAwesomeIcon icon={faHome}/> Startseite</Link>
            </Alert>
        );
    }
}

