import * as React from 'react';
import {PropsWithChildren} from 'react';
import {Card, CardHeader} from "reactstrap";
import {CardHeaderWithCloseButton} from "../common/CardHeaderWithCloseButton";

interface Props {
    closeSidebar: () => void
    title: string
    showCloseButton: boolean
}

export default function Sidebar(props: PropsWithChildren<Props>) {

    return (
        <div className="sidebar">
            <Card>
                {props.showCloseButton ?
                    <CardHeaderWithCloseButton
                        onCloseFunction={props.closeSidebar}
                        title={props.title}
                    />
                    :
                    <CardHeader>
                        {props.title}
                    </CardHeader>}
                {props.children}
            </Card>
        </div>
    );

}

