import {PlaylistLocalStoragePersistence} from "../../localStoragePersistence/v3/playlist/PlaylistLocalStoragePersistence";
import {Client, gql} from "urql";
import {PlaylistDTO, PlaylistDTOCompanion} from "../../dto/v3/playlist/PlaylistDTO";
import {PlaylistItemDTOCompanion} from "../../dto/v3/playlist/PlaylistItemDTO";
import _ from "lodash";
import PlaylistMapper from "../mapper/PlaylistMapper";
import {TocIdDTO} from "../../dto/v3/toc/TocIdDTO";
import {TocEntryDTO} from "../../dto/v1/toc/TocEntryDTO";
import PlaylistManipulationUtils from "../PlaylistManipulationUtils";
import PlaylistData from "../data/PlaylistData";

export interface FetchResult {
    noFetchNecessary: ReadonlyArray<string>;
    successfullyFetched: ReadonlyArray<string>;
    fetchedAndSolvedConflict: ReadonlyArray<{ duplicatedPlaylistIdentifierOnDisk: string, identifierFromServer: string }>;
}

const playlistMapper = new PlaylistMapper();

export class PlaylistFetcher {
    private readonly graphQlClient: Client
    private readonly mapPersistedToData: (p: PlaylistDTO) => PlaylistData

    private readonly playlistLocalStoragePersistence = new PlaylistLocalStoragePersistence();

    private readonly playlistQuery = gql`
        query playlistQuery{
            listPlaylist{
                identifier,
                title,
                belongsToCrew,
                tags,
                items{
                    tocId{
                        sourceSystem,
                        songId
                    },
                    title,
                    note
                },
                createdAt,
                updatedAt,
                lastServerVersion,
                isRemoved
            }
        }
    `;

    constructor(graphQlClient: Client, lookupSong: (tocId: TocIdDTO) => TocEntryDTO | undefined) {
        this.graphQlClient = graphQlClient
        this.mapPersistedToData = playlistMapper.persistedToDataMapper(lookupSong)

        this.run = this.run.bind(this);
        this.fetchAllPlaylists = this.fetchAllPlaylists.bind(this);
    }

    run(): Promise<FetchResult> {
        return Promise.resolve(this.loadAllPlaylistIdentifiers())
            .then((identifiers: ReadonlyArray<string>) => this.fetchAllPlaylists());
    }

    private loadAllPlaylistIdentifiers(): ReadonlyArray<string> {
        return PlaylistLocalStoragePersistence.loadAllIdentifiers();
    }

    private fetchAllPlaylists(): Promise<FetchResult> {
        return this.graphQlClient
            .query(this.playlistQuery, [])
            .toPromise()
            .then(result => {
                const playlistsFromGraphQl: ReadonlyArray<PlaylistDTOFromGraphQlEndpoint> = result.data.listPlaylist
                const fetchResult = playlistsFromGraphQl.map(this.mapFromGraphQlToDtoPlaylist)
                    .map(p => this.updatePlaylist(p))
                    .reduce(this.combineFetchResult, emptyFetchResult);
                return Promise.resolve(fetchResult);
            });
    }

    private combineFetchResult: (acc: FetchResult, currentValue: FetchResult) => FetchResult =
        (acc: FetchResult, currentValue: FetchResult) =>
            ({
                noFetchNecessary: acc.noFetchNecessary.concat(currentValue.noFetchNecessary),
                successfullyFetched: acc.successfullyFetched.concat(currentValue.successfullyFetched),
                fetchedAndSolvedConflict: acc.fetchedAndSolvedConflict.concat(currentValue.fetchedAndSolvedConflict),
            })


    private mapFromGraphQlToDtoPlaylist: (dto: PlaylistDTOFromGraphQlEndpoint) => PlaylistDTO =
        (dto: PlaylistDTOFromGraphQlEndpoint) =>
            PlaylistDTOCompanion.create(
                dto.identifier,
                dto.title,
                dto.tags,
                nullToUndefined(dto.belongsToCrew),
                dto.items.map(i => PlaylistItemDTOCompanion.create(nullToUndefined(i.tocId), nullToUndefined(i.title), nullToUndefined(i.note))),
                dto.createdAt,
                dto.updatedAt,
                dto.lastServerVersion,
                false,
                false,
                dto.isRemoved
            );

    private updatePlaylist(playlistFromServer: PlaylistDTO): FetchResult {

        const optPlaylistOnDisk = this.playlistLocalStoragePersistence.load(playlistFromServer.identifier);

        if (optPlaylistOnDisk === undefined) {
            this.playlistLocalStoragePersistence.persist(playlistFromServer);
            return successfullyFetched(playlistFromServer.identifier);
        } else if (optPlaylistOnDisk.lastServerVersion === playlistFromServer.lastServerVersion) {
            return noFetchNecessary(playlistFromServer.identifier);
        } else if (!optPlaylistOnDisk.needsPush) {
            this.playlistLocalStoragePersistence.persist(playlistFromServer);
            return successfullyFetched(playlistFromServer.identifier);
        } else {
            return this.solveConflict(playlistFromServer, optPlaylistOnDisk);
        }
    }

    private solveConflict(playlistFromServer: PlaylistDTO, playlistOnDisk: PlaylistDTO): FetchResult {
        const playlistOnDiskAsPlaylistData = this.mapPersistedToData(playlistOnDisk)
        const duplicatedPlaylist = PlaylistManipulationUtils
            .copyPlaylistFromExistingToCrew(playlistOnDiskAsPlaylistData, playlistOnDiskAsPlaylistData.belongsToCrew)
            .copyWithTitle(playlistOnDiskAsPlaylistData.title + " (Konflikt nach Synchronisation am " + new Date().toLocaleString() + ")")
        this.playlistLocalStoragePersistence.persist(playlistMapper.mapDataToPersisted(duplicatedPlaylist));
        this.playlistLocalStoragePersistence.persist(playlistFromServer);
        return fetchedAndSolvedConflict(duplicatedPlaylist.getIdentifier(), playlistFromServer.identifier);
    }
}

/**
 * Here we have a discrepancy between TypeScript and GraphQL.
 * Options in Typescript: Type or Undefined
 * Options in GraphQL: Type or null
 * Here we have to map from one to the other. Maybe we find a better way later.
 */

function nullToUndefined<T>(mayBeNullValue: T | undefined | null): T | undefined {
    return _.isNil(mayBeNullValue) ? undefined : mayBeNullValue
}

/**
 * We use these interfaces, because they represent, what we really get from the server. We don't get the schemaVersion for example.
 * Maybe this gets better, when we can genearte DTOs from the GraphQL query above...
 */
interface PlaylistDTOFromGraphQlEndpoint {
    identifier: string;
    title: string;
    belongsToCrew: string | null;
    tags: ReadonlyArray<string>;
    items: ReadonlyArray<PlaylistItemDTOFromGraphQlEndpoint>;
    createdAt: Date;
    updatedAt: Date;
    lastServerVersion: number;
    isRemoved: boolean
}

interface PlaylistItemDTOFromGraphQlEndpoint {
    tocId: TocIdDTOFromGraphQlEndpoint | null,
    title: string | null;
    note: string | null;
}

export interface TocIdDTOFromGraphQlEndpoint {
    sourceSystem: string;
    songId: number;
}

const emptyFetchResult: FetchResult = {
    noFetchNecessary: [],
    successfullyFetched: [],
    fetchedAndSolvedConflict: [],
}

function noFetchNecessary(identifier: string): FetchResult {
    return {
        noFetchNecessary: [identifier],
        successfullyFetched: [],
        fetchedAndSolvedConflict: [],
    }
}

function successfullyFetched(identifier: string): FetchResult {
    return {
        noFetchNecessary: [],
        successfullyFetched: [identifier],
        fetchedAndSolvedConflict: [],
    }
}

function fetchedAndSolvedConflict(duplicatedPlaylistIdentifierOnDisk: string, identifierFromServer: string): FetchResult {
    return {
        noFetchNecessary: [],
        successfullyFetched: [],
        fetchedAndSolvedConflict: [{duplicatedPlaylistIdentifierOnDisk: duplicatedPlaylistIdentifierOnDisk, identifierFromServer: identifierFromServer}],
    }
}