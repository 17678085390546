import {AuthState} from "../appinitandstate/AuthState";
import {Permission, permissionsToRequest} from "./Permission";
import _ from "lodash";
import {LocalStorageKey} from "../localStoragePersistence/common/LocalStorageKey";

export default abstract class Auth {

    protected static readonly grantedPermissionsStringLocalStorageKey = new LocalStorageKey("AuthGrantedPermissionsString", undefined);

    abstract login(): void;

    abstract handleAuthentication(): Promise<AuthState>;

    abstract getAccessToken(): undefined | string;

    abstract getIdToken(): undefined | string;

    abstract renewSession(resultCallback: (authenticationResult: AuthState) => void): void;

    abstract logout(): void;

    abstract isAuthenticated(): boolean;

    protected abstract getGrantedPermissionsString(): String | undefined;

    getPermissionOverview(): readonly { permission: Permission; granted: boolean; }[] {
        const grantedPermissionsString = this.getGrantedPermissionsString();
        return permissionsToRequest.map(requestedPermission => (
            {
                permission: requestedPermission,
                granted: grantedPermissionsString ? grantedPermissionsString.includes(requestedPermission.name) : false
            }
        ));
    }

    hasPermissions(requiredPermissions: readonly Permission[]): boolean {
        return _.every(requiredPermissions, required => this.getGrantedPermissionsString()?.includes(required.name))
    }

    abstract getEmail(): string | undefined

    abstract getAuth0Subject(): string

}
