import {TocIdDTO} from "../toc/TocIdDTO";
import _ from "lodash";

/**
 * @deprecated use v3
 */
export interface PlaylistItemDTO {
    songId?: number;
    sourceSystem?: string;
    title?: string;
    note?: string;
}

/**
 * @deprecated use v3
 */
export class PlaylistItemDTOCompanion {

    public static create(
        tocId?: TocIdDTO,
        title?: string,
        note?: string
    ): PlaylistItemDTO {
        return {
            songId: tocId?.songId,
            sourceSystem: tocId?.sourceSystem,
            title: title,
            note: note
        }
    }

    public static fromParsedJson(playlistItems: ReadonlyArray<PlaylistItemDTO>): ReadonlyArray<PlaylistItemDTO> {
        return _.flatMap(playlistItems, (i: PlaylistItemDTO, index: number) => {
            if (i.songId !== undefined) {
                const tocId = TocIdDTO.fromSongIdAndSourceSystem(i.songId, i.sourceSystem);
                return PlaylistItemDTOCompanion.create(tocId, i.title, i.note);
            } else {
                return [];
            }
        });
    }

}
