import {SynchronizableEntityDTO} from "../../SynchronizableEntityDTO";
import _ from "lodash";
import {PlaylistItemDTO, PlaylistItemDTOCompanion} from "../../v1/playlist/PlaylistItemDTO";

/**
 * @deprecated use v3
 */
export interface PlaylistDTO extends SynchronizableEntityDTO<PlaylistDTO> {
    schemaVersion: number;
    identifier: string;
    title: string;
    tags: ReadonlyArray<string>;
    items: ReadonlyArray<PlaylistItemDTO>;
    createdAt: Date;
    updatedAt: Date;
    lastServerVersion: number;
    needsPush: boolean;
}

/**
 * @deprecated use v3
 */
export class PlaylistDTOCompanion {
    public static readonly SCHEMA_VERISON = 2;

    public static create(
        identifier: string,
        title: string,
        tags: ReadonlyArray<string>,
        items: ReadonlyArray<PlaylistItemDTO> = [],
        createdAt: Date,
        updatedAt: Date,
        lastServerVersion: number,
        needsPush: boolean
    ): PlaylistDTO {
        return {
            schemaVersion: this.SCHEMA_VERISON,
            identifier: identifier,
            title: title,
            tags: tags,
            items: items,
            createdAt: createdAt,
            updatedAt: updatedAt,
            lastServerVersion: lastServerVersion,
            needsPush: needsPush,
        }
    }

    static copyWithNeedsPush(s: PlaylistDTO, needsPush: boolean): PlaylistDTO {
        const clone = _.cloneDeep(s);
        clone.needsPush = needsPush;
        return clone;
    }

    //Workaround, until we have a proper parsing...
    public static fromParsedJson(i: PlaylistDTO): PlaylistDTO {
        const items = PlaylistItemDTOCompanion.fromParsedJson(i.items);

        return {
            schemaVersion: this.SCHEMA_VERISON,
            identifier: i.identifier,
            title: i.title,
            tags: i.tags,
            items: items,
            createdAt: new Date(i.createdAt),
            updatedAt: new Date(i.updatedAt),
            lastServerVersion: i.lastServerVersion,
            needsPush: i.needsPush,
        }
    }
}