import {ImageUrlResolver} from "../helper/ImageUrlResolver";
import {TableOfContentsDTO} from "../dto/v1/toc/TableOfContentsDTO";

export class TocTransformer {
    private readonly imagePathResolver = new ImageUrlResolver();

    public buildUrlListForAllPagesOfAllSongs(toc: TableOfContentsDTO): Set<string> {
        const urls = new Set<string>();
        for(const tocEntry of toc.values()){
            this.imagePathResolver.resolveAllUrlsForSong(tocEntry).forEach(v => urls.add(v));
        }
        return urls;
    }

}