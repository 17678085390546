export class Permission {

    public static songsheetsRead = new Permission("songsheets:read", "Lesen von Liedernoten")

    public readonly name: string;
    public readonly description: string;

    constructor(name: string, description: string) {
        this.name = name;
        this.description = description;
    }
}


export const permissionsToRequest = [Permission.songsheetsRead]