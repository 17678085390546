import LocalStoragePersistence from "../localStoragePersistence/common/LocalStoragePersistence";
import {CacheManager} from "../offline/CacheManager";

export class LocalDataRemover {
    public removeAllLocalData(): Promise<void> {
        return new CacheManager().deleteAllCaches()
            .then((result) => {
                if (result) {
                    new LocalStoragePersistence().clearLocalStorageWithExceptions();
                } else {
                    throw new Error("Error while deleting cache (browser cache api).");
                }
            })
    }
}
