import {TocEntryDTO} from "../../dto/v1/toc/TocEntryDTO";

export class PreferredSongnumberFinder {
    public findPreferredSongnumber(toCEntry: TocEntryDTO, preferredSongbookId: number | undefined): string | undefined {
        const songNumberInPreferredSongbook = toCEntry.songnumbers.find(sn => sn.songbookId === preferredSongbookId);
        if (songNumberInPreferredSongbook) {
            return songNumberInPreferredSongbook.number
        } else if (toCEntry.songnumbers.length > 0) {
            const anyOtherAvailableNumber = toCEntry.songnumbers[0];
            return anyOtherAvailableNumber.mnemonic + anyOtherAvailableNumber.number;
        } else {
            return undefined
        }
    }
}