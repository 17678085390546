import * as React from 'react';
import {Button, Card, CardBody, CardHeader, CardText, CardTitle, Col, Container} from 'reactstrap';
import {auth0UserStub} from "../Auth0UserStub";
import {StubTokenDefinition} from "./StubTokenDefinition";
import {CyStubAuth} from "./CyStubAuth";
import {RoutePaths} from "../../RoutePaths";

export const SONGSHIP_STUB_TOKEN = "songship_stub_token";

export function StubLogin() {
    function persistFakeLoginToken(fakeTokenStaticPart : string) {
        //don't use the history.push() because we simulate a coming back from Auth0 which is a different domain.
        window.location.href = RoutePaths.CALLBACK + `#${SONGSHIP_STUB_TOKEN}=${new StubTokenDefinition(fakeTokenStaticPart).token()}`;
    }
    return (
        <Container style={{width: "30rem"}}>
            <Col >
                <Card >
                    <CardHeader className="pb-0" style={{backgroundColor: "#f8f9fa", borderBottom: "none"}}>
                        <h2 className={"light-header"}>
                            Stub Login
                        </h2>
                    </CardHeader>
                    <CardBody>
                        {auth0UserStub.map(s => (
                            <Card body className="mb-2" key={s.tokenWithoutPrefix}>
                                <CardTitle tag="h5">{s.tokenWithoutPrefix}</CardTitle>
                                <CardText>
                                    {s.content.permissions.map(p => <React.Fragment key={p}>{p}<br/></React.Fragment>)}
                                    <br/>
                                    <Button color="primary" data-cy={`${CyStubAuth.LoginButtonPrefix}-${s.tokenWithoutPrefix}`} onClick={() => persistFakeLoginToken(s.tokenWithoutPrefix)}>Login</Button>
                                </CardText>

                            </Card>
                        ))}
                    </CardBody>
                </Card>
            </Col>
        </Container>);
}