export const FAKE_TOKEN_PREFIX = "devTestToken"
export const ACCESS_TOKEN_TO_USE_STORAGE_KEY = "AuthAlwaysAuthorizedStub-accessTokenToUse";


export class StubTokenDefinition {
    private readonly _tokenWithoutPrefix: string

    constructor(tokenWithoutPrefix: string) {
        this._tokenWithoutPrefix = tokenWithoutPrefix;
    }

    get tokenWithoutPrefix(): string {
        return this._tokenWithoutPrefix;
    }

    token() {
        return `${FAKE_TOKEN_PREFIX}${this._tokenWithoutPrefix}`
    }

}

