import {StubTokenDefinition} from "./stub/StubTokenDefinition";

export const userSongshipUser1TokenDefinition = new StubTokenDefinition("SongshipUser1")

export const userNoLinkToSonganizeTokenDefinition = new StubTokenDefinition("NoLinkToSonganize")

export const payingEmptyTokenDefinition = new StubTokenDefinition("PayingEmpty")

export type FakeTokenEntry = ({ content: { permissions: string[] }; tokenWithoutPrefix: string });

export function permissionsFor(stubToken: StubTokenDefinition): readonly string[] {
    let fakeTokenEntry = auth0UserStub.find(f => f.tokenWithoutPrefix === stubToken.tokenWithoutPrefix);
    return fakeTokenEntry ? fakeTokenEntry.content.permissions : []
}

//taken from the server: data/auth0UserStub.json
//keep in sync!
export const auth0UserStub: FakeTokenEntry[] = [
    {
        "tokenWithoutPrefix": "NoLinkToSonganize",
        "content": {
            "permissions": []
        }
    },
    {
        "tokenWithoutPrefix": "PayingEmpty",
        "content": {
            "permissions": [
                "songsheets:read"
            ]
        }
    },
    {
        "tokenWithoutPrefix": "SonganizeOnly",
        "content": {
            "permissions": []
        }
    },
    {
        "tokenWithoutPrefix": "SongshipUser1",
        "content": {
            "permissions": [
                "songsheets:read"
            ]
        }
    }
];
