export enum CurrentState {
    REFRESHING_TOC,
    CHECKING_CURRENT_DATA_IN_CACHE,
    CALCULATE_DELTA,
    DOWNLOAD,
    DELETE_OLD,
    FINISHED,
    FINISHED_WITH_ERRORS,
    ABORTED_WITH_ERROR
}

export class OfflineUpdateSummary {
    public currentState: CurrentState = CurrentState.REFRESHING_TOC;
    public amountInToc: number = 0;
    public amountInCache: number = 0;
    public initialAmountToBeDownloaded: number = 0;
    public initialAmountToBeDeleted: number = 0;
    public leftAmountToBeDownloaded: number = 0;

    public getProgress(): number {
        if (this.currentState < CurrentState.DOWNLOAD) {
            return 0;
        } else if (this.currentState > CurrentState.DOWNLOAD) {
            return 100;
        } else {
            return Math.round(100 - this.leftAmountToBeDownloaded * 100 / this.initialAmountToBeDownloaded);
        }
    }
}