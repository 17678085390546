import * as React from 'react';
import {Card, CardBody, CardHeader, ListGroup, ListGroupItem} from 'reactstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHandsHelping} from '@fortawesome/free-solid-svg-icons';
import {ContactFormLink} from "../helper/ContactFormLink";

export function HelpInfo() {
    return (
        <Card className="mt-3">
            <CardHeader><h2 className={"light-header"}>
                <FontAwesomeIcon icon={faHandsHelping}/> Hilfe & Kontakt</h2>
            </CardHeader>
            <CardBody>
                <ListGroup flush>
                    <ListGroupItem>
                        Hast du ein Anliegen, eine Frage oder ein technisches Problem? <a href={ContactFormLink} target="_blank" rel="noreferrer">Mit diesem Formular</a> kannst du uns kontaktieren.
                    </ListGroupItem>
                </ListGroup>
            </CardBody>
        </Card>
    );
}
