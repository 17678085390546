export class InProgress {
    public readonly state  = "InProgress";
}

export class SuccessfullyJoined {
    public readonly state = "SuccessfullyJoined";
    title: string

    constructor(title: string) {
        this.title = title;
    }
}

export class JoiningError {
    public readonly state = "JoiningError";
    errorMessage: string

    constructor(errorMessage: string) {
        this.errorMessage = errorMessage;
    }
}

export type JoinCrewState =
    | InProgress
    | SuccessfullyJoined
    | JoiningError;
