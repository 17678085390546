import * as React from 'react';
import {Alert, Button, Progress} from 'reactstrap';
import {CurrentState, OfflineUpdateSummary} from "./OfflineUpdateSummary";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationCircle, faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import './DownloadIndicator.css';

interface Props {
    offlineUpdateSummary: OfflineUpdateSummary;
    resetProgress: () => void
}

class DownloadIndicator extends React.Component<Props, {}> {
    public render() {
        const currentState = this.props.offlineUpdateSummary.currentState;
        return (
            <>
                {currentState < CurrentState.DOWNLOAD && (
                    <Progress animated
                              value="100" className="progress-workaround">
                        {currentState === CurrentState.REFRESHING_TOC ? (
                            <>Inhaltsverzeichnis vom Internet herunterladen</>
                        ) : currentState === CurrentState.CHECKING_CURRENT_DATA_IN_CACHE ? (
                            <>Daten vom Gerät laden</>
                        ) : (
                            <>Berechne Unterschiede</>
                        )}
                    </Progress>)}
                {currentState === CurrentState.DOWNLOAD && (
                    <Progress animated={currentState === CurrentState.DOWNLOAD}
                              value={this.props.offlineUpdateSummary.getProgress()}
                              className="progress-workaround">
                        Noten herunterladen ({this.props.offlineUpdateSummary.getProgress()}%)
                    </Progress>)}
                {currentState === CurrentState.DELETE_OLD && (
                    <Progress animated={true} value="100" className="progress-workaround">
                        Aufräumen
                    </Progress>)}
                {currentState === CurrentState.FINISHED && (
                    <Progress animated={false} value="100" className="progress-workaround">
                        <Button color="primary" onClick={() => this.props.resetProgress()}>Fertig!</Button>
                    </Progress>)}
                {currentState === CurrentState.FINISHED_WITH_ERRORS && (
                    <Alert color="warning">
                        <h2 className={"light-header"}><FontAwesomeIcon icon={faExclamationTriangle} /> Download mit Fehlern abgeschlossen</h2>
                        Beim Herunterladen der Lieder sind Fehler aufgetreten. Bitte rechne damit, dass gewisse Lieder offline nicht zur Verfügung stehen werden!<br />
                        Bei Problemen hilft dir der Support gerne weiter.<br /><br/>
                        <Button color="primary" onClick={() => this.props.resetProgress()}>Schliessen</Button>
                    </Alert>
                )}
                {currentState === CurrentState.ABORTED_WITH_ERROR && (
                    <Alert color="danger">
                        <h2 className={"light-header"}><FontAwesomeIcon icon={faExclamationCircle} /> Download Abgebrochen</h2>
                        Der Download wurde aufgrund eines Fehlers abgebrochen. Deine Daten stehen nicht offline bereit!<br/>Bei Problemen hilft dir der Support gerne weiter.<br /><br/>
                        <Button color="primary" onClick={() => this.props.resetProgress()}>Schliessen</Button>
                    </Alert>
                )}

            </>
        );
    }
}

export default DownloadIndicator;
