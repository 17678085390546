import * as React from 'react';
import QrReader from 'react-qr-reader-es6';

interface Props {
    onScanHandler: (data?: string) => void;
}

interface State {
    delay: number,
    result?: string
}

export class QrCodeReader extends React.Component<Props, State> {
    constructor(props : Props) {
        super(props);
        this.state = {
            delay: 100,
            result: undefined
        };
        this.handleScan = this.handleScan.bind(this);
    }

    private handleScan(data : string | null) {
        if (data) {
            this.setState({
                result: data
            });
            this.props.onScanHandler(data);
        }
    }

    private handleError(err : any) {
        console.error(err);
    }

    public render() {
        return (
            <QrReader
                delay={this.state.delay}
                onError={this.handleError}
                onScan={this.handleScan}
            />
        );
    }
}
