import * as React from 'react';
import {Fragment} from 'react';
import {Badge, Button, Card, CardBody, CardHeader, ListGroup, ListGroupItem} from 'reactstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowCircleLeft,
    faCheck,
    faCogs,
    faLock,
    faTimes,
    faUser,
    faUserLock
} from '@fortawesome/free-solid-svg-icons';
import {Link} from "react-router-dom";
import Auth from "../auth/Auth";
import Header from "../Header";
import {CySettings} from "./CySettings";
import {CrewSettings} from "./CrewSettings";
import {OfflineSettings} from "./OfflineSettings";
import {HelpInfo} from "./HelpInfo";
import {UiConfig} from "../Config";

interface Props {
    auth: Auth,
    startUpdate: () => void,
}

export default function Settings(props: Props) {
    return (
        <>
            <Header/>
            <div className="mt-3">
                <Link to="/"><Button color="primary" size="lg"><FontAwesomeIcon
                    icon={faArrowCircleLeft}/> Zurück</Button></Link>
            </div>
            <Card className="mt-3">
                <CardHeader><h2 className={"light-header"}>
                    <FontAwesomeIcon icon={faUser}/> Benutzer</h2>
                </CardHeader>
                <CardBody>
                    <ListGroup flush>
                        <ListGroupItem data-cy={CySettings.PermissionList}>
                            <div style={{width: "30px", display: "inline-block"}}><FontAwesomeIcon icon={faUserLock}/>
                            </div>
                            {props.auth.getEmail()}<br/><br/>

                            {props.auth.getPermissionOverview().map((p, i) => (<Fragment key={i}>
                                <div style={{width: "30px", display: "inline-block"}}>{p.granted ?
                                    <FontAwesomeIcon icon={faCheck}/> : <FontAwesomeIcon icon={faTimes}/>}</div>
                                {p.permission.description}&nbsp;
                                <Badge color={p.granted ? "success" : "danger"}
                                       data-cy={p.granted ? CySettings.PermissionGranted : CySettings.PermissionDeclined}>{p.permission.name}</Badge><br/>
                            </Fragment>))}

                            <br/>
                            <Button color="primary" onClick={() => props.auth.logout()}
                                    data-cy={CySettings.LogoutButton}><FontAwesomeIcon icon={faLock}/> Abmelden</Button><br/>
                            <small>Klicke, um dich abzumelden oder den Benutzer zu wechseln.</small>
                        </ListGroupItem>
                    </ListGroup>
                </CardBody>
            </Card>

            <CrewSettings/>

            <OfflineSettings/>

            <HelpInfo/>

            <Card className="mt-3">
                <CardHeader><h2 className={"light-header"}>
                    <FontAwesomeIcon icon={faCogs}/> Version</h2>
                </CardHeader>
                <CardBody>
                    <ListGroup flush>
                        <ListGroupItem>
                            Songship Version {UiConfig.appVersion}
                        </ListGroupItem>
                    </ListGroup>
                </CardBody>
            </Card>
        </>
    );
}
