import {BackendServerAvailability} from "./BackendServerAvailability";
import {AuthState} from "./AuthState";
import LocationRestorerAfterLogin from "./LocationRestorerAfterLogin";
import {TocFetcher} from "./TocFetcher";
import Auth from "../auth/Auth";
import {BackendConfig} from "../Config";
import App from "../App";
import {TocAvailability} from "./TocAvailability";
import {TableOfContentsDTO} from "../dto/v1/toc/TableOfContentsDTO";
import {PlaylistPusher} from "../playlist/sync/PlaylistPusher";
import {PlaylistFetcher} from "../playlist/sync/PlaylistFetcher";
import {Client} from "urql";
import {PlaylistV2ToV3} from "../dto/migration/PlaylistV2ToV3";
import SwitchUtils from "../common/SwitchUtils";
import {LoginByDifferentUserHandler} from "../auth/LoginByDifferentUserHandler";
import {SONGSHIP_STUB_TOKEN} from "../auth/stub/StubLogin";
import history from "../history";
import {TocIdDTO} from "../dto/v3/toc/TocIdDTO";
import {TocEntryDTO} from "../dto/v1/toc/TocEntryDTO";
import {RoutePaths} from "../RoutePaths";


export class AppInitializer {

    constructor(private readonly auth: Auth, private readonly graphQlClient: Client, private readonly app: App) {

        this.checkIfDifferentUserLoggedInLastTime = this.checkIfDifferentUserLoggedInLastTime.bind(this);
    }

    public initApp(): Promise<TableOfContentsDTO | TocAvailability> {
        console.info("Starting initialization");

        //Note, if you impelement your migration: For migrations you cannot expect a ToC to be available.
        PlaylistV2ToV3.migrate();
        console.debug("All migrations done");

        return this.checkBackendConnection()
            .then((b: BackendServerAvailability) => this.updateBackendServerAvailability(b))
            .then((backendServerAvailability: BackendServerAvailability) => this.verifyAuthentication(backendServerAvailability))
            .then((a: AuthState) => this.checkIfDifferentUserLoggedInLastTime(a))
            .then((a: AuthState) => this.updateAuthState(a))
            .then((a: AuthState) => {
                switch (a) {
                    case AuthState.RedirectToLoginNecessary:
                        return this.redirectToLogin();
                    case AuthState.OkWithOnlineAuthentication:
                        return this.fetchToc()
                            .then((tocOrState: TableOfContentsDTO | TocAvailability) => this.syncPlaylistsIfOnlineAndAuthenticated(tocOrState));
                    case AuthState.OkButOffline :
                        //we also fetch if we are offline because we have a cache in place
                        return this.fetchToc();
                    case AuthState.LocalStorageDeletionNecessary:
                    case AuthState.Failed:
                    case AuthState.Undecided:
                        return TocAvailability.ToCNotAvailable;
                    default:
                        return SwitchUtils.throwUnsupportedValue(a);
                }
            });
    }

    public checkBackendConnection(): Promise<BackendServerAvailability> {
        console.debug("Checking backend connection to: " + BackendConfig.baseUrl);
        return fetch(BackendConfig.baseUrl, {mode: "cors"}).then(response => {
            if (response.ok) {
                console.debug("Backend server availability: ", BackendServerAvailability.Available);
                return BackendServerAvailability.Available;
            } else {
                console.warn("AppInitializer: check backend connection did not answer with ok but " + response.status + ". Strange... Backend state will be unavailable.");
                return BackendServerAvailability.Unavailable;
            }
        }).catch(e => {
            console.debug("Backend server availability will be unavailable. Reason: ", e);
            return BackendServerAvailability.Unavailable;
        })
    }

    private verifyAuthentication(backendServerAvailability: BackendServerAvailability): Promise<AuthState> {
        switch (backendServerAvailability) {
            case BackendServerAvailability.Available:
                if (this.auth.isAuthenticated()) {
                    AppInitializer.verifyNoOneIsCallingCallbackRouteWhenAlreadyAuthenticated();
                    return Promise.resolve(AuthState.OkWithOnlineAuthentication)
                } else {
                    if (!window.location.href.includes(RoutePaths.CALLBACK)) {
                        return Promise.resolve(AuthState.RedirectToLoginNecessary);
                    } else {
                        return this.handleCallback()
                    }
                }
            case BackendServerAvailability.Unavailable:
                return Promise.resolve(AuthState.OkButOffline);
            case BackendServerAvailability.Undecided:
                return Promise.reject("BackendServerAvailability is " + BackendServerAvailability[backendServerAvailability] + ". This should not happen here.");
        }
    }

    private static verifyNoOneIsCallingCallbackRouteWhenAlreadyAuthenticated() {
        if (window.location.href.includes(RoutePaths.CALLBACK)) {
            console.warn("Callback route was called. But the user already is authenticated!")
            history.push("/");
        }
    }

    private redirectToLogin(): Promise<TocAvailability> {
        console.debug(("online but not yet authenticated --> redirect to login"));
        new LocationRestorerAfterLogin().writeCurrentLocationToLocalStorage();
        this.auth.login();
        return Promise.resolve(TocAvailability.RedirectToLoginNecessary)
    }

    private handleCallback(): Promise<AuthState> {
        const locHash = window.location!.hash;
        if (new RegExp("access_token|id_token|error|" + SONGSHIP_STUB_TOKEN).test(locHash)) {
            console.debug("auth calls back with the following hash: ", {"hash": window.location!.hash});
            return this.auth.handleAuthentication()
                .then(r => {
                    new LocationRestorerAfterLogin().navigateToLocationBeforeLogin("/");
                    return Promise.resolve(r);
                })
        } else {
            return Promise.reject("The callback url '" + locHash + "' is not a propper callback url.");
        }
    }

    private checkIfDifferentUserLoggedInLastTime(r: AuthState): AuthState {
        if (r === AuthState.OkWithOnlineAuthentication) {
            let auth0Subject = this.auth.getAuth0Subject();
            if (LoginByDifferentUserHandler.isMessageToUserNecessaryThatDeletionOfLocalStorageDataIsNeededToLogin(auth0Subject)) {
                return AuthState.LocalStorageDeletionNecessary
            } else {
                LoginByDifferentUserHandler.persistCurrentAuth0Subject(auth0Subject);
                return r
            }
        } else {
            return r
        }
    }

    private fetchToc(): Promise<TableOfContentsDTO | TocAvailability> {
        return new TocFetcher(this.auth).fetch();
    }

    private updateBackendServerAvailability(b: BackendServerAvailability): Promise<BackendServerAvailability> {
        return new Promise<BackendServerAvailability>(resolve => {
            this.app.setState(() => ({
                backendServerAvailability: b
            }), () => resolve(b));
        })
    }

    private updateAuthState(a: AuthState): Promise<AuthState> {
        return new Promise<AuthState>(resolve => {
            this.app.setState(() => ({
                authState: a
            }), () => resolve(a));
        })
    }

    private syncPlaylistsIfOnlineAndAuthenticated(tocOrState: TableOfContentsDTO | TocAvailability): Promise<TableOfContentsDTO | TocAvailability> {
        const lookupSong: (tocId: TocIdDTO) => TocEntryDTO | undefined = (tocId: TocIdDTO) => {
            return tocOrState instanceof TableOfContentsDTO ? tocOrState.get(tocId) : undefined;
        }

        const fetcher = new PlaylistFetcher(this.graphQlClient, lookupSong);
        const pusher = new PlaylistPusher(this.auth);
        return fetcher.run()
            .then((fetchResult) => {
                return pusher.run()
                    .then(pushResult => ({fetchResult: fetchResult, pushResult: pushResult}));
            })
            .then((syncResult) => {
                if (syncResult.pushResult.pushError.length > 0) {
                    console.warn("There were sync errors!", syncResult)
                } else {
                    console.info("PlaylistPusher finished.", syncResult);
                }
                return tocOrState
            })
            .catch(function (error) {
                const message = "Error while syncing playlists";
                console.error(message, error);
                return tocOrState
            })
    }

}