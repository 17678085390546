import * as React from 'react';
import {Switcher} from "../../common/Switcher";

interface Props {
    values: string[]
    selectedValues: string[]
    selectedValuesSetter: (values: string[]) => void
}

function FilterButtonGroup(props: Props) {

    return (
        <>
            {props.values.map((s: string) =>
                <Switcher value={s} isActive={props.selectedValues.includes(s)} setIsActive={() => toggleValue(s)}/>
            )}
        </>
    );

    function toggleValue(toggeledValue: string) {
        if (props.selectedValues.includes(toggeledValue)) {
            props.selectedValuesSetter(props.selectedValues.filter(v => v !== toggeledValue))
        } else {
            props.selectedValuesSetter(props.selectedValues.concat(toggeledValue))
        }
    }
}

export default FilterButtonGroup;
