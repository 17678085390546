import * as React from 'react';
import {useEffect, useState} from 'react';
import {Button, Col, Row} from 'reactstrap';
import {PlaylistIndexManipulator} from "./PlaylistIndexManipulator";
import {LinkCreator} from "../LinkCreator";
import {useHistory} from "react-router-dom";
import './PlaylistIndex.css';

import {v4 as uuidv4} from 'uuid';
import {faCaretDown, faCirclePlus, faQrcode} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import PlaylistData from "../playlist/data/PlaylistData";
import {CyPlaylistIndex} from "./CyPlaylistIndex";
import {PlaylistIndexTexts} from "./PlaylistIndexTexts";
import {CrewAwareEditSongsNavItem} from "../mainmenu/CrewAwareEditSongsNavItem";

interface Props {
    performIndexRefresh: () => void
    playlistIndexData: readonly PlaylistData[]
    scanQrCodeDialogActive: boolean
    setScanQrCodeDialogActive: ((value: (((prevState: boolean) => boolean) | boolean)) => void)
    showDetailsInPlaylistSummary: boolean
    setShowDetailsInPlaylistSummary: ((value: (((prevState: boolean) => boolean) | boolean)) => void)
    playlistAdvancedMenuOpen: boolean
    setPlaylistAdvancedMenuOpen: ((value: (((prevState: boolean) => boolean) | boolean)) => void)
    currentCrewIdentifier: string | undefined
}

export function PlaylistIndexToolbar(props: Props) {

    //This is a hack because we cannot jump to the new page inside method createPlaylistAndOpen. This would prevent playlistIndex from being updated correctly
    const [newlyCreatedPlaylistIdentifier, setNewlyCreatedPlaylistIdentifier] = useState<string>();

    const history = useHistory();

    function createPlaylistAndOpen() {
        const identifierToCreate = uuidv4();
        PlaylistIndexManipulator.createPlaylist(identifierToCreate, props.currentCrewIdentifier);
        setNewlyCreatedPlaylistIdentifier(identifierToCreate);
    }

    useEffect(() => {
        if (newlyCreatedPlaylistIdentifier) {
            history.push(new LinkCreator().toPlaylist(newlyCreatedPlaylistIdentifier));
        }
    }, [history, newlyCreatedPlaylistIdentifier])

    return (
        <Row className="mt-4 mb-0 g-2 row-cols-auto">
            <Col>
                <Button onClick={() => createPlaylistAndOpen()} data-cy={CyPlaylistIndex.ButtonAddPlaylist} color="primary">
                    <FontAwesomeIcon icon={faCirclePlus}/> {PlaylistIndexTexts.AddPlaylist}
                </Button>
            </Col>
            <Col>
                <CrewAwareEditSongsNavItem/>
            </Col>
            <Col>
                <Button active={props.scanQrCodeDialogActive} onClick={() => {
                    props.setScanQrCodeDialogActive(p => !p)
                    props.setPlaylistAdvancedMenuOpen(false)
                }} color="primary">
                    <FontAwesomeIcon icon={faQrcode}/> QR Code importieren
                </Button>
            </Col>
            <Col>
                <Button color="primary" active={props.playlistAdvancedMenuOpen} onClick={() => {
                    props.setPlaylistAdvancedMenuOpen(p => !p)
                    props.setScanQrCodeDialogActive(false)
                }}>
                    Mehr <FontAwesomeIcon icon={faCaretDown}/>
                </Button>
            </Col>
        </Row>
    );
}