import * as React from 'react';
import {useState} from 'react';
import {
    useStateWithLocalStoragePersistenceAndDefault
} from "../common/hooks/useStateWithLocalStoragePersistenceAndDefault";
import {useBeamerBroadcastChannelReceiver} from "./useBeamerBroadcastChannelReceiver";
import {CyBeamer} from "./CyBeamer";
import {BeamerBroadcastChannel} from "./BeamerBroadcastChannel";
import {LocalStorageKey} from "../localStoragePersistence/common/LocalStorageKey";


export function SongsheetBeamerView() {

    const [fontSizeInViewHeight, setFontSizeInViewHeight]: [number, (value: (((prevState: number) => number) | number)) => void] = useStateWithLocalStoragePersistenceAndDefault<number>(new LocalStorageKey("SongsheetBeamerView", "fontSizeInViewHeight"), 8);

    const [content, setContent] = useState<string>(BeamerBroadcastChannel.BeamerViewDefaultContent);

    useBeamerBroadcastChannelReceiver(setContent, setFontSizeInViewHeight);

    return (
        <div data-cy={CyBeamer.LyricsContent}
             style={{
                 backgroundColor: "black",
                 fontSize: fontSizeInViewHeight + "vh",
                 color: "whitesmoke",
                 height: "100vh",
                 padding: "1vw",
                 whiteSpace: "pre-line"
             }}>
            {content}
        </div>
    );
}