import * as React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileLines} from "@fortawesome/free-solid-svg-icons";
import './TextOnlySongSlide.css';

export const TextOnlySongSlide = () => {
    return (
        <div className="text-only-song">
            <div>
                <div className="p-3">
                    <h1>
                        <FontAwesomeIcon icon={faFileLines}/><br/>
                    </h1>
                    Dieses Lied enthält keine Noten.<br/>Wechsle in den Einstellungen zur Ansicht "Liedtext" um den Text anzuzeigen.
                </div>
            </div>
        </div>
    );
}
