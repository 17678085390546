import {CacheManager} from "./CacheManager";
import {Blackboard} from "./Blackboard";
import {CurrentState, OfflineUpdateSummary} from "./OfflineUpdateSummary";
import {TableOfContentsDTO} from "../dto/v1/toc/TableOfContentsDTO";
import Auth from "../auth/Auth";
import {UpdateDeltaCalculator} from "./UpdateDeltaCalculator";
import {TocTransformer} from "./TocTransformer";
import {FetchWithAuth} from "../common/FetchWithAuth";

export class UpdateWorker {
    private readonly tocTransformer = new TocTransformer();
    private readonly notifyProgress: (summary: OfflineUpdateSummary) => void;

    private readonly cacheManager: CacheManager;
    private readonly auth: Auth;

    constructor(auth: Auth, notifyProgress: (summary: OfflineUpdateSummary) => void) {
        this.cacheManager = new CacheManager();
        this.auth = auth;
        this.notifyProgress = notifyProgress;

        this.update = this.update.bind(this);
    }

    update(toc: TableOfContentsDTO): Promise<void> {
        const blackboard = new Blackboard(this.notifyProgress, toc);
        return Promise.resolve(blackboard)
            .then((bb: Blackboard) => this.findFilenamesInCache(bb))
            .then((bb: Blackboard) => this.calculateDelta(bb))
            .then((bb: Blackboard) => this.delete(bb))
            .then((bb: Blackboard) => this.download(bb))
            .then((bb: Blackboard) => {
                if (bb.failures.length > 0) {
                    bb.updateAndNotifyCurrentState(CurrentState.FINISHED_WITH_ERRORS);
                } else {
                    bb.updateAndNotifyCurrentState(CurrentState.FINISHED);
                }
                console.log("Blackboard: ", bb);
            })
            .catch(e => {
                console.error(e.message);
                const summary = new OfflineUpdateSummary();
                summary.currentState = CurrentState.ABORTED_WITH_ERROR;
                this.notifyProgress(summary);
            })
    }

    private findFilenamesInCache(blackboard: Blackboard): Promise<Blackboard> {
        blackboard.updateAndNotifyCurrentState(CurrentState.CHECKING_CURRENT_DATA_IN_CACHE);
        return this.cacheManager.findAllSongsheetCacheKeys()
            .then((cacheUrls: string[]) => {
                blackboard.setUrlsFromCache(new Set(cacheUrls));
                return Promise.resolve(blackboard);
            });
    }

    private calculateDelta(blackboard: Blackboard) {
        blackboard.updateAndNotifyCurrentState(CurrentState.CALCULATE_DELTA);
        blackboard.work = new UpdateDeltaCalculator().calculateDelta(blackboard.input.urlsFromToc, blackboard.input.urlsFromCache);
        return Promise.resolve(blackboard);
    }

    private download(blackboard: Blackboard): Promise<Blackboard> {
        blackboard.updateDownloadsLeft(blackboard.work.urlsToBeDownloaded.length);
        if (blackboard.work.urlsToBeDownloaded.length > 0) {
            let url = blackboard.work.urlsToBeDownloaded.shift()!;
            return this.addUrlToCache(url)
                .catch(function (error) {
                    blackboard.failures.push("Fehler beim Download von " + url + ": " + error);
                })
                .then(() => Promise.resolve(this.download(blackboard)));
        } else {
            return Promise.resolve(blackboard);
        }
    }

    private addUrlToCache(url: string) {
        let cm = this.cacheManager;
        return new FetchWithAuth(this.auth).fetch(url)
            .then(function (response) {
                return cm.addToCache(url, response);
            })
    }

    private delete(blackboard: Blackboard) {
        blackboard.updateAndNotifyCurrentState(CurrentState.DELETE_OLD);
        let promises = [...blackboard.work.urlsToBeDeleted].map((filename) => this.cacheManager.deleteSongsheetFile(filename));
        return Promise.all(promises)
            .catch((error) => {
                blackboard.failures.push("Fehler beim Löschen: " + error);
            })
            .then(() => Promise.resolve(blackboard));
    }
}