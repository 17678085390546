import {err, ok, Result} from "neverthrow";

export type ParseError = { message: string, content: string, error?: Error }

export class JsonParsing {
    public parse<T>(content: string): Result<T, ParseError> {
        try {
            //TODO: parser with error handling
            const result: T = JSON.parse(content);
            return ok(result);
        } catch (e: any) {
            return err({
                message: "Error while parsing content as json.",
                content: content,
                error: e
            })
        }
    }

}
