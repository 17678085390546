import {BroadcastChannel} from "broadcast-channel";
import {BeamerBroadcastChannel} from "./BeamerBroadcastChannel";

export class BeamerBroadcastChannelSender {
    readonly channel: BroadcastChannel;

    constructor() {
        this.channel = new BroadcastChannel(BeamerBroadcastChannel.ChannelName);
    }

    public sendText(text: string) {
        this.channel.postMessage(text);
    }

    public emptyScreen() {
        this.channel.postMessage("");
    }

    public increaseFontSize(){
        this.channel.postMessage(BeamerBroadcastChannel.IncreaseFontSize);
    }

    public decreaseFontSize(){
        this.channel.postMessage(BeamerBroadcastChannel.DecreaseFontSize);
    }
}
