const ScottySourceSystem = "scotty";

/**
 * @deprecated use v3
 */
export class TocIdDTO {
    public readonly songId: number;
    public readonly sourceSystem: string;

    constructor(songId: number, sourceSystem: string) {
        this.songId = songId;
        this.sourceSystem = sourceSystem
    }

    static fromSongIdAndSourceSystem(songId: number, sourceSystem?: string) {
        return new TocIdDTO(songId, sourceSystem ? sourceSystem : ScottySourceSystem)
    }

    static fromString(stringTocId : string): TocIdDTO | undefined {
        const splitted = stringTocId.split("_", 2);
        let songId : number | undefined;
        let sourceSystem : string;
        if(splitted.length === 1){
            sourceSystem = ScottySourceSystem;
            songId = Number(splitted[0]) || undefined
        }else{
            sourceSystem = splitted[0];
            songId = Number(splitted[1]) || undefined
        }
        return songId ? new TocIdDTO(songId, sourceSystem) : undefined
    }

    public stringify(): string {
        return this.sourceSystem !== ScottySourceSystem ? `${this.sourceSystem}_${this.songId}` : this.songId + ""
    }
}