import {Auth0DecodedHash, WebAuth} from "auth0-js";
import history from '../history';
import {AuthState} from "../appinitandstate/AuthState";
import _ from "lodash";
import {permissionsToRequest} from "./Permission";
import Auth from "./Auth";
import LocalStoragePersistence from "../localStoragePersistence/common/LocalStoragePersistence";
import {AuthConfig} from "../Config";

export default class AuthImpl extends Auth {
    private accessToken?: string;
    private idToken?: string;
    private expiresAt?: number;
    private grantedPermissionsString?: string;
    private name?: string; //currently, this is the users email in auth0
    //TODO: Do we always have a subject
    private subject?: string; //something like "auth0|5c...e16e"

    private readonly auth0: WebAuth;

    constructor() {
        super();
        this.login = this.login.bind(this);
        this.logout = this.logout.bind(this);
        this.handleAuthentication = this.handleAuthentication.bind(this);
        this.isAuthenticated = this.isAuthenticated.bind(this);
        this.getAccessToken = this.getAccessToken.bind(this);
        this.getIdToken = this.getIdToken.bind(this);
        this.renewSession = this.renewSession.bind(this);
        this.getEmail = this.getEmail.bind(this);
        this.getAuth0Subject = this.getAuth0Subject.bind(this);
        this.setSession = this.setSession.bind(this);
        this.getGrantedPermissionsString = this.getGrantedPermissionsString.bind(this);

        this.auth0 = new WebAuth({
            domain: AuthConfig.domain,
            clientID: AuthConfig.clientId,
            redirectUri: AuthConfig.callbackUrl,
            responseType: 'id_token token',
            scope: 'openid profile ' + _.join(permissionsToRequest.map(p => p.name), " "), //these are the permissions that we ask for. Auth0 decides, which one the user really gets.
            audience: AuthConfig.audience
        });
    }

    login() {
        this.auth0.authorize();
    }

    handleAuthentication(): Promise<AuthState> {
        return new Promise<AuthState>(resolve => {
            this.auth0.parseHash((err, authResult) => {
                if (authResult && authResult.accessToken && authResult.idToken) {
                    this.setSession(authResult);
                    return resolve(AuthState.OkWithOnlineAuthentication)
                } else {
                    console.error(err);
                    return resolve(AuthState.Failed);
                }
            });
        });
    }

    getAccessToken() {
        return this.accessToken;
    }

    getIdToken() {
        return this.idToken;
    }

    getEmail(): string | undefined {
        return this.name;
    }

    getAuth0Subject(): string {
        if (this.subject === undefined) throw new Error("Illegal State: expected not to be undefined.");
        return this.subject
    }

    private setSession(authResult: Auth0DecodedHash) {
        // Set the time that the access token will expire at
        let expiresAt: number = (authResult.expiresIn! * 1000) + new Date().getTime();
        this.accessToken = authResult.accessToken;
        this.idToken = authResult.idToken;
        this.expiresAt = expiresAt;
        this.grantedPermissionsString = authResult.scope;
        LocalStoragePersistence.persist(Auth.grantedPermissionsStringLocalStorageKey, authResult.scope);
        this.name = authResult.idTokenPayload.name;
        this.subject = authResult.idTokenPayload.sub;
    }

    renewSession() {
        this.auth0.checkSession({}, (err, authResult) => {
            if (authResult && authResult.accessToken && authResult.idToken) {
                this.setSession(authResult);
            } else if (err) {
                this.logout();
                console.log(err);
                alert(`Could not get a new token (${err.error}: ${err.error_description}).`);
            }
        });
    }

    logout() {
        this.accessToken = undefined;
        this.idToken = undefined;
        this.expiresAt = undefined;
        localStorage.removeItem(Auth.grantedPermissionsStringLocalStorageKey.localStorageKey);

        this.auth0.logout({
            returnTo: window.location.origin
        });

        // navigate to the home route
        history.replace('/');
    }

    isAuthenticated(): boolean {
        // Check whether the current time is past the
        // access token's expiry time
        const expiresAt = this.expiresAt;
        return expiresAt !== undefined && new Date().getTime() < expiresAt;
    }

    protected getGrantedPermissionsString(): String | undefined {
        if (this.grantedPermissionsString) {
            return this.grantedPermissionsString
        } else {
            return LocalStoragePersistence.read(Auth.grantedPermissionsStringLocalStorageKey, undefined)
        }
    }
}
