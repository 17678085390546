import * as React from 'react';
import {useState} from 'react';
import {Button} from 'reactstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopy} from '@fortawesome/free-solid-svg-icons';


interface Props {
    content: string,
    buttonValue: string
}


export function CopyToClipboardButton(props: Props) {
    const [copied, setCopied] = useState(false);

    return (
        <Button color={copied ? "success" : "primary"} onClick={() => {
            navigator.clipboard.writeText(props.content);
            setCopied(true);
            setTimeout(() => {
                setCopied(false);
            }, 3000);
        }}><FontAwesomeIcon icon={faCopy}/> {copied ? "In Zwischenablage kopiert" : props.buttonValue}</Button>
    );
}