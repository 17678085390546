import * as React from 'react';
import {useEffect} from 'react';
import _ from "lodash";
import {ListGroup, ListGroupItem} from "reactstrap";
import {BeamerBroadcastChannelSender} from "./BeamerBroadcastChannelSender";
import {CyCarousel} from "../carousel/CyCarousel";
import {LivePositionMarker} from "../live/LivePositionMarker";
import {PlaylistLivePosition} from "../live/playlist/PlaylistLivePosition";
import {useDeviceContext} from "../device/DeviceInfoContext";

interface Props {
    channel: BeamerBroadcastChannelSender
    setActiveLyricsPart: (activeLyricsPart: number | undefined) => void
    index: number
    text: string
    isActive: boolean
    playlistLivePositionArray: readonly PlaylistLivePosition[]
}

export function SongsheetBeamerText(props: Props) {
    const {text, isActive, channel} = props;

    const {
        deviceIdentifier
    } = useDeviceContext();

    useEffect(() => {
        isActive && channel.sendText(text);
    }, [text, isActive, channel]);

    function generateDeviceInfo(index: number): React.ReactNode {
        return _.filter(props.playlistLivePositionArray, (p: PlaylistLivePosition) => {
            return p.deviceIdentifier !== deviceIdentifier // exclude own device
                && p.currentLyricsPosition === index
        })
            .map((p: PlaylistLivePosition) => {
                return <LivePositionMarker color={p.deviceColor} key={p.deviceIdentifier}/>
            })
    }

    return (
        <ListGroup horizontal
                   style={{flex: "1 1 auto", userSelect: "none"}}
                   className="border-bottom"
        >
            <ListGroupItem
                active={props.isActive}
                action
                data-cy={CyCarousel.LyricsItem}
                className={"border-0 " + (props.isActive && "bg-primary text-dark bg-opacity-25")}
                onClick={() => props.setActiveLyricsPart(props.index)}
            >
                <div className="col">
                    <div className="row">
                        <div className="col-10 d-flex">
                            {props.text}
                        </div>
                        <div className="col-2 d-flex justify-content-end">
                            {generateDeviceInfo(props.index)}
                        </div>
                    </div>
                </div>
            </ListGroupItem>
        </ListGroup>
    );
}