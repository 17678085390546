import PlaylistItemData from "../../playlist/data/PlaylistItemData";

export class VirtualInvalidSlide {
    public readonly tag = "VirtualInvalidSlide"

    public readonly playlistItemData: PlaylistItemData;

    constructor(item: PlaylistItemData) {
        this.playlistItemData = item;
    }

    buildKey(): string {
        return `${this.playlistItemData.itemInMemoryId}-invalidSlide`
    }


}