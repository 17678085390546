import PlaylistData from "../../data/PlaylistData";
import {TocEntryDTO} from "../../../dto/v1/toc/TocEntryDTO";
import {TocIdDTO, TocIdDTOCompanion} from "../../../dto/v3/toc/TocIdDTO";
import PlaylistManipulationUtils from "../../PlaylistManipulationUtils";

export class PlaylistFromUrlImportParser {

    public static readonly PARAM_KEY_NAME = "songIds";
    public static IMPORTED_PLAYLIST_TITLE_PREFIX = "Importierte Playlist vom ";


    public createPlaylistFromUrl(importIdentifier: string, url: string, lookupSong: (tocId: TocIdDTO) => TocEntryDTO | undefined): PlaylistData | undefined {
        const params = this.getUrlParams(url);
        const songIds = params[PlaylistFromUrlImportParser.PARAM_KEY_NAME];

        if (songIds === undefined || songIds.length < 1) {
            console.debug("PlaylistFromUrlImportParser: No success while trying to import playlist from url. songIds param is missing.", {"url": url, "songIdsParam": songIds});
            return undefined;
        }

        const tocIdArray: ReadonlyArray<TocIdDTO> = songIds.split(",").filter(v => v !== "" && v !== "0").map(v => TocIdDTOCompanion.fromString(v)).filter(v => v !== undefined) as ReadonlyArray<TocIdDTO>;
        if (tocIdArray.length > 0) {
            const timestamp = new Date();
            return new PlaylistData(
                importIdentifier,
                PlaylistFromUrlImportParser.IMPORTED_PLAYLIST_TITLE_PREFIX + timestamp.toLocaleString(),
                undefined, //QR code imported playlists do not belong to a crew
                [],
                PlaylistManipulationUtils.buildItemsFromTocIdArray(lookupSong, tocIdArray),
                new Date(),
                new Date(),
                0,
                false,
                true,
                false
            );
        } else {
            console.debug("PlaylistFromUrlImportParser: No success while trying to import playlist from url", {"url": url, "songIdsParam": songIds, "tocIdArray": tocIdArray});
            return undefined
        }
    }

    private getUrlParams(url: string): { [p: string]: string } {
        let hashes = url.slice(url.indexOf('?') + 1).split('&');
        return hashes.reduce((params, hash) => {
            let [key, val] = hash.split('=');
            return Object.assign(params, {[key]: decodeURIComponent(val)})
        }, {})
    }
}
