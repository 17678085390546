import {SongsheetsConfig, TocConfig} from "../Config";
import _ from "lodash";

export class CacheManager {

    public deleteToc(): Promise<boolean> {
        return this.deleteResourceInOfflineAvailableContentCache(TocConfig.cacheIdentifier, TocConfig.url, true);
    }

    public deleteSongsheetFile(url: string): Promise<boolean> {
        return this.deleteResourceInOfflineAvailableContentCache(SongsheetsConfig.cacheIdentifier, url, false);
    }

    public deleteAllCaches(): Promise<boolean> {
        return window.caches.keys().then(function (cacheNames) {
            return Promise.all(
                cacheNames.map(function (cacheName) {
                    return caches.delete(cacheName);
                })
            ).then(results => _.every(results, Boolean));
        })
    }

    private deleteResourceInOfflineAvailableContentCache(cacheIdentifier: string, url: string, quietDelete: boolean = false): Promise<boolean> {
        console.info("going to delete: " + url);
        return window.caches.open(cacheIdentifier).then(function (cache) {
            return cache.delete(url).then(function (success) {
                if (!success) {
                    if (quietDelete) {
                        console.info("Deleting url did not work. But no error will be returned because quietDelete is set to true", url);
                    } else {
                        console.info("Deleting url did not work. Returning false", url);
                    }
                }
                return Promise.resolve(success || quietDelete);
            });
        });
    }

    public findAllSongsheetCacheKeys(): Promise<string[]> {
        return window.caches.open(SongsheetsConfig.cacheIdentifier).then(function (cache) {
            return cache.keys();
        }).then(function (requests) {
            return requests.map(function (request) {
                return request.url;
            });
        });
    }

    public addToCache(url: string, response: Response) {
        if (response.ok) {
            return caches.open(SongsheetsConfig.cacheIdentifier).then(function (cache) {
                cache.put(url, response)
            });
        } else {
            throw Error("Response status for " + url + " was " + response.status + ". Response will not be added to cache.");
        }

    }


}