import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import * as React from 'react';
import PlaylistItemData from "../data/PlaylistItemData";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import {CyPlaylistItem} from "./CyPlaylistItem";
import {InvalidItemMessage, InvalidItemMessageTitlePrefix} from "./PlaylistItemTexts";


interface Props {
    playlistEntry: PlaylistItemData
}

class PlaylistItemInvalid extends React.Component<Props, {}> {

    public render() {

        if (this.props.playlistEntry.toCEntry) {
            return ("Error: Component can only render not found items.");
        }
        return (
            <>
                <FontAwesomeIcon icon={faExclamationTriangle} className={"text-warning"}/> <span
                data-cy={CyPlaylistItem.EntryTitle}>{InvalidItemMessageTitlePrefix} {this.props.playlistEntry.tocId?.sourceSystem}_{this.props.playlistEntry.tocId?.songId}</span><br/><br/>
                {InvalidItemMessage}
            </>

        );
    }
}

export default PlaylistItemInvalid;
