import * as React from 'react';
import {Alert, Card, CardBody, CardHeader} from 'reactstrap';
import {faBell, faCogs, faCopy, faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {CrewJoinerTexts} from "./CrewJoinerTexts";
import {ContactFormLink} from "../helper/ContactFormLink";
import joinCrewHelp from "../crew/joinCrewHelp.png";
import Zoom from "react-medium-image-zoom";
import {CrewTexts} from "../Settings/CrewTexts";
import {MainMenuTexts} from "../mainmenu/MainMenuTexts";
import {CrewInformationUb} from "../gql/graphql";
import {useCrewQuery} from "./useCrewQuery";

export function NoCrewMembershipFoundAlert() {

    const crewInformationMaybe: ReadonlyArray<CrewInformationUb> | undefined = useCrewQuery()

    return (
        (crewInformationMaybe !== undefined && crewInformationMaybe.length === 0) ?
            <Card className="mt-3">
                <CardHeader className="pb-0" style={{backgroundColor: "#f8f9fa", borderBottom: "none"}}>
                    <h2 className={"light-header"}>
                        <FontAwesomeIcon icon={faBell}/> Keine Crew gefunden!
                    </h2>
                </CardHeader>
                <CardBody>
                    <Alert color="warning">
                        <p>Du bist erfolgreich bei Songship registriert. Allerdings siehst du im Moment keine Lieder, weil du nicht zu einer Crew (z. B. Adoray Schweiz) gehörst.</p>

                        <h5>Was muss ich machen?</h5>
                        <p>
                            Eine Crew ist ähnlich wie eine WhatsApp-Gruppe. Bitte eine
                            Person, welche bereits zur Crew gehört, dir einen Einladungslink zu senden.
                            <ol>
                                <li>Dazu geht die Person mit ihrem Songship-Account auf <FontAwesomeIcon icon={faCogs}/> <i>{MainMenuTexts.AdministrationAndOfflineUsage}</i>.</li>
                                <li>Anschliessend klickt sie bei der gewünschten Crew auf <FontAwesomeIcon icon={faCopy}/> <i>{CrewTexts.CopyInvitation}</i></li>
                                <li>Jetzt befindet sich der Einladungslink in der Zwischenablage und sie kann ihn dir via Mail, WhatsApp oder SMS senden.</li>
                                <li>Diesen Link musst du in deinem Browser öffnen und dich mit deinem Benutzer anmelden.</li>
                                <li>Jetzt wirst du zur Gruppe hinzugefügt und siehst die Meldung <FontAwesomeIcon icon={faInfoCircle}/> <i>{CrewJoinerTexts.JoiningSuccessful}</i>
                                </li>
                                <li>Fertig.</li>
                            </ol>
                            <Zoom> <img src={joinCrewHelp} className="img-thumbnail" width="300px"/></Zoom>
                        </p>

                        <h5>Hilfe</h5>
                        <p>
                            Benötigst du weitere Hilfe? <a href={ContactFormLink} target="_blank" rel="noreferrer">Mit diesem Formular</a> kannst du uns kontaktieren.
                        </p>

                    </Alert>
                </CardBody>
            </Card>
            : <></>);
}