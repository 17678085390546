import * as React from 'react';
import {Button, ButtonGroup, Card, CardBody, CardTitle} from "reactstrap";
import './SongsheetCarousel.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBookOpen, faCog, faFile, faFileAlt, faMinus, faPlus, faVideo} from "@fortawesome/free-solid-svg-icons";
import {BeamerBroadcastChannelSender} from "../beamer/BeamerBroadcastChannelSender";
import {faMusic} from "@fortawesome/free-solid-svg-icons/faMusic";
import {ToggleButton} from "../common/ToggleButton";
import Auth from "../auth/Auth";
import {Permission} from "../auth/Permission";
import {CyCarousel} from "./CyCarousel";
import {CardHeaderWithCloseButton} from "../common/CardHeaderWithCloseButton";

interface Props {
    auth: Auth;
    showBanner: boolean
    setShowBanner: (visible: boolean) => void
    beamerBroadcastChannelSender: BeamerBroadcastChannelSender
    isBeamerModeActive: boolean
    setIsBeamerModeActive: (active: boolean) => void
    openBeamerWindow: () => void
    amountOfPagesInSongsheetCarousel: number
    setAmountOfPagesInSongsheetCarousel: (amount: number) => void
}

export default function CarouselSettingsSidebar(props: Props) {

    const hasPermissionSongsheetsRead = props.auth.hasPermissions([Permission.songsheetsRead])

    return (
        <>
            <CardBody>
                <CardTitle><FontAwesomeIcon icon={faCog}/> Allgemein</CardTitle>
                <ToggleButton value="Playlist einblenden" setIsActive={props.setShowBanner} isActive={props.showBanner} color="primary" outline/>
            </CardBody>
            {hasPermissionSongsheetsRead && <CardBody>
                <CardTitle><FontAwesomeIcon icon={faMusic}/> / <FontAwesomeIcon icon={faFileAlt}/> Modus wählen</CardTitle>
                <ButtonGroup>
                    <Button color="primary" outline onClick={e => props.setIsBeamerModeActive(false)} active={!props.isBeamerModeActive}>
                        <FontAwesomeIcon icon={faMusic}/> Noten
                    </Button>
                    <Button color="primary" outline onClick={e => props.setIsBeamerModeActive(true)} active={props.isBeamerModeActive}>
                        <FontAwesomeIcon icon={faFileAlt}/> Liedtexte
                    </Button>
                </ButtonGroup>
            </CardBody>
            }
            {props.isBeamerModeActive ? <CardBody>
                <CardTitle>
                    <FontAwesomeIcon icon={faVideo}/> Liedtexte beamen</CardTitle>
                Öffne das Beamer-Fenster. Anschliessend kannst du hier die Schriftgrösse ändern.<br/><br/>
                <Button color="primary" outline onClick={() => props.openBeamerWindow()}>
                    <FontAwesomeIcon icon={faVideo}/> Beamer-Fenster öffnen
                </Button>
                <br/><br/>
                <ButtonGroup>
                    <Button color="primary" outline onClick={() => props.beamerBroadcastChannelSender.increaseFontSize()} data-cy={CyCarousel.IncreaseFontButton}>
                        <FontAwesomeIcon icon={faPlus}/> Grösser
                    </Button>
                    <Button color="primary" outline onClick={() => props.beamerBroadcastChannelSender.decreaseFontSize()} data-cy={CyCarousel.DecreaseFontButton}>
                        <FontAwesomeIcon icon={faMinus}/> Kleiner
                    </Button>
                </ButtonGroup>
            </CardBody> : <CardBody>
                <CardTitle>
                    <FontAwesomeIcon icon={faBookOpen}/> Noten anzeigen</CardTitle>
                <p>
                    Du kannst eine oder zwei Notenseiten parallel anzeigen.
                </p>
                <ButtonGroup>
                    <Button color="primary" outline onClick={e => props.setAmountOfPagesInSongsheetCarousel(1)} active={props.amountOfPagesInSongsheetCarousel === 1}>
                        <FontAwesomeIcon icon={faFile}/> Eine Seite
                    </Button>
                    <Button color="primary" outline onClick={e => props.setAmountOfPagesInSongsheetCarousel(2)} active={props.amountOfPagesInSongsheetCarousel === 2}>
                        <FontAwesomeIcon icon={faBookOpen}/> Doppelseite
                    </Button>
                </ButtonGroup>
            </CardBody>}
        </>
    );

}

