import PlaylistItemData from "../../playlist/data/PlaylistItemData";


export class VirtualTextOnlySlide {
    public readonly tag = "VirtualTextOnlySlide"

    public readonly playlistItemData: PlaylistItemData;

    constructor(item: PlaylistItemData) {
        this.playlistItemData = item;
    }

    buildKey(): string {
        return `${this.playlistItemData.itemInMemoryId}-textOnlySlide`
    }

}