import {useQuery} from "urql";
import {CrewInformationUb, CrewListQueryQuery, CrewListQueryQueryVariables} from "../gql/graphql";
import {TypedDocumentNode} from "@graphql-typed-document-node/core";
import {graphql} from "../gql";

export function useCrewQuery(): ReadonlyArray<CrewInformationUb> | undefined {

    const [result] = useQuery({
        query: crewListQuery,
        requestPolicy: "cache-and-network"
    });

    const {data} = result;

    return data?.listCrew
}

export const crewListQuery: TypedDocumentNode<CrewListQueryQuery, CrewListQueryQueryVariables> = graphql(`
    query crewListQuery{
        listCrew{
            identifier,
            joiningIdentifier,
            title
        }
    }
`);