import * as React from 'react';
import {QrCodeReader} from "../common/QrCodeReader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle, faShareAltSquare} from "@fortawesome/free-solid-svg-icons";
import {RoutePaths} from "../RoutePaths";
import history from '../history';
import {Alert, Card, CardBody, CardHeader, Col, Row} from "reactstrap";

class ScanQrCodeDialog extends React.Component<{}, {}> {

    constructor(props: Readonly<{}>) {
        super(props);

        this.handleScan = this.handleScan.bind(this);
    }

    public render() {
        return (
            <Row className="g-4 pt-4 pb-0">
                <Col md={6} lg={4}>
                    <Card>
                        <CardHeader>QR Code importieren</CardHeader>
                        <CardBody>
                            <QrCodeReader onScanHandler={this.handleScan}/>
                        </CardBody>
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <CardHeader>Anleitung</CardHeader>
                        <CardBody>
                            <Alert color="warning" >
                                <small>
                                    <FontAwesomeIcon icon={faExclamationTriangle}/> Damit Songship den QR Code scannen kann, musst du im Browser den Zugriff auf die
                                    Kamera erlauben.
                                </small>
                            </Alert>
                            <ul>
                                <li>Playlist auf anderem Gerät öffnen</li>
                                <li>Oben auf auf <FontAwesomeIcon icon={faShareAltSquare}/><i> Teilen</i> klicken</li>
                                <li>QR Code vor deine Kamera innerhalb der roten Markierung halten</li>
                                <li>Sobald der QR Code erkannt wird, öffnet sich die Playlist automatisch</li>
                            </ul>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        );
    }

    private handleScan(data?: string) {
        console.debug("ScanQrCodeDialog: Data " + data);
        if (data) {
            if (data.indexOf(RoutePaths.IMPORT_PLAYLIST) > -1) {
                history.push("/" + data.substr(data.indexOf(RoutePaths.IMPORT_PLAYLIST)));
            }
        }
    }
}

export default ScanQrCodeDialog;
