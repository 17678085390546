import * as React from 'react';
import _ from "lodash";
import {Card, CardBody, ListGroupItem} from "reactstrap";
import {BeamerBroadcastChannelSender} from "./BeamerBroadcastChannelSender";
import {TocEntryDTO} from "../dto/v1/toc/TocEntryDTO";
import {PlaylistLivePosition} from "../live/playlist/PlaylistLivePosition";
import {SongsheetBeamerText} from "./SongsheetBeamerText";

interface Props {
    tocEntry: TocEntryDTO
    channel: BeamerBroadcastChannelSender
    activeLyricsPart: number | undefined
    setActiveLyricsPart: (activeLyricsPart: number | undefined) => void
    playlistLivePositionArray: readonly PlaylistLivePosition[]
}

export function SongsheetBeamerControl(props: Props) {

    function generateItems(songsheet: string): React.ReactNode {
        return _.replace(songsheet, /\r\n/g, "\n").split("\n\n").map((verseOrRefrain: string, index: number) => {
            const isSelected = props.activeLyricsPart === index
            return (
                <SongsheetBeamerText
                    key={index}
                    channel={props.channel}
                    setActiveLyricsPart={props.setActiveLyricsPart}
                    index={index}
                    text={verseOrRefrain}
                    isActive={isSelected}
                    playlistLivePositionArray={props.playlistLivePositionArray}
                />
            )
        })
    }

    function generateNoLyricsInfo(): React.ReactNode {
        return <ListGroupItem>
            Zu diesem Lied sind keine Liedtexte vorhanden.
        </ListGroupItem>
    }

    return (
        <div className="lyrics">
            <Card style={{/*fontFamily: "'Courier New', monospace",*/ whiteSpace: "pre-line"}}>
                <CardBody>
                    {props.tocEntry.lyrics ? generateItems(props.tocEntry.lyrics) : generateNoLyricsInfo()}
                </CardBody>
            </Card>

        </div>
    );
}