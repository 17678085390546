import * as React from 'react';
import notFoundIcon from "./NotFoundIcon.png";
import './InvalidSlide.css';


export function InvalidSlide() {
    return (
        <div className="invalid-slide">
            <img src={notFoundIcon} alt="Notenblatt nicht gefunden"/>
        </div>
    );
}