


export const CySettings = {
    PermissionList: "PermissionList",
    PermissionGranted : "PermissionGranted",
    PermissionDeclined : "PermissionDeclined",
    LogoutButton : "LogoutButton",
    CrewList : "CrewList"
} as const;
