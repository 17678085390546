import * as React from 'react';
import {Card, CardBody, CardHeader, CloseButton, Col, Row} from 'reactstrap';
import {faUsers} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {renderLoadingUntilDefined} from "../common/LoadingUntilDefined";
import {CrewInformationUb} from "../gql/graphql";
import {useCrewQuery} from "./useCrewQuery";
import {CrewSelector} from "./CrewSelector";
import {useMediaQuery} from "../common/hooks/useMediaQuery";
import {bigScreenThreshold} from "../App";

interface Props {
    closeDialog: () => void
    currentCrewIdentifier: string | undefined
    setCurrentCrewIdentifier: (i: string | undefined) => void
}

export function CrewDialog(props: Props) {
    const crewInformationMaybe: ReadonlyArray<CrewInformationUb> | undefined = useCrewQuery()

    const isBigScreen = useMediaQuery(`(min-width: ${bigScreenThreshold})`);
    const crewSelectorSize = isBigScreen ? "large" : "small";

    return (
        <Card className="mt-3">
            <CardHeader className="pb-0" style={{backgroundColor: "#f8f9fa", borderBottom: "none"}}>
                <Row>
                    <Col>
                        <h2 className={"light-header"}>
                            <FontAwesomeIcon icon={faUsers}/> Crews
                        </h2>
                    </Col>
                    <Col xs={"auto"}>
                        <CloseButton onClick={() => props.closeDialog()}/>
                    </Col>
                </Row>

            </CardHeader>
            <CardBody>
                {renderLoadingUntilDefined(crewInformationMaybe, (crewInformation) => {
                    return <CrewSelector
                        crewInformation={crewInformation}
                        targetCrewIdentifier={props.currentCrewIdentifier}
                        setTargetCrewIdentifier={props.setCurrentCrewIdentifier}
                        size={crewSelectorSize}
                    />
                })}
            </CardBody>
        </Card>
    );
}

