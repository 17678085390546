import PlaylistItemData from "../../playlist/data/PlaylistItemData";


export class VirtualSongsheetSlide {
    public readonly tag = "VirtualSongsheetSlide"
    public readonly playlistItemData: PlaylistItemData;
    public readonly pictureReference: string;

    constructor(item: PlaylistItemData, pictureReference: string) {
        this.playlistItemData = item;
        this.pictureReference = pictureReference;
    }

    buildKey(): string {
        return `${this.playlistItemData.itemInMemoryId}-${this.pictureReference}`
    }

}