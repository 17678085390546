import * as React from 'react';
import {ReactNode} from 'react';
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from 'reactstrap';

interface Props {
    songbookMap: Map<number, string>
    currentSongbookId: number | undefined
    setCurrentSongbookId: (newValue: number) => void
}

class DefaultSongbookDropdown extends React.Component<Props, {}> {
    public render() {
        return (
            <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                    Bevorzugtes Liederbuch
                </DropdownToggle>
                <DropdownMenu right>
                    {this.generateSongbookItems()}
                </DropdownMenu>
            </UncontrolledDropdown>
        );
    }

    private generateSongbookItems(): ReactNode[] {
        const nodes: ReactNode[] = [];
        this.props.songbookMap.forEach((value, key) => {
            nodes.push((
                <DropdownItem key={key} onClick={() => this.props.setCurrentSongbookId(key)} active={key === this.props.currentSongbookId}>
                    {value}
                </DropdownItem>
            ))
        });
        return nodes;
    }
}

export default DefaultSongbookDropdown;
