import * as React from 'react';
import {RefObject, useCallback, useEffect, useState} from 'react';
import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardHeader,
    Col,
    Collapse,
    Form,
    Navbar,
    NavbarToggler,
    Row
} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowCircleLeft} from "@fortawesome/free-solid-svg-icons";
import PlaylistData from "../data/PlaylistData";
import {SearchEngine} from "../../search/SearchEngine";
import FilterButtonGroup from "./FilterButtonGroup";
import {TocEntryDTO} from "../../dto/v1/toc/TocEntryDTO";
import {TocIdDTO, TocIdDTOCompanion} from "../../dto/v3/toc/TocIdDTO";
import PlaylistItemData from "../data/PlaylistItemData";
import {Link, useHistory} from "react-router-dom";
import {ItemId} from "../data/PlaylistTypes";
import PlaylistBanner from "./PlaylistBanner";
import _ from "lodash";
import {useHotkeys} from "react-hotkeys-hook";
import {ResultList} from "./ResultList";
import {
    useStateWithLocalStoragePersistenceAndDefault
} from "../../common/hooks/useStateWithLocalStoragePersistenceAndDefault";
import {DelayedFilterInput} from "../../common/DelayedFilterInput";
import {LocalStorageKey} from "../../localStoragePersistence/common/LocalStorageKey";
import {CyTocIndex} from "./CyTocIndex";
import {RoutePaths} from "../../RoutePaths";

interface Props {
    playlistIdentifier: string
    playlistData: PlaylistData;
    insertAfter: (tocId: TocIdDTO, itemId?: ItemId) => PlaylistItemData
    searchEngine: SearchEngine;
    selectedItem: PlaylistItemData | undefined;
    setSelectedItem: (item: PlaylistItemData | undefined) => void;
}

function ToCIndex(props: Props) {

    const {playlistData, selectedItem, setSelectedItem, searchEngine} = props;

    useHotkeys('escape', () => {
        history.push(RoutePaths.toPlaylist(props.playlistIdentifier));
    }, {
        enableOnFormTags: ['input']
    })

    const searchfieldRef: RefObject<HTMLInputElement> = React.createRef();

    const [isNavbarOpen, setIsNavbarOpen] = useState(false);
    const [selectedTonalities, setSelectedTonalities] = useState<string[]>([]);
    const [selectedTags, setSelectedTags] = useState<string[]>([]);
    const [resultList, setResultList] = useState<TocEntryDTO[]>([]);
    const [amountOfEntriesInSearchResult, setAmountOfEntriesInSearchResult] = useStateWithLocalStoragePersistenceAndDefault<number>(new LocalStorageKey("ToCIndex", "amountOfEntriesInSearchResult"), 50);

    const history = useHistory();

    useEffect(() => {
        //if no item is selected, try to select the last one
        if (!selectedItem) {
            setSelectedItem(_.last(playlistData.getItems()));
        }
    }, [playlistData, selectedItem, setSelectedItem]);

    //https://react.dev/reference/react/useCallback#preventing-an-effect-from-firing-too-often
    const performSearch = useCallback((searchTerm: string) => {
        const results = searchEngine.search(searchTerm, selectedTonalities, selectedTags);
        setResultList(results);
        window.scrollTo(0, 0);
    }, [searchEngine, selectedTags, selectedTonalities]);

    return (
        <>
            <Navbar color="light" light sticky="top">
                <PlaylistBanner {...props} />
                <Form className="my-2">
                    <Row className="row-cols-auto g-4">
                        <Col>
                            <Link
                                to={RoutePaths.toPlaylist(props.playlistIdentifier)}
                                color="primary"
                            >
                                <Button color="primary" data-cy={CyTocIndex.ButtonBack}>
                                    <FontAwesomeIcon icon={faArrowCircleLeft}/>
                                </Button>
                            </Link>
                        </Col>
                        <Col>
                            <DelayedFilterInput
                                inputRef={searchfieldRef}
                                onSearchTermChanged={performSearch}
                                autoFocus={true}
                                placeholder="Lied suchen"
                            />
                        </Col>
                    </Row>
                </Form>

                <NavbarToggler onClick={toggleNavbar} className="me-2"/>
            </Navbar>

            <Collapse isOpen={isNavbarOpen} style={{backgroundColor: "#f8f9fa"}}>
                <Row className="px-3">
                    <Col md={4}>
                        <Card className="my-3">
                            <CardHeader>Tonarten</CardHeader>
                            <CardBody>
                                <FilterButtonGroup values={props.searchEngine.getTonalities()}
                                                   selectedValues={selectedTonalities}
                                                   selectedValuesSetter={setSelectedTonalities}/>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card className="my-3">
                            <CardHeader>
                                Tags
                            </CardHeader>
                            <CardBody>
                                <FilterButtonGroup values={props.searchEngine.getTags()} selectedValues={selectedTags}
                                                   selectedValuesSetter={setSelectedTags}/>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card className="my-3">
                            <CardHeader>
                                Anzahl Resultate in der Suche
                            </CardHeader>
                            <CardBody>
                                <ButtonGroup>
                                    <Button color="primary" outline onClick={e => setAmountOfEntriesInSearchResult(50)}
                                            active={amountOfEntriesInSearchResult === 50}>
                                        50
                                    </Button>
                                    <Button color="primary" outline onClick={e => setAmountOfEntriesInSearchResult(100)}
                                            active={amountOfEntriesInSearchResult === 100}>
                                        100
                                    </Button>
                                    <Button color="primary" outline
                                            onClick={e => setAmountOfEntriesInSearchResult(1000)}
                                            active={amountOfEntriesInSearchResult === 1000}>
                                        1000
                                    </Button>
                                </ButtonGroup>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Collapse>

            <ResultList tocEntrySelected={insertTocEntry}
                        searchResults={resultList.slice(0, amountOfEntriesInSearchResult)}/>
        </>
    );

    function toggleNavbar() {
        window.scrollTo(0, 0);
        setIsNavbarOpen(prev => !prev);
        console.warn("scrolled to top?");
    }

    function insertTocEntry(tocEntry: TocEntryDTO) {
        const tocId = TocIdDTOCompanion.create(tocEntry.sourceSystem, tocEntry.songId);
        const newItem = props.insertAfter(tocId, props.selectedItem?.itemInMemoryId)
        props.setSelectedItem(newItem)
        setFocusToSearchfieldAndSelectText();
    }

    function setFocusToSearchfieldAndSelectText() {
        const element = searchfieldRef.current;
        if (element) {
            element.setSelectionRange(0, element.value.length);
            element.focus();
        }
    }
}

export default ToCIndex;
