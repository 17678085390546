import * as React from 'react';
import {Alert, Button, Card, CardBody, CardHeader} from 'reactstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBell} from '@fortawesome/free-solid-svg-icons';

export function DownloadNecessaryButNoBackendAvailabilityNotification() {
    return (
        <Card className="mt-3">
            <CardHeader>
                <h2 className={"light-header"}><FontAwesomeIcon icon={faBell}/> Daten fehlen!</h2>
            </CardHeader>
            <CardBody>
                <Alert color="warning">
                    Nicht alle Noten sind auf deinem Gerät offline gespeichert und du hast keine Verbindung zum Server. Es kann also sein, dass einige Noten nicht angezeigt werden
                    können.<br/><br/>
                    Prüfe bitte deine Internet-Verbindung und versuche es erneut!<br/><br/>
                    <Button color="primary" onClick={() => window.location.reload()}>Erneut versuchen</Button><br/><br/>
                </Alert>
            </CardBody>
        </Card>
    );
}
