import * as React from 'react';
import {Button, ButtonGroup, Card, CardBody, CardHeader, CardTitle, Col, Row} from 'reactstrap';
import './PlaylistIndex.css';
import {faFileLines, faSliders, faSortAlphaDown} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import PlaylistData from "../playlist/data/PlaylistData";
import {Switcher} from "../common/Switcher";
import {OrderByButton} from "./OrderByButton";

interface Props {
    changeOrderBy: (newField: keyof PlaylistData) => void
    currentOrder: "asc" | "desc"
    currentField: keyof PlaylistData
    showDetailsInPlaylistSummary: boolean
    setShowDetailsInPlaylistSummary: ((value: (((prevState: boolean) => boolean) | boolean)) => void)
    amountOfEntriesInPlaylistIndex: number
    setAmountOfEntriesInPlaylistIndex: ((value: (((prevState: number) => number) | number)) => void)
}

export function PlaylistIndexAdvancedMenu(props: Props) {
    function OrderBy(orderByProps: { title: string, field: keyof PlaylistData, }) {
        return <OrderByButton
            title={orderByProps.title}
            field={orderByProps.field}
            changeOrderBy={props.changeOrderBy}
            currentField={props.currentField}
            currentOrder={props.currentOrder}
            renderBullet={true}/>
    }

    return (
        <Row className="g-4 pt-4 pb-0">
            <Col md={6} lg={4} xl={3}>
                <Card>
                    <CardHeader><FontAwesomeIcon icon={faSortAlphaDown}/> Sortierung</CardHeader>
                    <CardBody>
                        <CardTitle>Playlisten sortieren nach</CardTitle>
                        <OrderBy title="Playlist" field="titleLowercase"/><br/>
                        <OrderBy title="Erstellt" field="createdAt"/><br/>
                        <OrderBy title="Aktualisiert" field="updatedAt"/><br/>
                    </CardBody>
                </Card>
            </Col>
            <Col md={6} lg={4} xl={3}>
                <Card>
                    <CardHeader><FontAwesomeIcon icon={faSliders}/> Vorschau</CardHeader>
                    <CardBody>
                        <CardTitle>Elemente einer Playlist in der Übersicht</CardTitle>
                        <Switcher
                            value="Details anzeigen"
                            setIsActive={props.setShowDetailsInPlaylistSummary}
                            isActive={props.showDetailsInPlaylistSummary}/>
                    </CardBody>
                </Card>
            </Col>
            <Col md={6} lg={4} xl={3}>
                <Card>
                    <CardHeader>
                        <FontAwesomeIcon icon={faFileLines}/> Anzahl anzuzeigende Playlisten
                    </CardHeader>
                    <CardBody>
                        <ButtonGroup>
                            <Button color="primary" outline onClick={e => props.setAmountOfEntriesInPlaylistIndex(20)} active={props.amountOfEntriesInPlaylistIndex === 20}>
                                20
                            </Button>
                            <Button color="primary" outline onClick={e => props.setAmountOfEntriesInPlaylistIndex(50)} active={props.amountOfEntriesInPlaylistIndex === 50}>
                                50
                            </Button>
                            <Button color="primary" outline onClick={e => props.setAmountOfEntriesInPlaylistIndex(100)} active={props.amountOfEntriesInPlaylistIndex === 100}>
                                100
                            </Button>
                            <Button color="primary" outline onClick={e => props.setAmountOfEntriesInPlaylistIndex(1000)} active={props.amountOfEntriesInPlaylistIndex === 1000}>
                                1000
                            </Button>
                        </ButtonGroup>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
}