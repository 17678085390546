import * as React from 'react';
import {Alert, Button, ListGroupItem} from 'reactstrap';
import {CyPushNeededInfo} from "./CyPushNeededInfo";

export function PushNeededInfo() {
    return (
        <ListGroupItem key="pushNeededInfo">
            <Alert color="warning" >
                    Playlist hat lokal Änderungen. <Button color="link" data-cy={CyPushNeededInfo.ButtonSyncNow} style={{borderStyle: "none", padding: "0", verticalAlign: "baseline"}} onClick={() => window.location.reload()}>Jetzt synchronisieren</Button>

            </Alert>
        </ListGroupItem>
    );
}

