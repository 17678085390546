import {UpdateDeltaCalculator} from "./UpdateDeltaCalculator";
import {CacheManager} from "./CacheManager";
import {TocTransformer} from "./TocTransformer";
import {TableOfContentsDTO} from "../dto/v1/toc/TableOfContentsDTO";


export class DownloadNecessityChecker {
    private readonly cacheManager = new CacheManager();

    public isFileDownloadNecessary(toc: TableOfContentsDTO): Promise<boolean> {
        return this.cacheManager.findAllSongsheetCacheKeys()
            .then((cacheUrls: string[]) => {
                const urlsToBeDownloaded = new UpdateDeltaCalculator().calculateToBeDownloaded(new TocTransformer().buildUrlListForAllPagesOfAllSongs(toc), new Set(cacheUrls));
                console.log("update necessity check: " + urlsToBeDownloaded.length + " urls need to be downloaded.");
                return Promise.resolve(urlsToBeDownloaded.length > 0);
            })
            .catch(e => {
                console.error(e.message);
                return Promise.resolve(false);
            });
    }
}