import * as React from 'react';
import {useEffect, useState} from 'react';
import {Alert, Col, Row} from 'reactstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSync} from '@fortawesome/free-solid-svg-icons';
import {SpinnerCircular} from "spinners-react";
import {CyLoadingMessage} from "./CyLoadingMessage";
import {UiConfig} from "./Config";


interface Props {
    message: string,
    showGoToRootPageAfterMs?: number,
}

export function LoadingMessage(props: Props) {
    const [showGoToRootPage, setShowGoToRootPage] = useState(false);

    useEffect(() => {
        if (props.showGoToRootPageAfterMs !== undefined) {
            const timeoutId = setTimeout(() => {
                setShowGoToRootPage(true);
            }, props.showGoToRootPageAfterMs);

            return () => clearTimeout(timeoutId);
        }
        return undefined
    }, [props.showGoToRootPageAfterMs]);

    return (
        <Alert color="primary">

            <Row>
                <Col className="col-auto">
                    <SpinnerCircular color="#004085" style={{verticalAlign: "bottom"}}/>
                </Col>
                <Col className="d-flex align-items-center">
                    <h2 className={"light-header m-0"} data-cy={CyLoadingMessage.LoadingText} >Laden</h2>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p className="mt-3">
                        {props.message}
                    </p>
                </Col>
            </Row>
            <Row>
                {props.showGoToRootPageAfterMs !== undefined &&
                    <p className="mt-3" style={{transition: "opacity 0.5s linear", opacity: showGoToRootPage ? 1 : 0}}>
                        Falls die Seite nicht geladen wird, versuche es manuell:
                        <br/>
                        {showGoToRootPage ?
                            <a href={UiConfig.baseUrl}><FontAwesomeIcon icon={faSync}/> Manuell neu laden</a>
                            : <><FontAwesomeIcon icon={faSync}/> Manuell neu laden</>
                        }
                    </p>
                }
            </Row>
        </Alert>
    );
}