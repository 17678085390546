import {DtoIdentifier} from "../EntityDTO";
import {PlaylistDTO as PlaylistDTOV2} from "../v2/playlist/PlaylistDTO";
import {PlaylistLocalStoragePersistenceV2} from "../../localStoragePersistence/v2/playlist/PlaylistLocalStoragePersistence";
import {PlaylistDTO, PlaylistDTOCompanion} from "../v3/playlist/PlaylistDTO";
import {PlaylistItemDTOCompanion} from "../v3/playlist/PlaylistItemDTO";
import {TocIdDTOCompanion} from "../v3/toc/TocIdDTO";
import {PlaylistLocalStoragePersistence} from "../../localStoragePersistence/v3/playlist/PlaylistLocalStoragePersistence";
import {PlaylistFromUrlImportParser} from "../../playlist/import/url/PlaylistFromUrlImportParser";

export class PlaylistV2ToV3 {

    public static migrate() {
        console.info("Performing migration: PlaylistV2ToV3")
        const playlistLocalStoragePersistence = new PlaylistLocalStoragePersistenceV2();
        playlistLocalStoragePersistence.loadAllIdentifiersWithSchemaV2().map(i => this.migratePlaylist(i));
    }

    private static migratePlaylist(identifier: DtoIdentifier) {
        const convertedPlaylist = this.readV2PlaylistAndConvert(identifier)
        console.info("Migrating PlaylistV2ToV3 identifier=" + identifier, convertedPlaylist);
        convertedPlaylist && new PlaylistLocalStoragePersistence().persist(convertedPlaylist);
    }

    private static readV2PlaylistAndConvert(identifier: DtoIdentifier) {
        const playlistLocalStoragePersistence = new PlaylistLocalStoragePersistenceV2();
        const v2 = playlistLocalStoragePersistence.load(identifier);
        return (v2 !== undefined) ? this.v2ToV3(v2) : undefined;
    }

    private static v2ToV3(v2Playlist: PlaylistDTOV2): PlaylistDTO {
        const itemsv3 = v2Playlist.items.map(v2 => {
            const tocId = (v2.sourceSystem !== undefined && v2.songId !== undefined) ? TocIdDTOCompanion.create(v2.sourceSystem, v2.songId) : undefined;
            return PlaylistItemDTOCompanion.create(tocId, v2.title, v2.note)
        });
        const isQrCodeScannedPlaylist = v2Playlist.title.includes(PlaylistFromUrlImportParser.IMPORTED_PLAYLIST_TITLE_PREFIX)
        const needsPush = !isQrCodeScannedPlaylist; //We push all playlists (except qr code scanned ones) again with the new schema version so we don't have to migrate them on the server
        return PlaylistDTOCompanion.create(
            v2Playlist.identifier,
            v2Playlist.title,
            v2Playlist.tags,
            undefined, //v2 playlists did not belong to a crew (--> all private)
            itemsv3,
            v2Playlist.createdAt,
            v2Playlist.updatedAt,
            v2Playlist.lastServerVersion,
            needsPush,
            isQrCodeScannedPlaylist,
            false
        )
    }
}