import {DeviceInfo} from "../../device/DeviceInfoContext";

export const PlaylistLivePositionEventName = "playlistLivePosition";

export interface PlaylistLivePosition extends DeviceInfo{
    playlistIdentifier: string
    timestamp: number,
    selectedPlaylistItemInMemoryId: number | undefined
    viewMode: "lyrics" | "songsheet"
    currentLyricsPosition: number | undefined
    activePageInCarousel: number | undefined
}