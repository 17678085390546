import * as React from 'react';
import {Button} from 'reactstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faToggleOff, faToggleOn} from '@fortawesome/free-solid-svg-icons';

interface Props {
    value: string
    isActive: boolean
    setIsActive: (isActive: boolean) => void
    outline? : boolean
    color? : string
    className?: string | undefined;
}

export function ToggleButton(props: Props) {
    function toggle() {
        props.setIsActive(!props.isActive);
    }

    return (
        <Button className={props.className} onClick={() => toggle()} color={props.color} outline={props.outline}>
            <FontAwesomeIcon icon={props.isActive ? faToggleOn : faToggleOff}/> {props.value}
        </Button>
    );
}