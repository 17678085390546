import * as React from 'react';
import {Card, CardBody, CardHeader, ListGroup, ListGroupItem} from 'reactstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload} from '@fortawesome/free-solid-svg-icons';

export function OfflineSettings() {
    return (
        <Card className="mt-3">
            <CardHeader><h2 className={"light-header"}>
                <FontAwesomeIcon icon={faDownload}/> Offline-Nutzung</h2>
            </CardHeader>
            <CardBody>
                <ListGroup flush>
                    <ListGroupItem>
                        <p>
                            Wenn du den Chrome-Browser verwendest, kannst du auf Android, Windows und Mac und iOS die Lieder
                            herunterladen und anschliessend ohne Internet-Verbindung verwenden.
                        </p>
                        <p>
                            Wenn du Songship öffnest, prüft die App bei bestehender Internet-Verbindung automatisch, ob neue Noten vorhanden sind und informiert dich.
                        </p>
                    </ListGroupItem>
                </ListGroup>
            </CardBody>
        </Card>
    );
}
