import * as React from 'react';
import {useEffect, useState} from 'react';
import {PlaylistIndexManipulator} from "../../../playlists/PlaylistIndexManipulator";
import {TocEntryDTO} from "../../../dto/v1/toc/TocEntryDTO";
import {ImportState} from "./ImportState";
import {Alert} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle, faHome, faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {PlaylistFromUrlImportParser} from "./PlaylistFromUrlImportParser";
import {TocIdDTO} from "../../../dto/v3/toc/TocIdDTO";
import Header from "../../../Header";
import {PlaylistLocalStoragePersistence} from "../../../localStoragePersistence/v3/playlist/PlaylistLocalStoragePersistence";
import {LoadingMessage} from "../../../LoadingMessage";
import {RoutePaths} from "../../../RoutePaths";

interface Props {
    importIdentifier: string
    lookupSong: (id: TocIdDTO) => TocEntryDTO | undefined
}

const playlistLocalStoragePersistence = new PlaylistLocalStoragePersistence();

function PlaylistUrlImportDialog(props: Props) {
    const [didPlaylistAlreadyExist, setDidPlaylistAlreadyExist] = useState<boolean>(false);

    const [importState, setImportState] = useState<ImportState>(ImportState.Working);

    useEffect(() => {
        if (playlistLocalStoragePersistence.doesDtoExist(props.importIdentifier)) {
            setDidPlaylistAlreadyExist(true);
        }

        const importedPlaylist = new PlaylistFromUrlImportParser().createPlaylistFromUrl(props.importIdentifier, window.location.href, props.lookupSong);
        if (importedPlaylist) {
            //TODO: This should not change the already imported / existing title and tags if the playlist already exists!...
            PlaylistIndexManipulator.importPlaylist(importedPlaylist);
            setImportState(ImportState.ImportSuccessful);
        } else {
            setImportState(ImportState.FailureImportError);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <>
            <Header/>
            <br/>
            {renderState()}
        </>
    );

    function renderState() {
        switch (importState) {
            case ImportState.Working:
                return renderWorking();
            case ImportState.ImportSuccessful:
                return renderImportSuccessful();
            case ImportState.FailureImportError:
                return renderFailureImportError();
        }
    }

    function renderWorking() {
        return (
            <LoadingMessage message={"Playlist wird importiert. Bitte warten..."}/>
        );
    }

    function renderImportSuccessful() {
        return (
            <Alert color="success">
                <h2 className={"light-header"}><FontAwesomeIcon icon={faInfoCircle}/> Playlist erfolgreich importiert</h2>
                <span>
                    {didPlaylistAlreadyExist ? (<>
                        <FontAwesomeIcon icon={faExclamationTriangle}/>&nbsp;
                        Diese Playlist wurde bereits einmal importiert. Sie wurde deswegen nicht erneut importiert, sondern auf deinem Gerät aktualisiert.
                    </>) : (
                        <>Die Playlist wurde erfolgreich importiert.</>
                    )}
                </span>
                <br/><br/>
                <Link to={`/${RoutePaths.PLAYLIST}/${props.importIdentifier}`}>Playlist öffnen</Link>
            </Alert>
        );
    }

    function renderFailureImportError() {
        return (
            <Alert color="danger">
                <h2 className={"light-header"}><FontAwesomeIcon icon={faExclamationTriangle}/> Fehler beim Import</h2>
                <span>
                    Die Playlist konnte nicht korrekt importiert werden. Melde dich bitte bei uns, um Unterstützung zu erhalten.
                </span>
                <br/><br/>
                <Link to="/"><FontAwesomeIcon icon={faHome}/> Übersicht</Link>
            </Alert>
        );
    }
}

export default PlaylistUrlImportDialog;
