import {TocEntryDTO} from "../dto/v1/toc/TocEntryDTO";
import PlaylistData from "../playlist/data/PlaylistData";
import {PlaylistLocalStoragePersistence} from "../localStoragePersistence/v3/playlist/PlaylistLocalStoragePersistence";
import PlaylistMapper from "../playlist/mapper/PlaylistMapper";
import {TocIdDTO} from "../dto/v3/toc/TocIdDTO";
import _ from "lodash";

const playlistMapper = new PlaylistMapper();

export class PlaylistIndexLoader {

    public loadAll(lookupSong: (tocId: TocIdDTO) => TocEntryDTO | undefined, currentCrewIdentifier : string | undefined): ReadonlyArray<PlaylistData> {
        const p = new PlaylistLocalStoragePersistence()
        return p
            .loadAll()
            .map(dto => playlistMapper.persistedToDataMapper(lookupSong)(dto))
            .filter(playlist => playlist.belongsToCrew === currentCrewIdentifier);
    }

    public orderBy(playlistIndex : ReadonlyArray<PlaylistData>, field : keyof PlaylistData, ascOrDesc : "asc" | "desc"): ReadonlyArray<PlaylistData> {
        console.debug("Order playlist index by newField=" + field + " newAscOrDesc=" + ascOrDesc);
        return _.orderBy(playlistIndex, [field], [ascOrDesc]);
    }

}