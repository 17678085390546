export class BeamerBroadcastChannel {
    public static readonly ChannelName = "beamer";

    public static readonly IncreaseFontSize = "+";
    public static readonly DecreaseFontSize = "-";

    public static readonly BeamerViewDefaultContent = `Songship
    1. Fenster auf zweiten Monitor ziehen
    2. Vollbildmodus mit F11-Taste`;
}
