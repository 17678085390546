import * as React from 'react';
import {FormGroup, Input, Label} from 'reactstrap';

interface Props {
    value: string
    isActive: boolean
    setIsActive: (isActive: boolean) => void
}

export function Switcher(props: Props) {
    function toggle() {
        props.setIsActive(!props.isActive);
    }

    return (
        <FormGroup switch>
            <Label><Input type="switch" role="switch" checked={props.isActive} onChange={() => toggle()}/> {props.value}</Label>
        </FormGroup>
    );
}