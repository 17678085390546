import {CrewLiveEventPusher} from "../CrewLiveEventPusher";
import {v4 as uuidv4} from "uuid";
import {Client} from "urql";
import {PlaylistLivePosition, PlaylistLivePositionEventName} from "./PlaylistLivePosition";


export function pushPlaylistLivePosition(
    graphQlClient: Client,
    currentCrewIdentifier: string,
    payload: PlaylistLivePosition
) {
    CrewLiveEventPusher.offerCrewLiveEvent({
        eventName: PlaylistLivePositionEventName,
        eventID: uuidv4(),
        crewId: currentCrewIdentifier,
        deviceIdentifier: payload.deviceIdentifier,
        deviceReadableName: payload.deviceReadableName,
        payload: JSON.stringify(payload),
        timestamp: new Date()
    }, graphQlClient)
        .then(r => console.debug(r))
}