import PlaylistData from "../data/PlaylistData";
import PlaylistItemData from "../data/PlaylistItemData";
import {TocEntryDTO} from "../../dto/v1/toc/TocEntryDTO";
import {TocIdDTO} from "../../dto/v3/toc/TocIdDTO";
import _ from "lodash";
import {PlaylistDTO, PlaylistDTOCompanion} from "../../dto/v3/playlist/PlaylistDTO";
import {PlaylistItemDTO, PlaylistItemDTOCompanion} from "../../dto/v3/playlist/PlaylistItemDTO";

class PlaylistMapper {

    public dataToPersistedMapper(): (d: PlaylistData) => PlaylistDTO {
        return this.mapDataToPersisted;
    }

    public persistedToDataMapper(lookupSong: (tocId: TocIdDTO) => TocEntryDTO | undefined): (p: PlaylistDTO) => PlaylistData {
        return (p: PlaylistDTO) => {
            const items : PlaylistItemData[] = _.flatMap(p.items, (i: PlaylistItemDTO, index: number) => {
                if (i.tocId !== undefined) {
                    return new PlaylistItemData(index, i.tocId, lookupSong(i.tocId));
                } else {
                    return [];
                }
            });
            return new PlaylistData(
                p.identifier,
                p.title,
                p.belongsToCrew,
                p.tags,
                items,
                p.createdAt,
                p.updatedAt,
                p.lastServerVersion,
                p.needsPush,
                p.isQrScannedPlaylist,
                p.isRemoved
            );
        }
    }

    public mapDataToPersisted(d: PlaylistData): PlaylistDTO {
        return PlaylistDTOCompanion.create(
            d.getIdentifier(),
            d.title,
            d.getTags(),
            d.belongsToCrew,
            d.getItems().map(i => PlaylistItemDTOCompanion.create(i.tocId)),
            d.createdAt,
            d.updatedAt,
            d.lastServerVersion,
            d.needsPush,
            d.isQrCodeScannedPlaylist,
            d.isRemoved
        );
    }

    public mapPersistedToData(p: PlaylistDTO, lookupSong: (tocId: TocIdDTO) => TocEntryDTO | undefined): PlaylistData {
        return this.persistedToDataMapper(lookupSong)(p);
    }
}

export default PlaylistMapper;