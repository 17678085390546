import {TocEntryDTO} from "../../dto/v1/toc/TocEntryDTO";

export class SongbookMapExtractor {
    public extractSongbooks(playlistEntries: ReadonlyArray<TocEntryDTO>): Map<number, string> {
        const map = new Map<number, string>();
        playlistEntries.forEach(v => v.songnumbers.forEach(sn => {
            map.set(sn.songbookId, sn.songbookTitle);
        }));
        return map
    }
}