import {SynchronizableEntityDTO} from "../../SynchronizableEntityDTO";
import {PlaylistItemDTO, PlaylistItemDTOCompanion} from "./PlaylistItemDTO";

export interface PlaylistDTO extends SynchronizableEntityDTO<PlaylistDTO> {
    readonly schemaVersion: number;
    readonly identifier: string;
    readonly title: string;
    readonly belongsToCrew?: string;
    readonly tags: ReadonlyArray<string>;
    readonly items: ReadonlyArray<PlaylistItemDTO>;
    readonly createdAt: Date;
    readonly updatedAt: Date;
    readonly lastServerVersion: number;
    readonly needsPush: boolean;
    readonly isQrScannedPlaylist: boolean;
    readonly isRemoved: boolean;
}

export class PlaylistDTOCompanion {
    public static readonly SCHEMA_VERISON = 3;

    public static create(
        identifier: string,
        title: string,
        tags: ReadonlyArray<string>,
        belongsToCrew: string | undefined,
        items: ReadonlyArray<PlaylistItemDTO> = [],
        createdAt: Date,
        updatedAt: Date,
        lastServerVersion: number,
        needsPush: boolean,
        isQrCodeScannedPlaylist: boolean,
        isRemoved: boolean
    ): PlaylistDTO {
        return {
            schemaVersion: this.SCHEMA_VERISON,
            identifier,
            title,
            belongsToCrew,
            tags,
            items,
            createdAt,
            updatedAt,
            lastServerVersion,
            needsPush,
            isQrScannedPlaylist: isQrCodeScannedPlaylist,
            isRemoved
        }
    }

    //Workaround, until we have a proper parsing...
    public static fromParsedJson(i: PlaylistDTO): PlaylistDTO {
        const items = PlaylistItemDTOCompanion.fromParsedJson(i.items);
        return {
          	...i,
            schemaVersion: this.SCHEMA_VERISON,
            items: items,
            createdAt: new Date(i.createdAt),
            updatedAt: new Date(i.updatedAt),
            
        }
    }
}