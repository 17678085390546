import {TocEntryDTO} from "../../dto/v1/toc/TocEntryDTO";
import PlaylistItemData from "./PlaylistItemData";
import _ from "lodash";
import {v4 as uuidv4} from 'uuid';

class PlaylistData {

    public constructor(
        private readonly identifier: string,
        public readonly title: string,
        public readonly belongsToCrew: string | undefined,
        private readonly tags: ReadonlyArray<string> = [],
        private readonly items: ReadonlyArray<PlaylistItemData> = [],
        public readonly createdAt: Date,
        public readonly updatedAt: Date,
        public readonly lastServerVersion: number,
        public readonly needsPush: boolean,
        public readonly isQrCodeScannedPlaylist: boolean,
        public readonly isRemoved: boolean
    ) {}

    public static createNew(
        identifier: string = uuidv4(),
        belongsToCrew: string | undefined,
        tags: ReadonlyArray<string> = [],
        items: ReadonlyArray<PlaylistItemData> = [],
    ) {
        const timestamp = new Date();
        return new PlaylistData(
            identifier,
            "Playlist vom " + timestamp.toLocaleString(),
            belongsToCrew,
            _.concat(tags, PlaylistData.createTimestampTag(timestamp)),
            items,
            new Date(),
            new Date(),
            0,
            true,
            false,
            false
        );
    }

    private static createTimestampTag(timestamp: Date): string {
        return timestamp.getFullYear() + "/" + (timestamp.getMonth() + 1) + "/" + timestamp.getDate();
    }

    public getIdentifier() {
        return this.identifier
    }

    get titleLowercase(): string {
        return this.title.toLowerCase();
    }

    public getTags() {
        return _.clone(this.tags);
    }

    public copyWithTitle(title: string) {
        return new PlaylistData(
            this.identifier,
            title,
            this.belongsToCrew,
            _.cloneDeep(this.tags),
            _.cloneDeep(this.items),
            this.createdAt,
            new Date(),
            this.lastServerVersion,
            true,
            this.isQrCodeScannedPlaylist,
            this.isRemoved
        );
    }

    public copyWithIsRemoved(isRemoved: boolean): PlaylistData {
        return new PlaylistData(
            this.identifier,
            this.title,
            this.belongsToCrew,
            _.cloneDeep(this.tags),
            _.cloneDeep(this.items),
            this.createdAt,
            new Date(),
            this.lastServerVersion,
            true,
            this.isQrCodeScannedPlaylist,
            isRemoved
        );
    }

    public copyWithItems(items: ReadonlyArray<PlaylistItemData>) {
        return new PlaylistData(
            this.identifier,
            this.title,
            this.belongsToCrew,
            _.cloneDeep(this.tags),
            items,
            this.createdAt,
            new Date(),
            this.lastServerVersion,
            true,
            this.isQrCodeScannedPlaylist,
            this.isRemoved
        );
    }

    public getItems(): ReadonlyArray<PlaylistItemData> {
        return _.cloneDeep(this.items);
    }

    public getAllValidTocEntries(): ReadonlyArray<TocEntryDTO> {
        const validEntries: TocEntryDTO[] = [];
        this.items.forEach(v => {
            if (v.toCEntry) validEntries.push(v.toCEntry);
        });
        return validEntries;
    }
}

export default PlaylistData;
