import React, {createContext, useContext} from 'react';
import {
    useStateWithLocalStoragePersistenceAndDefault
} from "../common/hooks/useStateWithLocalStoragePersistenceAndDefault";
import {DeviceLocalStorageKeys} from "./DeviceLocalStorageKeys";
import {v4 as uuidv4} from "uuid";

export interface DeviceInfo {
    deviceIdentifier: string,
    deviceReadableName: string,
    deviceColor: string,
}

export interface DeviceContextType extends DeviceInfo {
    setDeviceIdentifier: (value: (((prevState: string) => string) | string)) => void,
    setDeviceReadableName: (value: (((prevState: string) => string) | string)) => void,
    setDeviceColor: (value: (((prevState: string) => string) | string)) => void,
}

export const DeviceInfoContext: React.Context<DeviceContextType | undefined> = createContext<DeviceContextType | undefined>(undefined);

export function useDeviceContextStateProvider(): DeviceContextType {
    const [deviceIdentifier, setDeviceIdentifier]: [string, (value: (((prevState: string) => string) | string)) => void] = useStateWithLocalStoragePersistenceAndDefault<string>(DeviceLocalStorageKeys.identifier, uuidv4());
    const [deviceReadableName, setDeviceReadableName] = useStateWithLocalStoragePersistenceAndDefault<string>(DeviceLocalStorageKeys.readableName, "Unbekanntes Gerät");
    const [deviceColor, setDeviceColor] = useStateWithLocalStoragePersistenceAndDefault<string>(DeviceLocalStorageKeys.color, "blue");

    return {
        deviceIdentifier: deviceIdentifier,
        deviceReadableName: deviceReadableName,
        deviceColor: deviceColor,
        setDeviceIdentifier,
        setDeviceReadableName,
        setDeviceColor,
    }
}

// Hack to never have undefined in the context
// https://stackoverflow.com/a/69735347
export const useDeviceContext = () => {
    const c = useContext(DeviceInfoContext);
    if (!c)
        throw new Error(
            'No provider found.'
        );
    return c;
};