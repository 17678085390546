/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  JSON: { input: any; output: any; }
  /** The `Long` scalar type represents non-fractional signed whole numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  Long: { input: any; output: any; }
  OffsetDateTime: { input: any; output: any; }
  UUID: { input: any; output: any; }
};

/** A crew to which a user can belong to. */
export type CrewInformationUb = {
  __typename?: 'CrewInformationUB';
  identifier: Scalars['String']['output'];
  joiningIdentifier: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

/** Entity that can be identified */
export type Identifiable = {
  id: Scalars['ID']['output'];
};

/** A live event scoped to a crew. */
export type LiveEventUbInputType = {
  crewId: Scalars['UUID']['input'];
  deviceIdentifier: Scalars['String']['input'];
  deviceReadableName?: InputMaybe<Scalars['String']['input']>;
  eventID: Scalars['UUID']['input'];
  eventName: Scalars['String']['input'];
  payload: Scalars['JSON']['input'];
  timestamp: Scalars['OffsetDateTime']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addUserToCrew: CrewInformationUb;
  offerCrewLiveEvent: Scalars['String']['output'];
  setLivePositionInPlaylist: Array<PlaylistLivePositionEntry>;
};


export type MutationAddUserToCrewArgs = {
  emailUsedDuringJoin: Scalars['String']['input'];
  joiningIdentifier: Scalars['String']['input'];
};


export type MutationOfferCrewLiveEventArgs = {
  crewLiveEvent: LiveEventUbInputType;
};


export type MutationSetLivePositionInPlaylistArgs = {
  playlistIdentifier: Scalars['String']['input'];
  playlistLivePositionEntry: PlaylistLivePositionEntryInputType;
};

/** A playlist item such as a ToC entry or a title or note */
export type PlaylistItemUb = {
  __typename?: 'PlaylistItemUB';
  note?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  tocId?: Maybe<TocIdUb>;
};

/** Defines a position in a playlist. */
export type PlaylistLivePositionEntry = {
  __typename?: 'PlaylistLivePositionEntry';
  currentLyricsPosition?: Maybe<Scalars['Int']['output']>;
  currentSlideIndex?: Maybe<Scalars['Int']['output']>;
  deviceIdentifier: Scalars['String']['output'];
  deviceReadableName?: Maybe<Scalars['String']['output']>;
  selectedPlaylistItemIndex?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['OffsetDateTime']['output'];
};

/** Defines a position in a playlist. */
export type PlaylistLivePositionEntryInputType = {
  currentLyricsPosition?: InputMaybe<Scalars['Int']['input']>;
  currentSlideIndex?: InputMaybe<Scalars['Int']['input']>;
  deviceIdentifier: Scalars['String']['input'];
  deviceReadableName?: InputMaybe<Scalars['String']['input']>;
  selectedPlaylistItemIndex?: InputMaybe<Scalars['Int']['input']>;
  updatedAt: Scalars['OffsetDateTime']['input'];
};

/** A Playlist */
export type PlaylistUb = Identifiable & {
  __typename?: 'PlaylistUB';
  belongsToCrew?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['OffsetDateTime']['output'];
  id: Scalars['ID']['output'];
  identifier: Scalars['String']['output'];
  isRemoved: Scalars['Boolean']['output'];
  items: Array<PlaylistItemUb>;
  lastServerVersion: Scalars['Int']['output'];
  tags: Array<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['OffsetDateTime']['output'];
};

export type Query = {
  __typename?: 'Query';
  /** Returns the live positions of all users for a specific playlist. */
  findPlaylistLivePosition: Array<PlaylistLivePositionEntry>;
  /** Returns a song with specific `id`. */
  findTocEntry?: Maybe<SongUb>;
  /** Returns a list of all crews to which the current user belongs. */
  listCrew: Array<CrewInformationUb>;
  /** Returns a list of all playlists for which the currently logged in user has access to. */
  listPlaylist: Array<PlaylistUb>;
  /** Returns a list of all available songs. */
  listTocEntry: Array<SongUb>;
  /** Dummy query for testing */
  testQuery: Scalars['String']['output'];
};


export type QueryFindPlaylistLivePositionArgs = {
  playlistIdentifier: Scalars['String']['input'];
};


export type QueryFindTocEntryArgs = {
  id: Scalars['ID']['input'];
};

/** A song entry in the table of contents */
export type SongUb = Identifiable & {
  __typename?: 'SongUB';
  createdAt: Scalars['OffsetDateTime']['output'];
  id: Scalars['ID']['output'];
  lyrics?: Maybe<Scalars['String']['output']>;
  pdfSourceVersion?: Maybe<Scalars['String']['output']>;
  pictureReferences: Array<Scalars['String']['output']>;
  songId: Scalars['Long']['output'];
  songnumbers: Array<SongnumberUb>;
  sourceSystem: Scalars['String']['output'];
  tags: Array<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  tocId: TocIdUb;
  tonality?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['OffsetDateTime']['output'];
};

/** Songnumber in a specific book */
export type SongnumberUb = {
  __typename?: 'SongnumberUB';
  mnemonic: Scalars['String']['output'];
  mnemonicNumber: Scalars['String']['output'];
  number: Scalars['String']['output'];
  songbookId: Scalars['Long']['output'];
  songbookTitle: Scalars['String']['output'];
};

/** Identifies a toc entry across different source systems. */
export type TocIdUb = {
  __typename?: 'TocIdUB';
  songId: Scalars['Long']['output'];
  sourceSystem: Scalars['String']['output'];
};

export type AddUserToCrewMutationVariables = Exact<{
  joiningIdentifier: Scalars['String']['input'];
  emailUsedDuringJoin: Scalars['String']['input'];
}>;


export type AddUserToCrewMutation = { __typename?: 'Mutation', addUserToCrew: { __typename?: 'CrewInformationUB', title: string } };

export type CrewListQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type CrewListQueryQuery = { __typename?: 'Query', listCrew: Array<{ __typename?: 'CrewInformationUB', identifier: string, joiningIdentifier: string, title: string }> };

export type OfferCrewLiveEventMutationVariables = Exact<{
  crewLiveEvent: LiveEventUbInputType;
}>;


export type OfferCrewLiveEventMutation = { __typename?: 'Mutation', offerCrewLiveEvent: string };

export type PlaylistQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type PlaylistQueryQuery = { __typename?: 'Query', listPlaylist: Array<{ __typename?: 'PlaylistUB', identifier: string, title: string, belongsToCrew?: string | null, tags: Array<string>, createdAt: any, updatedAt: any, lastServerVersion: number, isRemoved: boolean, items: Array<{ __typename?: 'PlaylistItemUB', title?: string | null, note?: string | null, tocId?: { __typename?: 'TocIdUB', sourceSystem: string, songId: any } | null }> }> };


export const AddUserToCrewDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"addUserToCrew"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"joiningIdentifier"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"emailUsedDuringJoin"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"addUserToCrew"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"joiningIdentifier"},"value":{"kind":"Variable","name":{"kind":"Name","value":"joiningIdentifier"}}},{"kind":"Argument","name":{"kind":"Name","value":"emailUsedDuringJoin"},"value":{"kind":"Variable","name":{"kind":"Name","value":"emailUsedDuringJoin"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"title"}}]}}]}}]} as unknown as DocumentNode<AddUserToCrewMutation, AddUserToCrewMutationVariables>;
export const CrewListQueryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"crewListQuery"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"listCrew"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"identifier"}},{"kind":"Field","name":{"kind":"Name","value":"joiningIdentifier"}},{"kind":"Field","name":{"kind":"Name","value":"title"}}]}}]}}]} as unknown as DocumentNode<CrewListQueryQuery, CrewListQueryQueryVariables>;
export const OfferCrewLiveEventDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"offerCrewLiveEvent"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"crewLiveEvent"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"LiveEventUBInputType"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"offerCrewLiveEvent"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"crewLiveEvent"},"value":{"kind":"Variable","name":{"kind":"Name","value":"crewLiveEvent"}}}]}]}}]} as unknown as DocumentNode<OfferCrewLiveEventMutation, OfferCrewLiveEventMutationVariables>;
export const PlaylistQueryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"playlistQuery"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"listPlaylist"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"identifier"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"belongsToCrew"}},{"kind":"Field","name":{"kind":"Name","value":"tags"}},{"kind":"Field","name":{"kind":"Name","value":"items"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"tocId"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sourceSystem"}},{"kind":"Field","name":{"kind":"Name","value":"songId"}}]}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"note"}}]}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"}},{"kind":"Field","name":{"kind":"Name","value":"lastServerVersion"}},{"kind":"Field","name":{"kind":"Name","value":"isRemoved"}}]}}]}}]} as unknown as DocumentNode<PlaylistQueryQuery, PlaylistQueryQueryVariables>;