import * as React from 'react';
import {ReactNode} from 'react';
import {Badge} from 'reactstrap';
import {SongnumberEntryDTO} from "../../dto/v1/toc/SongnumberEntryDTO";
import {TocEntryDTO} from "../../dto/v1/toc/TocEntryDTO";
import {ImageUrlResolver} from "../../helper/ImageUrlResolver";
import PlaylistItemData from "../data/PlaylistItemData";
import {CyPlaylistItem} from "./CyPlaylistItem";


interface Props {
    playlistEntry: PlaylistItemData
    showImageThumbnails: boolean
}

const imagePathResolver = new ImageUrlResolver();

function PlaylistItemSong(props: Props) {

    if (!props.playlistEntry.toCEntry) {
        return (<>"Error: Component can only render found items."</>);
    }

    return (
        <>
            <span data-cy={CyPlaylistItem.EntryTitle}>{props.playlistEntry.toCEntry.title}</span>
            <br/>
            {generateTags(props.playlistEntry.toCEntry)}
            <Badge color="light" className={"me-2 text-dark"}>{props.playlistEntry.toCEntry.tonality}</Badge>
            {generateSongNumbers(props.playlistEntry.toCEntry)}
            <br/>
            {props.showImageThumbnails ? (
                <div className={"mt-3"}>
                    {generateImages(props.playlistEntry.toCEntry)}
                </div>
            ) : null}
        </>
    );

    function generateSongNumbers(entry: TocEntryDTO): ReactNode {
        return entry.songnumbers.map((s: SongnumberEntryDTO) => <Badge color="light" className={"me-2 text-dark"} key={s.mnemonicNumber}>{s.mnemonicNumber}</Badge>)
    }

    function generateTags(entry: TocEntryDTO): ReactNode {
        return entry.tags.map((s: string) => <Badge color="light" className={"me-2 text-dark"} key={s}>{s}</Badge>)
    }

    function generateImages(entry: TocEntryDTO) {
        const allImagePathsForSong = imagePathResolver.resolveAllUrlsForSong(entry);
        return allImagePathsForSong.map((path) => {
            return (
                <img src={path} key={path} className="border rounded" height="300px" style={{marginRight: 5}} alt={"Öffnen"}/>
            )
        });
    }
}

export default PlaylistItemSong;
