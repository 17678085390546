
import _ from "lodash";
import {TocIdDTO, TocIdDTOCompanion} from "../toc/TocIdDTO";

export interface PlaylistItemDTO {
    tocId?: TocIdDTO,
    title?: string;
    note?: string;
}

export class PlaylistItemDTOCompanion {

    public static create(
        tocId?: TocIdDTO,
        title?: string,
        note?: string
    ): PlaylistItemDTO {
        return {
            tocId: tocId,
            title: title,
            note: note
        }
    }

    public static fromParsedJson(playlistItems: ReadonlyArray<PlaylistItemDTO>): ReadonlyArray<PlaylistItemDTO> {
        return _.flatMap(playlistItems, (i: PlaylistItemDTO, index: number) => {
            //we write the following if block explicit like this: i.tocId !== undefined && i.tocId !== null
            //... to show, that an Option.None is a null value in GraphQL
            if (i.tocId !== undefined && i.tocId !== null) {
                const tocId = TocIdDTOCompanion.fromParsedJson(i.tocId);
                return PlaylistItemDTOCompanion.create(tocId, i.title, i.note);
            } else if((i.title !== undefined && i.title !== null) || (i.note !== undefined && i.note !== null)) {
                return PlaylistItemDTOCompanion.create(undefined, i.title, i.note);
            }else {
                return [];
            }
        });
    }

}
