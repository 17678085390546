import {HashCalculator} from "../helper/HashCalculator";

export class UpdateDeltaCalculator {
    private readonly hashCalculator = new HashCalculator();

    public calculateDelta(urlsFromToc: Set<string>, urlsFromCache: Set<string>): { urlsToBeDownloaded: string[]; urlsToBeDeleted: string[] } {
        const urlHashmapFromToc = this.hashCalculator.buildHashMap(urlsFromToc);
        const urlHashmapFromCache = this.hashCalculator.buildHashMap(urlsFromCache);

        return {
            urlsToBeDownloaded: this.calculateToBeDownloadedFromMap(urlHashmapFromToc, urlHashmapFromCache),
            urlsToBeDeleted: this.calculateToBeDeletedFromMap(urlHashmapFromToc, urlHashmapFromCache)
        }
    }

    public calculateToBeDownloaded(urlsFromToc: Set<string>, urlsFromCache: Set<string>): string[] {
        return this.calculateToBeDownloadedFromMap(this.hashCalculator.buildHashMap(urlsFromToc), this.hashCalculator.buildHashMap(urlsFromCache))
    }

    public calculateToBeDeleted(urlsFromToc: Set<string>, urlsFromCache: Set<string>): string[] {
        return this.calculateToBeDeletedFromMap(this.hashCalculator.buildHashMap(urlsFromToc), this.hashCalculator.buildHashMap(urlsFromCache))
    }

    private calculateToBeDownloadedFromMap(urlHashmapFromToc: Map<number, string>, urlHashmapFromCache: Map<number, string>): string[] {
        const toBeDownloaded: string[] = [];
        for (let [key, value] of urlHashmapFromToc.entries()) {
            if (!urlHashmapFromCache.has(key)) {
                toBeDownloaded.push(value)
            }
        }
        return toBeDownloaded;
    }

    private calculateToBeDeletedFromMap(urlHashmapFromToc: Map<number, string>, urlHashmapFromCache: Map<number, string>): string[] {
        const toBeDeleted: string[] = [];
        for (let [key, value] of urlHashmapFromCache.entries()) {
            if (!urlHashmapFromToc.has(key)) {
                toBeDeleted.push(value)
            }
        }
        return toBeDeleted;
    }


}