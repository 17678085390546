import {PlaylistLocalStoragePersistence} from "../localStoragePersistence/v3/playlist/PlaylistLocalStoragePersistence";
import PlaylistData from "../playlist/data/PlaylistData";
import PlaylistMapper from "../playlist/mapper/PlaylistMapper";
import _ from "lodash";
import PlaylistManipulationUtils from "../playlist/PlaylistManipulationUtils";

export class PlaylistIndexManipulator {

    public static createPlaylist(identifierToCreate: string, belongsToCrew: string | undefined) {
        const newPlaylist = PlaylistData.createNew(identifierToCreate, belongsToCrew);
        new PlaylistLocalStoragePersistence().persist(new PlaylistMapper().mapDataToPersisted(newPlaylist));
    }

    public static importPlaylist(playlist: PlaylistData) {
        new PlaylistLocalStoragePersistence().persist(new PlaylistMapper().mapDataToPersisted(playlist));
    }

    public static deletePlaylists(playlistsToDelete: readonly PlaylistData[]) {
        _.forEach(playlistsToDelete, (playlistToDelete) => {
            new PlaylistLocalStoragePersistence().persist(new PlaylistMapper().mapDataToPersisted((playlistToDelete.copyWithIsRemoved(true))))
        })
    }

    public static copyPlaylistsToCrew(playlistsToCopy: readonly PlaylistData[], targetCrew: string | undefined) {
        this.internalCopyPlaylistsToCrew(playlistsToCopy, targetCrew)
    }

    private static internalCopyPlaylistsToCrew(playlistsToCopy: readonly PlaylistData[], targetCrew: string | undefined) {
        _.forEach(playlistsToCopy, (playlistToCopy) => {
            this.internalCopyPlaylistToCrew(playlistToCopy, targetCrew)
        })
    }

    private static internalCopyPlaylistToCrew(playlistToCopy: PlaylistData, targetCrew: string | undefined) {
        const newPlaylist = PlaylistManipulationUtils.copyPlaylistFromExistingToCrew(playlistToCopy, targetCrew)
        new PlaylistLocalStoragePersistence().persist(new PlaylistMapper().mapDataToPersisted(newPlaylist));
        return newPlaylist.getIdentifier()
    }

    public static movePlaylistToCrew(playlistToMove: PlaylistData, targetCrew: string | undefined) {
        const newIdentifier = this.internalCopyPlaylistToCrew(playlistToMove, targetCrew)
        this.deletePlaylists([playlistToMove])
        return newIdentifier
    }

    public static movePlaylistsToCrew(playlistsToMove: readonly PlaylistData[], targetCrew: string | undefined) {
        this.internalCopyPlaylistsToCrew(playlistsToMove, targetCrew)
        this.deletePlaylists(playlistsToMove)
    }
}
