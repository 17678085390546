import {Client, gql, OperationResult} from "urql";
import {LiveEventUbInputType, PlaylistLivePositionEntryInputType} from "../gql/graphql";

const offerCrewLiveEventMutation = gql`
    mutation offerCrewLiveEvent($crewLiveEvent: LiveEventUBInputType!){
        offerCrewLiveEvent(
            crewLiveEvent: $crewLiveEvent)
    }
`

export const CrewLiveEventPusher = {
    offerCrewLiveEvent(crewLiveEvent: LiveEventUbInputType, graphQlClient: Client): Promise<string> {
        return graphQlClient
            .mutation(offerCrewLiveEventMutation, {crewLiveEvent : crewLiveEvent })
            .toPromise()
            .then((result: OperationResult<any, object>) => {
                if (result.error === undefined) {
                    const resultString = result.data.offerCrewLiveEvent
                    return Promise.resolve(resultString);
                } else {
                    return Promise.reject(result);
                }
            });
    }
} as const

