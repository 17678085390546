import * as React from 'react';
import {Permission} from "./Permission";
import Auth from "./Auth";

interface Props {
    requiredPermissions: readonly Permission[]
    children?: React.ReactNode
    auth: Auth
}

export function RenderPermissionBased(props: Props) {
    return (
        <>
            {props.auth.hasPermissions(props.requiredPermissions) && props.children}
        </>
    );
}
