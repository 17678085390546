import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorkerRegistration';
import 'bootstrap/dist/css/bootstrap.min.css';

const container = document.getElementById('root')!;

const root = ReactDOMClient.createRoot(container);

root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// Note Philipp:
// The idea for the onUpdate event handling is from https://betterprogramming.pub/let-users-know-when-you-have-updated-your-service-worker-in-create-react-app-b0c2701995b3
// and https://stackoverflow.com/questions/52904430/how-to-implement-skipwaiting-with-create-react-app
const serviceWorkerRegistration = "ServiceWorkerRegistration"
serviceWorker.register({
    onUpdate: registration => {
        const waitingServiceWorker = registration.waiting
        console.debug(serviceWorkerRegistration + ": onUpdate event received. registration: ", registration);

        if (waitingServiceWorker) {
            waitingServiceWorker.addEventListener("statechange", (event: Event) => {
                //We don't have the correct TypeScript type for the event. See https://github.com/microsoft/TypeScript/issues/299
                //That's why we do this casting below (code from https://github.com/microsoft/TypeScript/issues/40153#issuecomment-976982264)
                const sw = event.target as ServiceWorker;
                console.debug(serviceWorkerRegistration + ": statechange event received. sw: ", sw);
                if (sw.state === "activated") {
                    console.info(serviceWorkerRegistration + ": New service worker activated --> Showing dialog to reload")
                    alert("Auf deinem Gerät wurde soeben die neuste Version von Songship installiert.\r\nDie Seite wird jetzt neu geladen. Dann bist du bereit.")
                    window.location.reload()
                }
            });
            console.info(serviceWorkerRegistration + ": We have a waiting service worker. Sending the SKIP_WAITING message now.")
            waitingServiceWorker.postMessage({type: "SKIP_WAITING"});
        }
    }
});
