import * as React from 'react';
import {FormText} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWarning} from "@fortawesome/free-solid-svg-icons";

export default function OwnNavigationDisabledWarning() {

    return (
        <div className="mt-1">
            <FormText>
                <FontAwesomeIcon icon={faWarning} className="me-1 text-warning"/>
                Deine eigene Navigation wird deaktiviert, so lange du jemandem folgst.
            </FormText>
        </div>
    );

}

