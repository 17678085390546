import * as React from 'react';
import {OfflineUpdateSummary} from "./OfflineUpdateSummary";
import DownloadIndicator from "./DownloadIndicator";
import {BackendServerAvailability} from "../appinitandstate/BackendServerAvailability";
import Auth from "../auth/Auth";
import {DownloadNecessaryButNoBackendAvailabilityNotification} from "./DownloadNecessaryButNoBackendAvailabilityNotification";
import {DownloadNecessaryNotification} from "./DownloadNecessaryNotification";


interface Props {
    auth: Auth,
    currentBackendServerAvailability: BackendServerAvailability,
    startUpdate: () => void,
    resetProgress: () => void,
    offlineUpdateSummary?: OfflineUpdateSummary
    downloadOfFilesNecessary: boolean
}

class OfflineComponentsHolder extends React.Component<Props, {}> {
    public render() {
        return (
            <>
                {this.props.offlineUpdateSummary ? (
                    <>
                        <br/>
                        <DownloadIndicator offlineUpdateSummary={this.props.offlineUpdateSummary} resetProgress={this.props.resetProgress}/>
                    </>
                ) : this.props.downloadOfFilesNecessary && (
                    <>
                        {this.props.currentBackendServerAvailability === BackendServerAvailability.Available ? (<>
                            <DownloadNecessaryNotification startUpdate={this.props.startUpdate}/>
                        </>) : (<>
                            <DownloadNecessaryButNoBackendAvailabilityNotification/>
                        </>)}
                    </>
                )}
            </>
        );
    }
}

export default OfflineComponentsHolder;
