import * as React from 'react';
import {Button, Nav, Navbar, NavItem} from 'reactstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCogs, faQuestion, faUsers} from '@fortawesome/free-solid-svg-icons';
import {Link} from "react-router-dom";
import {CyApp} from "../CyApp";
import {MainMenuTexts} from "./MainMenuTexts";
import {RoutePaths} from "../RoutePaths";

interface Props {
    isShortHelpDialogOpen: boolean
    isCrewDialogOpen: boolean
    setIsCrewDialogOpen: ((value: (((prevState: boolean) => boolean) | boolean)) => void)
    setIsShortHelpDialogOpen: ((value: (((prevState: boolean) => boolean) | boolean)) => void)
}

export default function MainMenu(props: Props) {

    return (
        <Navbar color="light" light expand="lg">
            <Nav className="me-auto" navbar>
                <NavItem>
                    <Button onClick={() => props.setIsCrewDialogOpen(prevState => !prevState)} className="nav-link" color="link" active={props.isCrewDialogOpen} data-cy={CyApp.ToggleShowCrewButton}>
                        <FontAwesomeIcon icon={faUsers}/> Crews {props.isCrewDialogOpen ? "schliessen" : "öffnen"}
                    </Button>
                </NavItem>
                <NavItem>
                    <Link to={`/${RoutePaths.SETTINGS}`} className="nav-link" data-cy={CyApp.MainMenuButton}>
                        <FontAwesomeIcon icon={faCogs}/> {MainMenuTexts.AdministrationAndOfflineUsage}
                    </Link>
                </NavItem>
                <NavItem>
                    <Button onClick={() => props.setIsShortHelpDialogOpen(prevState => !prevState)} className="nav-link" color="link" active={props.isShortHelpDialogOpen}>
                        <FontAwesomeIcon icon={faQuestion}/> Hilfe {props.isShortHelpDialogOpen ? "schliessen" : "öffnen"}
                    </Button>
                </NavItem>
            </Nav>
        </Navbar>
    );
}

