import {RoutePaths} from "../RoutePaths";

export class PlaylistRoutePaths {

    public static CAROUSEL = "carousel"
    public static toCarousel = (playlistIdentifier: string) => `/${RoutePaths.PLAYLIST}/${playlistIdentifier}/${PlaylistRoutePaths.CAROUSEL}`

    public static TOC_INDEX = "tocIndex"
    public static toTocIndex = (playlistIdentifier: string) => `/${RoutePaths.PLAYLIST}/${playlistIdentifier}/${PlaylistRoutePaths.TOC_INDEX}`;

    public static BEAMER_VIEW = "beamerView"
    public static toBeamerView = (playlistIdentifier: string) => `/${RoutePaths.PLAYLIST}/${playlistIdentifier}/${PlaylistRoutePaths.BEAMER_VIEW}`
}
