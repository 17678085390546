import * as React from 'react';
import {Button} from 'reactstrap';
import PlaylistData from "../playlist/data/PlaylistData";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown, faAngleUp} from "@fortawesome/free-solid-svg-icons";

interface Props {
    title: string
    field: keyof PlaylistData
    currentOrder: "asc" | "desc"
    currentField: keyof PlaylistData
    changeOrderBy: (newField: keyof PlaylistData) => void
    renderBullet?: boolean
    dataCy?: string
}

export function OrderByButton(props: Props) {
    function renderOrderIcon(): JSX.Element {
        if (props.currentField === props.field) {
            if (props.currentOrder === "asc") {
                return <FontAwesomeIcon icon={faAngleUp}/>
            } else {
                return <FontAwesomeIcon icon={faAngleDown}/>
            }
        } else {
            return props.renderBullet ? <>&bull;</> : <></>
        }
    }

    return (
        <Button
            color="link"
            style={{padding: "0", textDecoration: "none"}}
            onClick={() => props.changeOrderBy(props.field)}
            data-cy={props.dataCy}
        >
            {renderOrderIcon()} {props.title}
        </Button>
    );
}