import {TocEntryDTO} from "./TocEntryDTO";
import {TocIdDTO, TocIdDTOCompanion} from "../../v3/toc/TocIdDTO";

export class TableOfContentsDTO {
    private readonly toc: Map<string, TocEntryDTO> = new Map();

    public get(tocId: TocIdDTO) {
        return this.toc.get(TocIdDTOCompanion.stringify(tocId));
    }

    public values() {
        return this.toc.values();
    }

    public addTocEntry(tocEntry: TocEntryDTO) {
        const tocIdDTO = TocIdDTOCompanion.fromSongIdAndSourceSystem(tocEntry.songId, tocEntry.sourceSystem);
        this.toc.set(TocIdDTOCompanion.stringify(tocIdDTO), tocEntry);
    }

    public keysAsArray(): TocIdDTO[] {
        const keys: TocIdDTO[] = [];
        for (const aKey of this.toc.keys()) {
            const tocId = TocIdDTOCompanion.fromString(aKey);
            if (tocId === undefined) {
                console.warn("Could not create TocIdDTO for key string '" + aKey + "'. Skipping this key.");
            } else {
                keys.push(tocId);
            }
        }
        return keys;
    }

    public size(): number {
        return this.toc.size
    }

}