export const RoutePaths = {
    PLAYLIST: "playlist",
    toPlaylist: (playlistIdentifier: string) => `/${RoutePaths.PLAYLIST}/${playlistIdentifier}`,
    TABLE_OF_CONTENTS: "tableOfContents",
    SHARE_PLAYLIST: "sharePlaylist",
    SETTINGS: "settings",
    IMPORT_PLAYLIST: "importPlaylist",
    JOIN_CREW: "join-crew",
    LOGOUT: "logout",
    STUB_LOGIN: "stub-login",
    CALLBACK: "callback",
} as const;