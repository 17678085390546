import * as React from 'react';
import {ReactNode} from 'react';
import './TocAvailabilityNotice.css';
import {Alert, Button} from "reactstrap";
import {faCloudShowersHeavy, faKey, faLock} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {TocAvailability} from "./TocAvailability";
import Header from "../Header";
import {LoadingMessage} from "../LoadingMessage";

interface Props {
    logout: () => void,
    tocAvailability: TocAvailability
}

class TocAvailabilityNotice extends React.Component<Props, {}> {

    public render() {
        return (
            <>
                {this.generateToCUnavailableMessage()}
            </>
        );
    }

    private generateToCUnavailableMessage(): ReactNode {
        if (this.props.tocAvailability === TocAvailability.Undecided) {
            return (
                <>
                    <Header/>
                    <br/>
                    <LoadingMessage message="Bitte warte, während Songship das Inhaltsverzeichnis lädt." />
                </>
            )
        } else if (this.props.tocAvailability === TocAvailability.InsufficientPermissions) {
            return (
                <>
                    <Header/>
                    <br/>
                    <Alert color="warning">
                        <h2 className={"light-header"}><FontAwesomeIcon icon={faKey}/> Unzureichende Berechtigung</h2>
                        Du bist erfolgreich am System angemeldet. Allerdings fehlen dir die notwendigen Berechtigungen, um Songship zu nutzen.<br/>
                        Falls du dich gerade eben registriert hast, ist das ganz normal. Du musst zuerst vom Support freigeschaltet werden, damit dieser verifizieren kann, ob du
                        auch wirklich ein zahlender Kunde bist. In dem Fall, schau gerne später wieder vorbei oder kontaktiere uns.<br/>
                        Falls du dich mit einem anderen Benutzer anmelden möchtest, logge dich bitte aus.<br/> <br/>
                        <Button color="primary" onClick={() => this.props.logout()}><FontAwesomeIcon icon={faLock}/> Abmelden</Button>
                    </Alert>
                </>
            )
        } else if (this.props.tocAvailability === TocAvailability.ToCNotAvailable) {
            return (
                <>
                    <Header/>
                    <br/>
                    <Alert color="danger">
                        <h2 className={"light-header"}><FontAwesomeIcon icon={faCloudShowersHeavy}/> Offline</h2>
                        Das Inhaltsverzeichnis konnte weder aus dem lokalen Offline-Cache noch vom Internet geladen geladen werden. Bitte verbinde dich mit dem Internet, um die Daten
                        herunterzuladen.<br/>
                        Falls du Probleme mit der Offline-Nutzung von Songship hast, melde dich bitte beim Support.
                    </Alert>
                </>)
        } else {
            return (<></>)
        }
    }
}

export default TocAvailabilityNotice;
