import {TocEntryDTO} from "../dto/v1/toc/TocEntryDTO";
import Fuse from "fuse.js"
import {nameofFactory} from "../helper/nameOf";


export class SearchEngine {

    private nameOf = nameofFactory<TocEntryDTO>();

    private readonly fuseOptions: Fuse.IFuseOptions<TocEntryDTO> = {
        shouldSort: true,
        findAllMatches: true,
        useExtendedSearch: true,
        keys: [
            this.nameOf("title"),
            "songnumbers.mnemonicNumber",
            this.nameOf("tags"),
            this.nameOf("tonality")
        ]
    };

    private readonly fuseSearch: Fuse<TocEntryDTO>;
    private readonly allEntries: TocEntryDTO[];

    //filters and tags
    private readonly availableTonalities = new Set<string>();
    private readonly availableTags = new Set<string>();

    constructor(allEntries: TocEntryDTO[]) {
        allEntries.forEach(v => {
            if (v.tonality && v.tonality.length > 0) this.availableTonalities.add(v.tonality);
            v.tags.forEach(t => this.availableTags.add(t));

        })
        this.fuseSearch = new Fuse(allEntries, this.fuseOptions);
        this.allEntries = allEntries;
    }

    /**
     * Searches through all toc entries. Each param acts as a restriction. An empty array or string means no restriction.
     */
    public search(searchTerm: string, selectedTonalities: string[], selectedTags: string[]): TocEntryDTO[] {
        //https://fusejs.io/examples.html#extended-search
        const exactMatch = (v: string) => '="' + v + '"';

        if (!searchTerm && selectedTonalities.length === 0 && selectedTags.length === 0) {
            console.info("Searching performed without a search term. Returning everything.");
            return this.allEntries;
        }

        const expressions: Fuse.Expression[] = [];
        if (searchTerm && searchTerm.length > 0) expressions.push({
            $or: [
                {title: searchTerm},
                {"songnumbers.mnemonicNumber": exactMatch(searchTerm)}
            ]
        })
        if (selectedTonalities.length > 0) expressions.push({$or: selectedTonalities.map(t => ({tonality: exactMatch(t)}))})
        if (selectedTags.length > 0) expressions.push({$or: selectedTags.map(t => ({tags: exactMatch(t)}))})

        const searchExpression: Fuse.Expression = {
            $and: expressions
        };

        const results: Fuse.FuseResult<TocEntryDTO>[] = this.fuseSearch.search<TocEntryDTO>(searchExpression);
        console.debug("Search performed with the following params and results:",
            {
                searchTerm: searchTerm,
                tonalities: selectedTonalities,
                tags: selectedTags,
                searchExpression: searchExpression,
                results: results
            });
        //TODO: mapping every result is maybe expensive...
        return results.map(v => v.item);
    }

    public getTonalities(): string[] {
        return Array.from(this.availableTonalities);
    }

    public getTags(): string[] {
        return Array.from(this.availableTags);
    }

}