import _ from "lodash";

export default class ArrayUtils {

    public static previousOf<T>(arr: ReadonlyArray<T>, predicate: (e: T) => boolean): T | undefined {
        const index = arr.findIndex(predicate);
        const isNotFirstElement = index > 0;
        const elementFound = index > -1;

        if (elementFound && isNotFirstElement) {
            return arr[index - 1];
        } else {
            return undefined;
        }
    }

    public static followingOf<T>(arr: ReadonlyArray<T>, predicate: (e: T) => boolean): T | undefined {
        const index = arr.findIndex(predicate);
        const isNotLastElement = index < arr.length;
        const elementFound = index > -1;

        if (elementFound && isNotLastElement) {
            return arr[index + 1];
        } else {
            return undefined;
        }
    }

    public static remove<T>(arr: ReadonlyArray<T>, predicate: (e: T) => boolean): ReadonlyArray<T> {
        return arr.filter(i => !predicate(i));
    }

    public static insertAt<T>(arr: ReadonlyArray<T>, insertIndex: number, element: T): ReadonlyArray<T> {
        const fixedIndex = insertIndex < 0 ? 0 : (insertIndex > arr.length ? arr.length : insertIndex);
        return _.concat(_.slice(arr, 0, fixedIndex), element, _.slice(arr, fixedIndex));
    }

    public static insertBefore<T>(arr: ReadonlyArray<T>, predicate: (e: T) => boolean, element: T): ReadonlyArray<T> {
        const index = arr.findIndex(predicate);
        if (index < 0) {
            console.warn("no element found which satisfies the given predicate in", arr);
            return arr;
        } else {
            return this.insertAt(arr, index, element);
        }
    }

    public static insertAfter<T>(arr: ReadonlyArray<T>, predicate: (e: T) => boolean, element: T): ReadonlyArray<T> {
        const index = arr.findIndex(predicate);
        if (index < 0) {
            console.warn("no element found which satisfies the given predicate in", arr);
            return arr;
        } else {
            return this.insertAt(arr, index + 1, element);
        }
    }

    public static up<T>(arr: ReadonlyArray<T>, predicate: (e: T) => boolean): ReadonlyArray<T> {
        const indexOfItem = arr.findIndex(predicate);
        if (indexOfItem > 0) {
            return _.concat(_.slice(arr, 0, indexOfItem - 1), arr[indexOfItem], arr[indexOfItem - 1], _.slice(arr, indexOfItem + 1));
        } else {
            return arr;
        }
    }

    public static down<T>(arr: ReadonlyArray<T>, predicate: (e: T) => boolean): ReadonlyArray<T> {
        const indexOfItem = arr.findIndex(predicate);
        if (indexOfItem !== -1 && indexOfItem !== arr.length - 1) {
            return _.concat(_.slice(arr, 0, indexOfItem), arr[indexOfItem + 1], arr[indexOfItem], _.slice(arr, indexOfItem + 2));
        } else {
            return arr;
        }
    }
}