import * as React from "react";
import {ImageUrlResolver} from "../../helper/ImageUrlResolver";

interface Props {
    pictureReference: string
}

export const SongsheetSlide = (props: Props) => {

    const r = new ImageUrlResolver()

    return (
        <img src={r.resolveFor(props.pictureReference)} alt="Notenblatt"/>
    );
}

