import * as React from 'react';
import {Button} from 'reactstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil} from '@fortawesome/free-solid-svg-icons';
import {AdoraySchweizCrewIdentifier} from "../crew/CrewInformation";
import {ScottyLink} from "../helper/ScottyLink";
import {CrewInformationUb} from "../gql/graphql";
import {useCrewQuery} from "../crew/useCrewQuery";
import {PlaylistIndexTexts} from "../playlists/PlaylistIndexTexts";

export function CrewAwareEditSongsNavItem() {

    const crewInformationMaybe: ReadonlyArray<CrewInformationUb> | undefined = useCrewQuery()

    return (
        <>
            {/*TODO: Not only adoray Schweiz!*/}
            {crewInformationMaybe && belongsToAdoraySchweizCrew(crewInformationMaybe) &&
                <Button tag="a" href={ScottyLink()} target="_blank" color="primary">
                    <FontAwesomeIcon icon={faPencil}/> {PlaylistIndexTexts.EditSongs}
                </Button>
            }
        </>
    );
}

function belongsToAdoraySchweizCrew(crewInformation: ReadonlyArray<CrewInformationUb>): boolean {
    return crewInformation.some(c => c.identifier === AdoraySchweizCrewIdentifier);
}