import * as React from 'react';
import {PropsWithChildren} from 'react';
import {SwiperSlide} from "swiper/react";


interface Props {
    additionalClassNames?: string
}

//Workaround according to https://github.com/nolimits4web/swiper/issues/4413#issuecomment-1021387492
export const SongshipSwiperSlide = (props: PropsWithChildren<Props>) => {
    return (
        <SwiperSlide>
            <div className={"swiper-zoom-container " + (props.additionalClassNames ? props.additionalClassNames : "")}>
                {props.children}
            </div>
        </SwiperSlide>
    );
}

SongshipSwiperSlide.displayName = 'SwiperSlide';