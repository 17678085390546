import * as React from 'react';
import {CardBody, CardTitle, FormGroup, FormText, Input} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPeopleGroup, faUser} from "@fortawesome/free-solid-svg-icons";
import {InputEditable} from "../common/InputEditable";
import {LivePositionMarker} from "../live/LivePositionMarker";
import {DeviceInfo} from "../device/DeviceInfoContext";
import OwnNavigationDisabledWarning from "./OwnNavigationDisabledWarning";
import _ from "lodash";

interface Props {
    deviceInfo: DeviceInfo
    setDeviceReadableName: (name: string) => void
    setDeviceColor: (color: string) => void
    devicesInPlaylist: readonly DeviceInfo[]
    listOfFollowedDevices: string[]
    setListOfFollowedDevices: (value: (prevState: string[]) => string[]) => void
}

export default function PlaylistLivePositionSidebar(props: Props) {

    return (
        <>
            <CardBody>
                <CardTitle>
                    <FontAwesomeIcon icon={faPeopleGroup}/> Weitere aktive Geräte
                </CardTitle>
                <FormText>Wähle, welchen Geräten du folgen möchtest.</FormText>
                {props.devicesInPlaylist.map((info: DeviceInfo) => <div key={info.deviceIdentifier}>
                    <FormGroup switch>
                        <Input
                            type="switch"
                            disabled={info.deviceIdentifier === props.deviceInfo.deviceIdentifier}
                            checked={props.listOfFollowedDevices.includes(info.deviceIdentifier)}
                            onChange={() => {
                                props.setListOfFollowedDevices(old => (old.includes(info.deviceIdentifier)) ? old.filter(i => i !== info.deviceIdentifier) : [...old, info.deviceIdentifier]);
                            }}
                        />

                        <span>{info.deviceReadableName}</span>
                        <LivePositionMarker
                            color={info.deviceColor}
                            className={"ms-2"}
                        />
                    </FormGroup>
                </div>)}
                {!_.isEmpty(props.listOfFollowedDevices) && <OwnNavigationDisabledWarning/>}
            </CardBody>

            <CardBody>
                <CardTitle><FontAwesomeIcon icon={faUser}/> Eigenes Gerät</CardTitle>
                <FormGroup>
                    <div>
                        <InputEditable
                            name={"deviceReadableName"}
                            content={props.deviceInfo.deviceReadableName}
                            updateContent={props.setDeviceReadableName}
                            editAllowed={true}
                        >
                            {props.deviceInfo.deviceReadableName}
                        </InputEditable>
                        <InputEditable
                            type="color"
                            name={"deviceColor"}
                            content={props.deviceInfo.deviceColor}
                            updateContent={props.setDeviceColor}
                            editAllowed={true}
                            style={{height: "38px"}}
                        >
                            <LivePositionMarker color={props.deviceInfo.deviceColor}/>
                        </InputEditable>
                    </div>
                    <FormText>Der Name und die Farbe des Geräts, welcher den anderen Benutzern angezeigt werden soll (z.
                        B.
                        Dominiks Handy).</FormText>
                </FormGroup>
            </CardBody>
        </>
    );

}

