import {TocConfig} from "../Config";
import {TableOfContentsDTO} from "../dto/v1/toc/TableOfContentsDTO";
import {TocAvailability} from "./TocAvailability";
import Auth from "../auth/Auth";
import {TocEntryDTO} from "../dto/v1/toc/TocEntryDTO";
import {FetchWithAuth} from "../common/FetchWithAuth";

export class TocFetcher {

    private readonly auth: Auth;

    constructor(auth: Auth) {
        this.auth = auth;
    }

    public fetchSkipCache(): Promise<TableOfContentsDTO | TocAvailability> {
        return this.fetchRaw(TocConfig.urlSkipCache)
            .then(responseOrState => this.mapToToc(responseOrState));
    }

    private fetchRaw(url: string): Promise<Response | TocAvailability> {
        return new FetchWithAuth(this.auth).fetch(url)
            .catch(e => {
                console.warn("Error while fetching toc. This is maybe is just because there is no internet connection. Message: " + e);
                return TocAvailability.ToCNotAvailable;
            }).then(responseOrState => {
                if (responseOrState instanceof Response) {
                    if (responseOrState.status === 401) {
                        return TocAvailability.NotAuthenticated;
                    } else if (responseOrState.status === 403) {
                        console.warn("User does not have the required permission. In our case it is possible that we do not have the 'access' permission which is required by the backend api for paying songship clients.");
                        return TocAvailability.InsufficientPermissions;
                    } else if (!responseOrState.ok) {
                        console.warn("Other error with status " + responseOrState.status + " in response. This is an unexpected error. Investigate deeper.");
                        return TocAvailability.ToCNotAvailable;
                    }
                }
                return responseOrState;
            })
    }

    public fetch(): Promise<TableOfContentsDTO | TocAvailability> {
        return this.fetchRaw(TocConfig.url)
            .then(responseOrState => this.mapToToc(responseOrState));
    }

    private mapToToc(responseOrState: Response | TocAvailability): Promise<TableOfContentsDTO | TocAvailability> {
        if (responseOrState instanceof Response) {
            return responseOrState.json().then(responseJson => {
                const tableOfContents: TableOfContentsDTO = new TableOfContentsDTO();
                Object.values(responseJson).forEach(rawEntry => {
                    //TODO: Do proper parsing
                    const tocEntry = rawEntry as TocEntryDTO;
                    tableOfContents.addTocEntry(tocEntry);
                });
                return Promise.resolve(tableOfContents);
            })
        } else {
            return Promise.resolve(responseOrState);
        }
    }


}
