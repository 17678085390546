import * as React from 'react';
import {ReactNode, useEffect, useState} from 'react';
import {TocEntryDTO} from "../../dto/v1/toc/TocEntryDTO";
import {SongnumberEntryDTO} from "../../dto/v1/toc/SongnumberEntryDTO";
import {Badge, ListGroup, ListGroupItem} from "reactstrap";
import {useHotkeys} from "react-hotkeys-hook";
import {CyResultList} from "./CyResultList";


interface Props {
    tocEntrySelected: (entry: TocEntryDTO) => void,
    searchResults: TocEntryDTO[]
}

export function ResultList(props: Props) {

    const {searchResults} = props;

    useHotkeys('up', () => setSelectedItem(p => Math.max(p - 1, 0)), {enableOnFormTags: ['input']})
    useHotkeys('down', () => setSelectedItem(p => Math.min(p + 1, searchResults.length - 1)), {enableOnFormTags: ['input']})

    useHotkeys('enter', (e) => {
        e.preventDefault()
        const r = searchResults.at(selectedItem)
        r && props.tocEntrySelected(r)
    }, {enableOnFormTags: ['input']})

    const [selectedItem, setSelectedItem] = useState(0);

    useEffect(() => {
        setSelectedItem(0)
    }, [searchResults]);

    function generateTags(entry: TocEntryDTO): ReactNode {
        return entry.tags.map((s: string) => <Badge color="light text-dark me-2" key={s}>{s}</Badge>)
    }

    function generateSongNumbers(entry: TocEntryDTO): ReactNode {
        return entry.songnumbers.map((s: SongnumberEntryDTO) => <Badge color="light" className="text-dark me-2" key={s.mnemonicNumber}>{s.mnemonicNumber}</Badge>)
    }

    return (
        <ListGroup flush>
            {props.searchResults.map((result, index) => {
                return (
                    <ListGroupItem
                        key={result.sourceSystem + "-" + result.songId}
                        tag="button"
                        action
                        onClick={() => props.tocEntrySelected(result)}
                        active={index === selectedItem}
                        data-cy={CyResultList.ButtonResultEntry}
                    >
                        {result.title}
                        <div className="float-md-end">
                            {generateTags(result)}
                            <Badge color="light" className="text-dark me-2">{result.tonality}</Badge>
                            {generateSongNumbers(result)}
                        </div>
                    </ListGroupItem>
                );
            })}
        </ListGroup>
    );
}
