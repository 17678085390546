import * as React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleUser} from "@fortawesome/free-solid-svg-icons";

interface Props {
    color: string,
    className?: string | undefined;
}

export function LivePositionMarker(props: Props) {
    return (
        <span style={{color: props.color}} className={props.className}><FontAwesomeIcon icon={faCircleUser} /></span>
    );
}