import * as React from 'react';
import './PlaylistIndex.css';
import {useCrewQuery} from "../crew/useCrewQuery";
import {CrewInformationUb} from "../gql/graphql";
import {CrewTitleTexts} from "./CrewTitleTexts";

interface Props {
    currentCrewIdentifier: string | undefined
}

export function CrewTitle(props: Props) {
    const crewInformationMaybe: ReadonlyArray<CrewInformationUb> | undefined = useCrewQuery()

    if (props.currentCrewIdentifier === undefined) return (<>{CrewTitleTexts.PrivateCrewTitle}</>)
    else if (!crewInformationMaybe) return (<></>)
    else return (<>{crewInformationMaybe.find(p => p.identifier === props.currentCrewIdentifier)?.title}</>)

}

