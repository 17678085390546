import * as React from 'react';
import {Button, Nav, Navbar, NavItem} from 'reactstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowAltCircleDown, faArrowAltCircleUp, faFileAlt, faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import {PlaylistRoutePaths} from "./PlaylistRoutePaths";
import {Link} from "react-router-dom";
import {CyPlaylist} from "./CyPlaylist";

interface Props {
    playlistIdentifier: string
    selectedItemId: number | undefined
    moveUp: (itemId: number) => void;
    moveDown: (itemId: number) => void;
    remove: (itemId: number) => void;
}

function PlaylistMenu(props: Props) {
    const isItemSelected = props.selectedItemId !== undefined;
    return (
        <Navbar color="light" className="navbar-expand" light sticky={"top"} fixed={"top"}>
            <Nav navbar >
                <NavItem>
                    <Button size="lg" className="nav-link" disabled={!isItemSelected}
                            onClick={() => props.selectedItemId !== undefined && props.moveUp(props.selectedItemId)} color="link">
                        <FontAwesomeIcon icon={faArrowAltCircleUp}/>
                    </Button>
                </NavItem>
                <NavItem>
                    <Button size="lg" className="nav-link" disabled={!isItemSelected}
                            onClick={() => props.selectedItemId !== undefined && props.moveDown(props.selectedItemId)} color="link">
                        <FontAwesomeIcon icon={faArrowAltCircleDown}/></Button>
                </NavItem>
                <NavItem>
                    <Button tag={Link} size="lg" className="nav-link" disabled={!isItemSelected}
                            to={PlaylistRoutePaths.toTocIndex(props.playlistIdentifier)} color="link">
                        <FontAwesomeIcon icon={faFileAlt}/>
                    </Button>
                </NavItem>
                <NavItem>
                    <Button size="lg" data-cy={CyPlaylist.ButtonRemove} className="nav-link" disabled={!isItemSelected}
                            onClick={() => props.selectedItemId !== undefined && props.remove(props.selectedItemId)} color="link">
                        <FontAwesomeIcon icon={faTrashAlt}/>
                    </Button>
                </NavItem>
            </Nav>
        </Navbar>
    );
}

export default PlaylistMenu;
