import * as React from 'react';
import {Button, Card, CardBody, CardTitle, Col, Row} from 'reactstrap';
import {faUser, faUsers} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {CrewInformationUb} from "../gql/graphql";
import {CyCrewSelector} from "./CyCrewSelector";
import {CrewTitleTexts} from "../playlists/CrewTitleTexts";

interface Props {
    crewInformation: ReadonlyArray<CrewInformationUb>
    targetCrewIdentifier: string | undefined
    setTargetCrewIdentifier: (i: string | undefined) => void

    size: "small" | "large"
}

export function CrewSelector(props: Props) {

    function renderLarge(crewName: String, identifier: string | undefined): JSX.Element {
        return <Col key={identifier}>
            <Card className="text-center">
                <Button
                    color={color(identifier)}
                    onClick={onClick(identifier)}
                    data-cy={CyCrewSelector.ButtonSelectCrew}
                >
                    <CardBody>
                        <CardTitle tag="h5">
                            <div className="mb-2">
                                <FontAwesomeIcon icon={icon(identifier)}/>
                            </div>
                            {crewName}
                        </CardTitle>
                    </CardBody>
                </Button>
            </Card>
        </Col>
    }

    function icon(identifier: string | undefined) {
        return identifier === undefined ? faUser : faUsers;
    }

    function onClick(identifier: string | undefined) {
        return () => props.setTargetCrewIdentifier(identifier);
    }

    function color(identifier: string | undefined) {
        return identifier === props.targetCrewIdentifier ? "primary" : "light";
    }

    function renderSmall(crewName: String, identifier: string | undefined): JSX.Element {
        return <Row key={identifier} className="mt-2">
            <Col className="d-flex">
                <Button
                    color={color(identifier)}
                    onClick={onClick(identifier)}
                    style={{flex: "1 1 auto"}}
                    data-cy={CyCrewSelector.ButtonSelectCrew}
                >
                    <FontAwesomeIcon icon={icon(identifier)} className="me-2"/>
                    {crewName}
                </Button>
            </Col>
        </Row>
    }


    return props.size === "large" ?
        <Row className="g-3" data-cy={CyCrewSelector.LargeSelectors}>
            {renderLarge(CrewTitleTexts.PrivateCrewTitle, undefined)}
            {props.crewInformation.map((p) => (
                renderLarge(p.title, p.identifier)
            ))}
        </Row>
        :
        <>
            {renderSmall(CrewTitleTexts.PrivateCrewTitle, undefined)}
            {props.crewInformation.map((p) => (
                renderSmall(p.title, p.identifier)
            ))}
        </>

}

