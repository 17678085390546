import React, {PropsWithChildren, useState} from 'react';
import {Collapse} from 'reactstrap';

interface Props {
    isOpen: boolean
}

/**
 * A collapsible component but lazy:
 * - The children are only rendered when the component is open OR when the animation is running
 * - The children are removed when the component is closed AND the animation is not running anymore
 */
export function LazyCollapse(props: PropsWithChildren<Props>) {
    const [isAnimationRunning, setIsAnimationRunning] = useState(false);

    const onEntering = () => setIsAnimationRunning(true)
    const onExited = () => setIsAnimationRunning(false)

    return (
        <Collapse
            isOpen={props.isOpen}
            onEntering={onEntering}
            onExited={onExited}
        >
            {(isAnimationRunning || props.isOpen) && props.children}
        </Collapse>
    )
}