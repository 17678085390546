import * as React from 'react';
import PlaylistItemData from "../playlist/data/PlaylistItemData";


interface Props {
    playlistData: ReadonlyArray<PlaylistItemData>
}

export default function PlaylistSummaryItemList(props: Props) {
    return (
        <div className="my-2">
            {props.playlistData.map((entry) => {
                if (entry.toCEntry) {
                    return (
                        <div className="row small" key={entry.itemInMemoryId}>
                            <div className="col-lg">
                                &bull; {entry.toCEntry.title}
                            </div>
                        </div>
                    )
                } else {
                    return (<React.Fragment key={entry.itemInMemoryId} />)
                }
            })}
        </div>
    )
}
