import {PlaylistDTO, PlaylistDTOCompanion} from "../../../dto/v2/playlist/PlaylistDTO";
import {PLAYLIST_LOCAL_STORAGE_ENTITY_NAME} from "../../EntityNames";
import LocalStoragePersistence from "../../common/LocalStoragePersistence";
import {DtoLocalStoragePersistence} from "../../common/DtoLocalStoragePersistence";
import {DtoIdentifier, EntityDTO} from "../../../dto/EntityDTO";
import _ from "lodash";
import EntityLocalStorageKey from "../../common/EntityLocalStorageKey";

/**
 * @deprecated use v3
 */
export class PlaylistLocalStoragePersistenceV2 extends DtoLocalStoragePersistence<PlaylistDTO> {
    protected schemaVersion(): number {
        return PlaylistDTOCompanion.SCHEMA_VERISON;
    }

    protected buildFromParsedJson(parsedJson: PlaylistDTO): PlaylistDTO {
        return PlaylistDTOCompanion.fromParsedJson(parsedJson);
    }

    /**
     * @deprecated use v3
     */
    public persist(playlist: PlaylistDTO) {
        throw new Error("no new persists with PlaylistLocalStoragePersistenceV2 please!");
    }

    protected localStorageKey(identifier: DtoIdentifier): EntityLocalStorageKey {
        return new EntityLocalStorageKey(PLAYLIST_LOCAL_STORAGE_ENTITY_NAME, identifier);
    }

    public loadAllIdentifiers(): ReadonlyArray<string> {
        return LocalStoragePersistence.loadAllIdentifiersForEntity(PLAYLIST_LOCAL_STORAGE_ENTITY_NAME)
    }

    public loadAllIdentifiersWithSchemaV2(): ReadonlyArray<string> {
        return _.compact(this.loadAllIdentifiers().map((i: string) => {
            const parsedJson: EntityDTO | undefined = LocalStoragePersistence.read(this.localStorageKey(i), undefined);
            if (parsedJson?.schemaVersion === PlaylistDTOCompanion.SCHEMA_VERISON) {
                return i;
            } else {
                return undefined;
            }
        }));
    }
}