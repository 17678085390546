const ScottySourceSystem = "scotty";

export interface TocIdDTO {
    sourceSystem: string;
    songId: number;
}

export class TocIdDTOCompanion {

    public static create(
        sourceSystem: string,
        songId: number
    ): TocIdDTO {
        return {
            sourceSystem: sourceSystem,
            songId: songId
        }
    }

    //this is done, to really have this type in order to make deep comparision in Lodash work.
    public static fromParsedJson(dto: TocIdDTO): TocIdDTO {
        return this.create(dto.sourceSystem, dto.songId)
    }

    static fromSongIdAndSourceSystem(songId: number, sourceSystem?: string): TocIdDTO {
        return {
            sourceSystem: sourceSystem ? sourceSystem : ScottySourceSystem,
            songId: songId
        }
    }

    static fromString(stringTocId: string): TocIdDTO | undefined {
        const splitted = stringTocId.split("_", 2);
        let songId: number | undefined;
        let sourceSystem: string;
        if (splitted.length === 1) {
            sourceSystem = ScottySourceSystem;
            songId = Number(splitted[0]) || undefined
        } else {
            sourceSystem = splitted[0];
            songId = Number(splitted[1]) || undefined
        }
        return songId ? this.fromSongIdAndSourceSystem(songId, sourceSystem) : undefined
    }

    public static stringify(dto: TocIdDTO): string {
        return dto.sourceSystem !== ScottySourceSystem ? `${dto.sourceSystem}_${dto.songId}` : dto.songId + ""
    }

    public static stringifyWithMandatoryPrefix(dto: TocIdDTO): string {
        return `${dto.sourceSystem}_${dto.songId}`
    }
}