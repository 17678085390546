import {DtoIdentifier, EntityDTO} from "../../dto/EntityDTO";
import LocalStoragePersistence from "./LocalStoragePersistence";
import EntityLocalStorageKey from "./EntityLocalStorageKey";

export abstract class DtoLocalStoragePersistence<DTO extends EntityDTO> {

    abstract persist(dto: DTO): void

    public doesDtoExist(identifier: DtoIdentifier): boolean {
        return LocalStoragePersistence.doesKeyExist(this.localStorageKey(identifier));
    }

    protected abstract schemaVersion(): number

    protected abstract localStorageKey(identifier: DtoIdentifier): EntityLocalStorageKey

    public load(identifier: DtoIdentifier): DTO | undefined {
        const parsedJson: DTO | undefined = LocalStoragePersistence.read(this.localStorageKey(identifier), undefined);
        if (parsedJson !== undefined) {
            this.validateSchemaVersion(identifier, parsedJson, this.schemaVersion());
        }
        return (parsedJson !== undefined) ? this.buildFromParsedJson(parsedJson) : undefined;
    }

    protected abstract buildFromParsedJson(parsedJson: DTO): DTO

    //TODO: Put errors in the response type
    private validateSchemaVersion(identifier: DtoIdentifier, successfulResult: DTO, schemaVersionToAssert: number): void {
        if (schemaVersionToAssert !== successfulResult.schemaVersion) {
            throw new Error(`DTO with identifier=${identifier} failed schema assertion. expected=${schemaVersionToAssert} actual=${successfulResult.schemaVersion}`);
        }
    }
}