import React, {createContext, useContext} from 'react';
import {useCrewLiveEventSource} from "./useCrewLiveEventSource";
import {
    PlaylistLivePositionByDeviceIdentifier,
    usePlaylistLivePositionFromEventSource
} from "../carousel/usePlaylistLivePositionFromEventSource";

export interface CrewLiveContextType {
    currentCrewIdentifier: string | undefined
    eventSource: EventSource | undefined
    playlistLivePosition: PlaylistLivePositionByDeviceIdentifier
    playlistLivePositionEventSourceHandlerIsReady: boolean,
    devicesOnline : readonly string[]
}

export const CrewLiveContext: React.Context<CrewLiveContextType | undefined> = createContext<CrewLiveContextType | undefined>(undefined);

export function useCrewLiveContextStateProvider(crewId: string | undefined): CrewLiveContextType {

    const {eventSource} = useCrewLiveEventSource(crewId)

    const {playlistLivePosition, playlistLivePositionEventSourceHandlerIsReady, devicesOnline} = usePlaylistLivePositionFromEventSource(eventSource);

    return {
        currentCrewIdentifier: crewId,
        eventSource,
        playlistLivePosition,
        playlistLivePositionEventSourceHandlerIsReady,
        devicesOnline
    }
}

// Hack to never have undefined in the context
// https://stackoverflow.com/a/69735347
export const useCrewLiveContext: () => CrewLiveContextType = () => {
    const c = useContext(CrewLiveContext);
    if (!c)
        throw new Error(
            'No provider found.'
        );
    return c;
};