import * as React from 'react';
import {Button} from 'reactstrap';
import {faFileAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useHistory} from "react-router-dom";
import {PlaylistRoutePaths} from "./PlaylistRoutePaths";
import PlaylistItemData from "./data/PlaylistItemData";
import {CyPlaylistFooterMenu} from "./CyPlaylistFooterMenu";

interface Props {
    playlistIdentifier: string,
    setSelectedItem: (item: PlaylistItemData | undefined) => void;
}

function PlaylistFooterMenu(props: Props) {
    const history = useHistory();

    function resetSelectedItemAndOpenTocIndex() {
        props.setSelectedItem(undefined);
        history.push(PlaylistRoutePaths.toTocIndex(props.playlistIdentifier));
    }

    return (
        <Button color="primary" onClick={() => resetSelectedItemAndOpenTocIndex()} data-cy={CyPlaylistFooterMenu.ButtonAdd}><FontAwesomeIcon icon={faFileAlt}/> Hinzufügen</Button>
    );
}

export default PlaylistFooterMenu;
